import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@procore/core-react";

type OnLoadSetEmailNotificationFunc = (
    selectedOption: string,
    switchValue: boolean,
    userid: string
  ) => Promise<void>;
 // type HandleRadioChangeFunc = () => void;
  
  interface ModalComponentProps {
    isShowModal: boolean;
    handleCancelClick: () => void;
 //   handleRadioChange:HandleRadioChangeFunc;
    selectedOption: string;
    switchValue: boolean;
    userid: any;
    projectname: any;
    isUsername: any;
    mode:any;
    setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    OnLoadSetEmailNotification: OnLoadSetEmailNotificationFunc;
  }
  function ModalComponent(props: ModalComponentProps) 
  {
    const {
      isShowModal,
      handleCancelClick,
     // handleRadioChange,
   //   selectedOption,
      switchValue,
      userid,
      projectname,
      isUsername,
      mode,
      setIsShowModal,
      OnLoadSetEmailNotification,
    } = props;

    const [selectedOption, setSelectedOption] = React.useState("");

    const handleRadioChange = (event: any) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
       
      };

   {
    return (
      <Modal
        show={isShowModal}
        keyboard={false}
        animation={false}
        dialogClassName="Delete-Commitment"
        backdrop="static"
        centered
      >
        <Modal.Header
          className="usermodel-header"
          style={{
            backgroundColor: "#45484A",
            color: "#fff",
            fontSize: 14,
            paddingTop: "12px",
            paddingRight: "24px",
            paddingBottom: "12px",
            paddingLeft: "24px",
            borderRadius: "4px 4px 0px 0px",
          }}
        >
          COI Requests for Commitments email settings
        </Modal.Header>
  
        <Modal.Body className="px-3">
          <div className="radio">
            <input
              type="radio"
              id="option1"
              name="radioGroup"
              value="option1"
              defaultChecked={mode !== "global"}
              onClick={handleRadioChange}
              disabled={mode === "global"}
            />
            <label className="radio-label" htmlFor="option1">
              <>
              {switchValue === true ? (
  <span className={mode === "global" ? "add-opacity-off" : "add-opacity-on"}>
    {mode === "global" ? "Disable emails for this project only (Not selected - Global level)" : `Enable emails for this project only ${projectname}`}
  </span>
) : (
  <span className={mode === "global" ? "add-opacity-off" : "add-opacity-on"}>
    {mode === "global" ? " Disable emails for this project only (Not selected - Global level)" : `Disable emails for this project only ${projectname}`}
  </span>
)}

           
             </>
            </label>
          </div>
  
          <div className="radio">
            <input
              type="radio"
              id="option2"
              name="radioGroup"
              value="option2"
              onClick={handleRadioChange}
              defaultChecked={mode === "global"}
            />
            <label className="radio-label" htmlFor="option2">
              <>
            {switchValue === true ? <span>Enable emails for all projects assigned to {isUsername}</span>:<span> Disable emails for all projects assigned to {isUsername}</span>}
            </>
            </label>
          </div>
        </Modal.Body>
  
        <Modal.Footer>
          <div>
            <button
              className="add-cancel-btn"
              onClick={() => {
                handleCancelClick();
                return;
              }}
            >
              Cancel
            </button>
          </div>
          
  
          <div style={{ paddingRight: "inherit" }}>
            <Button
              className="modal-save-button"
              onClick={async () => {
          
                let defaultSelectedOption;
                if (selectedOption === "") {
                  defaultSelectedOption = mode === "global" ? "option2" : "option1";
                }
                
                setIsShowModal(false);
                await OnLoadSetEmailNotification(defaultSelectedOption|| selectedOption, switchValue, userid);
                return;
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
 }
}
  export default ModalComponent;