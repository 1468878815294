import React, { useState } from "react";

import { getTokenFromHash } from "./utils";

import * as procoreIframeHelpers from "@procore/procore-iframe-helpers";

function Auth() {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(true);
  const context = procoreIframeHelpers.initialize();
  if (window.location.hash) {
    const tokens = getTokenFromHash(window.location.hash.substr(1));
    if (tokens && tokens.access_token) {
      context.authentication.notifySuccess(tokens.access_token);
    } else {
      setIsAuthorized(false);
      window.close();
    }
  }
  return (
    <div className="container container-wrapper p-5">
      <div className="row">
        <div className="col-md-12">
          <div
            className={`alert ${
              !isAuthorized ? "alert-danger" : "alert-light"
            } text-center`}
          >
            <p> {!isAuthorized ? "Unauthenticated!" : "Authenticating..."}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auth;
