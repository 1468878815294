import { toast, Slide } from "react-toastify";
import React, {useEffect } from "react";
import classNames from "classnames";


export function getTokenFromHash(hash: string) {
  let result = hash.split("&").reduce(function (res: any, item) {
    let parts = item.split("=");
    res[parts[0]] = parts[1];
    return res;
  }, {});

  return result;
}

export function showErrorMessage(message: string, icon?: React.ReactNode, clearPrevious: boolean = true , isCustomize?: boolean) {
  if(clearPrevious){
    toast.dismiss();
  }
  toast.error(<div style={{width: 'auto', textAlign: 'center'}}>{icon}{" "}{message}</div>, {
    position: isCustomize ? "bottom-right" :"bottom-center",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    autoClose: 10000,
    transition: Slide,
  });
}

export function showSuccessMessage(message: string, icon?: React.ReactNode , isCustomize?: boolean) {
  toast.dismiss();
  return toast.info(<div className={classNames("d-flex align-items-center  no-gutters", isCustomize === true ? '  justify-content-center': '')}>
    {icon}<div className="d-flex ml-2">{message}</div></div>, {
    position:isCustomize === true ? "bottom-right" :"bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    // style: {
    //   width: message.length * 8,
    // },
    transition: Slide,
  });
}


export function useOutsideAlerter(ref : any, setIsMenuOpen: (isOpen: boolean) => void) {
  useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !event?.target?.className?.includes("name-select")
        ) {
          setIsMenuOpen(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [ref, setIsMenuOpen]);
}

