import { useCallback , useState} from "react";
import API from "../apis";

export function useValidate() {
  const [validating, setValidating] = useState<boolean>(false);
  const onValidateEmail = useCallback(
    async (companyId: string, vendorIds: Array<string>, email: string) => {
      setValidating(true);
      try {
        const data = {
          companyId: companyId,
          vendors: vendorIds,
          emailAddress: email,
        };
        const response = await API.post(`Procore/ValidateEmailAddress`, data);
        setValidating(false);

        return response.data;
      } catch (e) {
        console.log(e);
        setValidating(false);

        return false;
      }
    },
    []
  );

  return {
    onValidateEmail,
    validating
  };
}
