import * as React from "react";
function NoResults(props: any) {
  return (
    <>
      <svg
        width="200"
        height="200"
        viewBox="0 0 200 200"
        fill="none"
        className="w-100"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M72.94 132.45H25.97V54.5H0V143.5H79.13C76.58 140.15 74.49 136.43 72.94 132.45Z"
          fill="#D6DADC"
        ></path>
        <path d="M141 30.5V41H25.86V119.5H13V30.5H141Z" fill="#1952B3"></path>
        <path d="M150 133H26V41H150V73.85V88.6V133Z" fill="white"></path>
        <path
          d="M117.5 152C142.629 152 163 131.629 163 106.5C163 81.3711 142.629 61 117.5 61C92.371 61 72 81.3711 72 106.5C72 131.629 92.371 152 117.5 152Z"
          fill="white"
        ></path>
        <path
          d="M97.75 65.5H36.5V90.5H74.9C79.03 79.52 87.27 70.56 97.75 65.5Z"
          fill="#F3F4F6"
        ></path>
        <path
          d="M72 106.5C72 103.06 72.39 99.72 73.12 96.5H36.5V121.5H74.54C72.9 116.8 72 111.76 72 106.5Z"
          fill="#F3F4F6"
        ></path>
        <path d="M39.96 47.93H36.85V51.04H39.96V47.93Z" fill="#232729"></path>
        <path d="M45.4 47.93H42.29V51.04H45.4V47.93Z" fill="#232729"></path>
        <path d="M50.85 47.93H47.74V51.04H50.85V47.93Z" fill="#232729"></path>
        <path
          d="M157.59 138.1L155.4 140.29L151.7 136.59C158.77 128.56 163.07 118.05 163.07 106.54C163.07 96.64 159.89 87.48 154.5 80V39H153.5H146.5H106.5V41H25.5V133H80.5C88.77 144.54 102.28 152.08 117.53 152.08C128.76 152.08 139.05 147.98 147 141.22L150.74 144.96L148.55 147.15L183.74 182.34L192.79 173.29L157.59 138.1ZM148.74 73.43C148.72 73.41 148.69 73.38 148.67 73.36C148.7 73.38 148.72 73.41 148.74 73.43ZM29.5 129V58H139.5V54H29.5V45H106.5V47H146.5V71.43C138.62 64.92 128.52 61 117.53 61C92.42 61 71.99 81.43 71.99 106.54C71.99 114.7 74.16 122.37 77.94 129H29.5ZM117.53 145.41C96.1 145.41 78.66 127.97 78.66 106.54C78.66 85.11 96.1 67.67 117.53 67.67C138.96 67.67 156.4 85.1 156.4 106.54C156.4 127.98 138.96 145.41 117.53 145.41Z"
          fill="#232729"
        ></path>
        <path
          d="M183.73 182.33L148.54 147.14L150.73 144.95L146.99 141.21C139.04 147.98 128.75 152.07 117.52 152.07C92.41 152.07 71.98 131.64 71.98 106.53C71.98 104.73 72.1 102.96 72.3 101.22C70.71 105.85 69.83 110.81 69.83 115.97C69.83 141.08 90.26 161.51 115.37 161.51C126.6 161.51 136.89 157.41 144.84 150.65L148.58 154.39L146.39 156.58L181.58 191.77L190.63 182.72L186.98 179.07L183.73 182.33Z"
          fill="#D6DADC"
        ></path>
        <path
          d="M127.4 88.15C125.04 86.17 121.71 85.18 117.42 85.18C113.11 85.18 109.76 86.25 107.37 88.4C104.98 90.55 103.77 93.55 103.73 97.4H113.49C113.53 95.96 113.9 94.81 114.62 93.96C115.33 93.11 116.27 92.69 117.42 92.69C119.92 92.69 121.18 94.14 121.18 97.05C121.18 98.24 120.81 99.34 120.07 100.33C119.33 101.32 118.25 102.41 116.82 103.61C115.4 104.8 114.37 106.21 113.73 107.84C113.1 109.47 112.78 111.69 112.78 114.5H121.04C121.08 113.04 121.28 111.83 121.65 110.88C122.01 109.93 122.67 109 123.61 108.09L126.93 105C128.34 103.63 129.36 102.29 129.99 100.98C130.63 99.67 130.94 98.22 130.94 96.62C130.93 92.96 129.76 90.14 127.4 88.15Z"
          fill="#FF5200"
        ></path>
        <path
          d="M117 120.12C115.46 120.12 114.17 120.58 113.14 121.49C112.11 122.4 111.59 123.57 111.59 125C111.59 126.43 112.11 127.6 113.14 128.51C114.17 129.43 115.46 129.88 117 129.88C118.54 129.88 119.83 129.42 120.86 128.51C121.89 127.6 122.41 126.42 122.41 125C122.41 123.57 121.89 122.4 120.86 121.49C119.83 120.57 118.54 120.12 117 120.12Z"
          fill="#FF5200"
        ></path>
        <path
          d="M141.314 19.1213L139.192 17L132.121 24.0711L134.243 26.1924L141.314 19.1213Z"
          fill="#232729"
        ></path>
        <path
          d="M100.071 26.1213L102.192 24L95.1213 16.9289L93 19.0503L100.071 26.1213Z"
          fill="#232729"
        ></path>
        <path d="M119.192 9H116.192V20H119.192V9Z" fill="#232729"></path>
        <path
          d="M93 180.071L95.1213 182.192L102.192 175.121L100.071 173L93 180.071Z"
          fill="#232729"
        ></path>
        <path
          d="M134.243 173.071L132.121 175.192L139.192 182.263L141.314 180.142L134.243 173.071Z"
          fill="#232729"
        ></path>
        <path
          d="M115.121 190.192L118.121 190.192L118.121 179.192L115.121 179.192L115.121 190.192Z"
          fill="#232729"
        ></path>
      </svg>
      <h3 className="text-center">No Results</h3>
    </>
  );
}

export default NoResults;
