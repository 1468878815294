import * as React from "react"
function CompanyLogo({height = 219, props}: any) {
    return (
        <svg width={height} height="219" viewBox="0 0 219 219" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="109.5" cy="109.5" r="109.5" fill="#EDEFF2" />
            <path d="M67.6044 141.176L37.96 171.456C43.5289 177.025 52.5582 177.025 58.1274 171.456L66.1942 163.389C71.7631 168.958 80.7926 168.958 86.3615 163.389L93.6677 156.083C83.7993 153.541 74.6462 148.109 67.6044 141.176Z" fill="white" />
            <path d="M150.72 58.0604H136.459V89.4318C136.46 78.8757 142.195 69.66 150.72 64.7288C154.915 62.302 159.785 60.9124 164.98 60.9124C164.98 59.1146 164.98 58.0602 164.98 58.0602V43.8C157.104 43.8 150.72 50.1847 150.72 58.0604Z" fill="white" />
            <path d="M122.198 58.0604C122.198 50.1847 115.814 43.8 107.938 43.8V60.9126C113.133 60.9126 118.003 62.3023 122.198 64.7291C130.723 69.6607 136.459 78.8769 136.459 89.4336V58.0604H122.198Z" fill="#D8DBE2" />
            <path d="M136.459 97.8139V90.8319C136.459 89.9791 136.459 89.4332 136.459 89.4332C136.459 78.877 130.723 69.6606 122.198 64.7294C118.003 62.3026 113.133 60.913 107.938 60.913V100.842C115.686 93.0944 127.618 92.0874 136.459 97.8139ZM116.494 80.8775C116.494 77.7274 119.048 75.1734 122.198 75.1734C125.349 75.1734 127.902 77.7274 127.902 80.8775C127.902 84.0278 125.349 86.5816 122.198 86.5816C119.048 86.5816 116.494 84.0278 116.494 80.8775Z" fill="#CBD0DB" />
            <path d="M136.459 89.4324V97.8139C137.786 98.6729 139.044 99.6796 140.206 100.842H164.98V60.913C159.785 60.913 154.915 62.3026 150.72 64.7294C142.195 69.6608 136.459 78.8765 136.459 89.4324ZM156.424 80.8775C156.424 84.0278 153.87 86.5816 150.72 86.5816C147.569 86.5816 145.016 84.0278 145.016 80.8775C145.016 77.7274 147.569 75.1734 150.72 75.1734C153.87 75.1734 156.424 77.7274 156.424 80.8775Z" fill="#CCCFD6" />
            <path d="M107.938 100.843L105.312 103.47L107.938 100.843Z" fill="#8F5543" />
            <path d="M140.206 100.843C149.116 109.754 149.116 124.201 140.206 133.111C122.385 150.932 93.492 150.932 75.671 133.112L67.6042 141.179C74.646 148.111 83.7991 153.544 93.6673 156.085C98.2284 157.26 103.01 157.885 107.938 157.885C139.441 157.885 164.98 132.347 164.98 100.844L140.206 100.843Z" fill="#D8DBE2" />
            <path d="M107.938 100.842L140.206 133.11C149.116 124.199 149.116 109.752 140.206 100.842C139.043 99.6793 137.785 98.6723 136.459 97.8133C127.618 92.0871 115.686 93.0941 107.938 100.842Z" fill="#C4C9D5" />
            <path d="M140.206 133.111L107.938 100.843L105.312 103.47L75.6704 133.111C93.4924 150.932 122.385 150.932 140.206 133.111Z" fill="white" />
            <path d="M122.199 86.5823C125.35 86.5823 127.904 84.0285 127.904 80.8782C127.904 77.7279 125.35 75.1741 122.199 75.1741C119.049 75.1741 116.495 77.7279 116.495 80.8782C116.495 84.0285 119.049 86.5823 122.199 86.5823Z" fill="white" />
            <path d="M150.72 86.5823C153.871 86.5823 156.425 84.0285 156.425 80.8782C156.425 77.7279 153.871 75.1741 150.72 75.1741C147.57 75.1741 145.016 77.7279 145.016 80.8782C145.016 84.0285 147.57 86.5823 150.72 86.5823Z" fill="#EDEFF2" />
        </svg>
    );
}

export default CompanyLogo;