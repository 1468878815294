import * as React from "react"

function WaitingNewCOI(props: any) {
    return (
        <svg 
        width={20}
        height={20} 
        viewBox="0 0 24 24" 
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
            <path fill="#FFA700" fillRule="evenodd" d="M12 6V1l5 5h-5zm0 5H3V9h9v2zm-3 4H3v-2h6v2zm9-.651c-2.329.824-4 3.04-4 5.651 0 .702.127 1.374.349 2H2c-1.103 0-2-.897-2-2V2C0 .898.897 0 2 0h11.414L18 4.586v9.763zm5.707 3.357L21.414 20l2.293 2.293-1.414 1.413L20 21.415l-2.293 2.291-1.414-1.413L18.586 20l-2.293-2.294 1.414-1.413L20 18.585l2.293-2.293 1.414 1.413z"/>
        </svg>
    )
}

export default WaitingNewCOI;
