import React, { memo } from "react";
import { MainTemplate } from "./notlink-project";


interface IProjectOnboardingProps {
  projectName: string;
}

const ProjectOnboarding = ({ projectName }: IProjectOnboardingProps) => {
  return (
    <div className="container container-wrapper p-4">
      <MainTemplate height={150}>
        <div className="main-create-project text-center">
          The project for {projectName} is in the onboarding phase in Jones.
        </div>

        <div
          className="main-create-project-p text-center"
          style={{ marginTop: "10px", lineHeight: "19.5px" }}
        >
          Once the project is complete, you can invite vendors to review your
          insurance requirements and upload their COIs.
        </div>
      </MainTemplate>
    </div>
  );
};

export default memo(ProjectOnboarding);
