import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Auth from "./Auth";
import Login from "./pages/Login"
import MapProject from "./pages/map-project"


import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import SidePanel from "./pages/side-panel";



ReactDOM.render(
  <React.StrictMode>
    <Router basename="/">
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/auth" component={Auth} />
        <Route path="/map-project" component={MapProject} />     
        <Route path="/sidepanel" component={SidePanel} />     
        <Route path="/" component={App} />
        
        
      </Switch>
    </Router>
    <p className="text-center w-100" style={{ color:"rgb(139 139 161)", fontWeight:900, marginBottom:1 }}>
      POWERED BY JONES
    </p>
    <p className="text-center w-100" style={{color:"rgb(9 144 247)", fontSize:10 }}>
     <u><a href="http://getjones.com" rel="noopener noreferrer" target="_blank">www.getjones.com</a></u>
    </p>
    <ToastContainer style={{ width: "auto" }} limit={4} />
  </React.StrictMode>,
  document.getElementById("root")
);
