import React from 'react';
import DataSource from 'devextreme/data/data_source';
import { SelectBox } from 'devextreme-react/select-box';
const LookupBox: React.FC<any> = (cell) => {
  const selectBoxData = new DataSource({
    store: cell.column?.lookup?.dataSource,
    paginate: true,
    pageSize: 10,
  });

  const handleValueChange = React.useCallback(
    (e: any) => {
      cell.setValue(e.value);
    },
    [cell]
  );

  const itemRender = React.useCallback((data) => {
    if (data != null) {
      return (
        <div>
          <span className="middle">{data.text}</span>
        </div>
      );
    } else {
      return <span>(All)</span>;
    }
  }, []);

  const data = cell.column?.lookup?.dataSource.find(
    (x: any) => x.value === cell.value?.toString()
  );
  return (
    <SelectBox
      defaultValue={data}
      dataSource={selectBoxData}
      onValueChange={(e) => handleValueChange(e)}
      itemRender={itemRender}
      displayExpr="text"
      displayValue="value"
      searchEnabled
    />
  );
};

export default LookupBox;
