import React, { useState, useCallback, useEffect } from "react";
import { InsuranceRequirement, JonesRecord, Project, PermissionOptionModel } from "../../models"
import API from "../../apis";
import fileDownload from 'js-file-download';
import { showErrorMessage, showSuccessMessage } from "../../utils";
import SuccessIcon from "../../shared/SuccessIcon";

export function useLoad() {
  const [jonesVendor, setJonesVendor] = useState<JonesRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloading, setDownLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingJonesVendors, setLoadingJonesVendors] = useState(false);
  const [permissions, setPermissions] = useState<PermissionOptionModel[]>([])
  const [Coifileload, setCoifileload] = useState<boolean>(false);
  const [JonesPermission, setJonesPermission] = useState<boolean>(false);


  const [project, setProject] = useState<Project>({
    id: 0,
    name: "",
    code: "",
    vendorList: [],
    requirementList: [],
    projectRoles: [],
    clientId: 0,
    kriyagoProjectId: 0,
    requirements: [],
    logeduser: "",
    comanyAccName :"",
  });

  const [insurance, setInsurance] = useState<InsuranceRequirement>({
    vendors: [],
    projectId: 0,
    requirementId: null,
    clientId: 0,
    kriyagoProjectId: 0,
    companyId: '0',
    tabId: 0,
  });


  const onLoadProject = useCallback(async () => {
    setLoading(true);
    try {
      let projectId = new URLSearchParams(window.location.search).get(
        "ProjectID"
      );
      let companyId = new URLSearchParams(window.location.search).get(
        "CompanyID"
      );

      const response = await API.get(
        `Procore/GetProject?ProjectID=${projectId}&CompanyID=${companyId?.toString()}&Mode=${"global"}`
      );
      if (response && response.data) {
        const data = {
          ...response.data,
          vendorList: [
            {
              id: "0",
              name: "Select All",
              code: "Select All",
              emailAddress: "",
              isSkip: false,
              projectUsers: [],
              isRequestSent: false,
            },
            ...response.data.vendorList,
          ],
        };
        setProject(data);

        setInsurance((insurance: any) => {
          return {
            ...insurance,
            projectId: response.data ? response.data.id : 0,
            clientId: response.data ? response.data?.clientId : 0,
            vendors: [],
            kriyagoProjectId: response.data ? response.data?.kriyagoProjectId : 0,
            companyId: companyId ? companyId?.toString() : '0'
          };
        });
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setErrorMessage(e.message);
    }

    setLoading(false);
  }, []);

  const onLoadVendorsUsers = useCallback(
    async (companyId: string, vendorIds: Array<string>) => {
      setLoadingUsers(true);
      try {
        const data = {
          companyId: companyId,
          vendors: vendorIds,
        }
        const response = await API.post(
          `Procore/GetVendorUsers`,
          data
        );


        setLoadingUsers(false);
        return response.data;
      } catch (e) {
        console.log(e);
        setLoadingUsers(false);
        setErrorMessage(e.message);
      }
    },
    []
  );

  const getVendorsCOItag = useCallback(async (companyId: string) => {
    try {
      setLoadingUsers(true);
      const response = await API.get(`Procore/GetVendorCOITag?CompanyID=${companyId}`);
      setLoadingUsers(true);
      return response.data;
    } catch (e) {
      console.log(e);
      setLoadingUsers(false);
      setErrorMessage(e.message);
    }
  }, []);


  const onLoadCoiFiles = useCallback(
    async (ForeignId2: string, Id: any) => {

      try {

        setLoadingUsers(true);
        const response = await API.get(
          `Procore/GetRecentCoiFile?ForeignId2=${ForeignId2}&Id=${Id}`,
        );
        if (response && response.data) {
          return response.data;
        }
        else { return null; }

      } catch (e) {
        console.log(e);

        setErrorMessage(e.message);
      }
    },
    []
  );


  const onUploadCoiFiles = useCallback(
    async (values, ForeignId2: string, Id: any) => {      
      setCoifileload(true)
      try {
        

        let formData = new FormData();
        for (var i = 0; i < values.length; i++) {
          const element = values[i].file;
          formData.append(`files`, element);
        }
        const response = await API.post(
          `Procore/UploadCoiFile?ForeignId2=${ForeignId2}&Id=${Id}`, formData
        );
        onLoadJonesVendors();
        setCoifileload(false)
        
        
        if (response.data == true) {     

          showSuccessMessage(
            "Insurance docs have been uploaded",
            <SuccessIcon />
          );
         } else {
          showErrorMessage("File is incorrect");
        }
        return response.data;
      } catch (e) {
        console.log(e);
        setLoadingUsers(false);
        setErrorMessage(e.message);
      }
    },
    []
  );


  const onLoadJonesVendors = useCallback(
    async (search: string = '') => {
      setLoadingJonesVendors(true);
      try {
        const projectId = new URLSearchParams(window.location.search).get("ProjectID");
        let companyId = new URLSearchParams(window.location.search).get("CompanyID");
        const response = await API.get(`Procore/GetJonesVendors?projectId=${projectId}&companyID=${companyId?.toString()}&search=${search}&mode=${"global"}`);
        setJonesVendor(response.data);
        setLoadingJonesVendors(false);
        return response.data;

      } catch (e) {
        setLoadingJonesVendors(false);
      }
    }, []
  )

  const onDownLoadFile = React.useCallback(async (search) => {
    setDownLoading(true);
    const projectId = new URLSearchParams(window.location.search).get("ProjectID");
    let companyId = new URLSearchParams(window.location.search).get("CompanyID");

    try {
      const { data, headers } = await API.get(
        `Procore/DownloadGlobalJonesVendors?projectId=${projectId}&companyID=${companyId?.toString()}&search=${search}&mode=${"global"}`,
        {
          responseType: 'blob',
        }
      );
      fileDownload(data, headers.filename);
      setDownLoading(false);
    } catch (e) {
      setDownLoading(false);
      showErrorMessage('Failed to download file');
    }
  }, []);

  const onLoadPermissions = React.useCallback(async () => {
    let companyId = new URLSearchParams(window.location.search).get(
      "CompanyID"
    );
    let projectId = new URLSearchParams(window.location.search).get(
      "ProjectID"
    );
    try {
      const response = await API.get(`Procore/GetPermissionTemplates?companyId=${companyId?.toString()}&projectId=${projectId}`);
      if (response.data) {
        setPermissions([
          {
            label: "",
            options: [
              {
                id: 0,
                type: "",
                name: "Apply Permission Template Later",
                projectSpecific: false
              }
            ]
          },
          ...response.data
        ])
      }
    } catch (error) {
      console.log(error);
    }
  }, [])
  

  useEffect(() => {
    // onLoadProject();
    //onLoadJonesVendors();
    onLoadPermissions();
  }, [onLoadPermissions])

  return {
    project,
    insurance,
    loading,
    setInsurance,
    errorMessage,
    jonesVendor,
    onLoadJonesVendors,
    downloading,
    onDownLoadFile,
    onLoadVendorsUsers,
    getVendorsCOItag,
    loadingUsers,
    setLoadingUsers,
    onLoadProject,
    loadingJonesVendors,
    permissions,    
    onLoadCoiFiles,
    onUploadCoiFiles,
    Coifileload,
    JonesPermission,
    
    

  }
}

export function useStore() {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onStore = useCallback(
    async (values) => {
      try {

        setSubmitting(true);
        let data = new FormData();
        // data.append('projectId', values.projectId);
        data.append('clientId', values.clientId);
        data.append('requirementId', values.requirementId);
        data.append('kriyagoProjectId', values.kriyagoProjectId);
        data.append('companyId', values.companyId?.toString());
        data.append('tabId', values.tabId);
        data.append('mode', "global");
        if (values?.vendors && values.vendors.length) {
          values.vendors.forEach((v: any, idx: any) => {
            data.append(`vendors[${idx}].id`, v.id);
            data.append(`vendors[${idx}].code`, v.code ?? null);
            data.append(`vendors[${idx}].name`, v.name ?? null);
            data.append(`vendors[${idx}].emailAddress`, v.emailAddress ?? null);
            data.append(`vendors[${idx}].contactName`, v.contactName ?? null);
            data.append(`vendors[${idx}].coiContactUserId`, v.coiContactUserId ?? 0);
            data.append(`vendors[${idx}].isRequestSent`, v.isRequestSent);
            if (v.contactCoiUser) {
              data.append(`vendors[${idx}].contactCoiUser.id`, v.contactCoiUser?.id);
              data.append(`vendors[${idx}].contactCoiUser.name`, v.contactCoiUser?.name);
              data.append(`vendors[${idx}].contactCoiUser.emailAddress`, v.contactCoiUser?.emailAddress);
              data.append(`vendors[${idx}].contactCoiUser.lastName`, v.contactCoiUser?.lastName);
              data.append(`vendors[${idx}].contactCoiUser.permissionTemplateId`, v.contactCoiUser?.permissionTemplateId);
            }
            if (v.isRequestCoi !== null) {
              data.append(`vendors[${idx}].isRequestCoi`, v.isRequestCoi);
            }

            if (v.coiFiles && v.coiFiles.length) {
              for (let idx2 = 0; idx2 < v.coiFiles.length; idx2++) {
                const element = v.coiFiles[idx2].file;
                data.append(`vendors[${idx}].files`, element);

              }
            }
          });
        }
        const response = await API.post("Procore/InviteVendorInsReq", data);
        sessionStorage.setItem('FailedCoiResponse', JSON.stringify(response.data));
        setSubmitting(false);
        if (response) {
          if (Array.isArray(response.data) && response.data.length === 0) {
            showSuccessMessage(
              "Great, a request has been sent! Status will be shown on the company’s insurance tab on the directory tool",
              <SuccessIcon />
            );
          } else {
            // Handle other cases when response.data is not an empty array
          }
        } else {
          showErrorMessage("Submission failed");
        }
      } catch (e) {
        console.log(e);
        setSubmitting(false);
        showErrorMessage(e?.response?.data);
      }
    },
    [setSubmitting]
  );
  return { onStore, submitting };
}
