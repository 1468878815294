import React, { useState, useCallback, useEffect } from "react";
import API from "../../apis";
import {  CommitmentTool,JonesRecord } from "../../models";
import { showErrorMessage, showSuccessMessage } from "../../utils";

export function useLoad() {

    
    const [getCommitmentList, setgetCommitmentList] = useState<CommitmentTool[]>([]);   
    const [loadingCommitment, setloadingCommitment] = useState(false);
    const [jonesVendor, setJonesVendor] = useState<JonesRecord[]>([]);
  
  
    const onLoadGetCommitmentlist = useCallback(
      async (search: string = '') => {
        setloadingCommitment(true);
        try {
          let projectId = new URLSearchParams(window.location.search).get(
              "ProjectID"
            );
            let mode = new URLSearchParams(window.location.search).get(
              "mode"
            );
          let companyId = sessionStorage.getItem('companyId');
        
          const response = await API.get(
            `Procore/GetCommitmentDataList?companyId=${companyId?.toString()}&projectId=${projectId}&search=${search}`
          );
          
          setloadingCommitment(false);
          setgetCommitmentList(response.data)
          return response.data;
        } catch (e) {
          console.log(e);
          setloadingCommitment(false);
          
        }
      },
      []
    );

    const onLoadJonesVendors = useCallback(
        async (search: string = '') => {
            setloadingCommitment(true);
          try {
            const projectId = new URLSearchParams(window.location.search).get("ProjectID");
            let companyId = new URLSearchParams(window.location.search).get("CompanyID");
         
            const response = await API.get(`Procore/GetJonesVendors?projectId=${projectId}&CompanyId=${companyId?.toString()}&search=${search}`);
        
            setJonesVendor(response.data);
            setloadingCommitment(false);
            return response.data;
    
          } catch (e) {
            setloadingCommitment(false);
          }
        }, []
      )


      const onDeleteCommitment = useCallback(
        async (Vendorid:string) => {
            setloadingCommitment(true);
          try {
            const projectId = new URLSearchParams(window.location.search).get("ProjectID");
            let companyId = sessionStorage.getItem('companyId');
            const response = await API.get(`Procore/DeleteCommitment?companyId=${companyId?.toString()}&projectId=${projectId}&Vendorid=${Vendorid}`);
           
            setloadingCommitment(false);
            onLoadGetCommitmentlist()
            showSuccessMessage(response.data);
            return response.data;
    
          } catch (e) {
            setloadingCommitment(false);
            showErrorMessage(e?.response?.data?.message);
          }
        }, []
      )
  
  
   
  
    
  
    // React.useEffect(() => {
    //  onLoadGetCommitmentlist();
    
    
    // },[onLoadGetCommitmentlist,
    //   ])
  
    return {
        getCommitmentList,
        onLoadGetCommitmentlist,
        loadingCommitment,
        jonesVendor,
        onLoadJonesVendors,
        onDeleteCommitment,
      
    };
  }