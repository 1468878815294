import React, { memo } from "react";
import { Card } from "react-bootstrap";
import Messagelogo from "../../shared/images/Message-Record-Hub.svg";
import { JONES_RECORD_HUB_URL } from "../../env";


const Banner: React.FC<any> = () => {
  const handleClick = () => {
    //window.open('https://app.getjones.com/signin?utm_source=procore', '_blank');
    window.open(JONES_RECORD_HUB_URL, "_blank");
  };

  return (
        <div className="d-flex container container-wrapper p-3 permission-panel">
          <div className="row justify-content-start">
            <div className="">
              <Card>
                <Card.Body>
                  <div className="row">
                    <div className="col-1">
                      <img src={Messagelogo} alt="Message Logo" width="30" />
                    </div>
                    <div className="col ml-2 d-flex  justify-content-between flex-column">
                      <div className=" d-flex flex-column">
                        <p className="card-hub mb-1">
                          You may have more COI requests from other companies
                        </p>
                        <div className="gray-card-note">
                          Log into Jones to see all of your requests and{" "}
                        </div>
                      </div>

                      <div className="d-flex justify-content-between mt-1">
                        <p className="gray-card-note">
                          manage your compliance.
                        </p>
                        <div className="d-flex align-items-start justify-content-end">
                          <button
                            className="view-request-button-inline"
                            onClick={handleClick}
                            style={{ width: "98px" }}
                          >
                            View Request
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
  
  );
};

export default memo(Banner);
