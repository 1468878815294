import React, { useCallback, useRef, useState } from "react";
import { columns } from "./column";
import { Typeahead, Button, Tooltip } from "@procore/core-react";
import { Plus } from '@procore/core-icons/dist'
import NoResults from '../../shared/NoResultsCommitment'
import { useLoad } from "./hooks"
import DataTable from "../../components/DataTable";
import debounce from "lodash/debounce";
import { Modal } from "react-bootstrap";
import CreateRequest from "../../pages/all-request/create-request";
import CustomLabel from "../../components/CustomLabel";
import DeleteCommitIcon from "../../shared/status-icons/Deletecommit";
import  Tooltip1  from 'devextreme-react/tooltip';
import FailedCoiPopup from "../../components/FailedCOIPopup";

function CommitmentRequest(props: {


}) {
    let {
    } = props;

   
    const [searchContent, setSearchContent] = useState<string>("");
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [isShowDeleteModal, SetIsShowDeleteModal] = useState<boolean>(false);
    const [IscompanyName,Setiscompanyname] =useState<string>(""); 
    const [IscompanyId,SetiscompanyId] =useState<string>(""); 
    const [Isdatalist,SetIsdatalist] = useState<any>();
    const [Vendorcoiupload1, setVendorcoiupload1] = useState<any>(null);
    const [isCoiShowModal, setIsCoiShowModal] = useState<boolean>(true);
   const { onLoadGetCommitmentlist,getCommitmentList,loadingCommitment,onLoadJonesVendors,onDeleteCommitment} = useLoad();
    //const dataGrid = React.useRef<any>(null);

     React.useEffect(() => {
        onLoadGetCommitmentlist()

     }, [onLoadGetCommitmentlist])

     const Vendorcoiupload:any = sessionStorage.getItem("FailedCoiResponse");
     // let Vendorcoiupload1;
     // if (Vendorcoiupload !== null) {
     //   Vendorcoiupload1 = JSON.parse(Vendorcoiupload);}
     React.useEffect(() => {       
         setVendorcoiupload1(JSON.parse(Vendorcoiupload));
       }, [Vendorcoiupload]);

     columns.forEach((e) => {

        if (e.caption === 'Action') {

            e.cellRender = (cellData: any, index: number) => {
               

                return (

                    <div className="Button-wrapper">
                        <div className="add_req-btn border-right pr-2 ">
                        <Button  icon={<Plus />} onClick={() => {
                         SetiscompanyId("");
                         setIsShowModal(true);                         
                         SetiscompanyId(cellData?.data?.vendorcode);
                        }} className="add-vendor-btn" >Add Project COI Request</Button> 
                        </div>
                        
                     


                    <Tooltip1
                    target={'.tooltip-dismiss'+ cellData.data.vendorId}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    position="top"
                    contentTemplate={
                        'Dismiss'
                      }                    />

                 <div className="pl-4" onClick={() => {         
                    
                    SetIsShowDeleteModal(true);                   
                    Setiscompanyname(cellData?.data?.companyName);
                    SetIsdatalist(cellData?.data);
                    
                }} >
                    <div className={"tooltip-dismiss" + cellData.data.vendorId}>
                    <CustomLabel  icon={<DeleteCommitIcon />} />
                    </div>
                </div> 
   
               </div> 
                     
                    
                );
            };
        }
    });
     const onSearch = useCallback(
         async (search: string) => {
             try {
                onLoadGetCommitmentlist(search);

             } catch (e) {
                 console.log(e);
             }
         }, [onLoadGetCommitmentlist]);

    const searchDebounce = useRef(debounce(onSearch, 1000));
    if ("") {
        return (
            <div className="container container-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="alert alert-danger text-center">
                            <p>{""}</p>
                        </div>
                    </div>
                </div>
            </div>
        );

    } else {
        return (
            <div className="mt-3 pt-2 border-top ">
                {(false) ?
                    <div className="p-4">
                        <div className="row mb-2" style={{ marginTop: "4%" }}>
                            <div className="col-md-12 d-flex justify-content-center">

                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className="position-absolute right-action-col">
                            <div className="row mb-2">
                                <div className="col-md-7">
                                    <Typeahead
                                        onChange={(e) => {
                                            
                                            setSearchContent(e);
                                            searchDebounce.current(e);
                                        }}
                                        placeholder="Search"
                                        value={searchContent}
                                    />
                                </div>

                                <div className="col-md-5 d-flex justify-content-end no-gutters">
                                    <div className="pr-2">
                                        {/* <Button disabled={downloading} onClick={() => {
                                            onDownLoadFile(searchContent);
                                        }} className="export-btn" >Export</Button> */}
                                    </div>
                                    <div>
                                        <Button icon={<Plus />} onClick={() => {
                                            SetiscompanyId("");
                                         setIsShowModal(true);

                                        }} className="add-vendor-btn" >Add Project COI Request</Button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="table-title mt-3">
                                            <span>Project Commitments Without COI Requests</span>
                                            <p className="mt-2" >Below is a list of commitments from your project without a COI request. Once you request, they will automatically be cleared from the list. </p>
                                        </div>

                        <div className="row mt-4">
                            <div className="col-md-12">
                                {loadingCommitment  ? (
                                    <div className="container container-wrapper p-5">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="alert alert-light text-center">
                                                    <img src={"/images/spinner.gif"} alt="Loading" />
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                ) : getCommitmentList.length ? (

                                    <div>
                                       
                                        <DataTable
                                            id="gridContainer"
                                            dataSource={getCommitmentList}                                         
                                            columns={columns}
                                            loading={loadingCommitment}
                                            pageSize={10}
                                        />
                                    </div>
                                ) : <NoResults />
                                    
                                }
                            </div>
                        </div> 
                        {                               

(Vendorcoiupload1 != null  && Vendorcoiupload1.length>0) && ( <FailedCoiPopup 
    onClose={() => {
      setVendorcoiupload1(null);
      setIsCoiShowModal(false); 
               
   }} 
   
   isModelOpen={Vendorcoiupload1} 
   setIsCoiShowModal={() =>{
     setVendorcoiupload1(null);

     setIsCoiShowModal(false); 
   }}             
   showCOIModal={true}                         
   allVendors={Vendorcoiupload1}             
   />)

}                      
                    </>
                }
                {isShowModal &&
                    (<Modal
                        show={isShowModal}
                        keyboard={false}
                        animation={false}
                        dialogClassName="create-request"
                        backdrop="static"
                        centered
                        onHide={() => { }}
                    >
                        <Modal.Body className="px-0">

                            <CreateRequest
                                showCOIModal={true}
                                onSaved={onLoadGetCommitmentlist}
                                setIsShowModal={setIsShowModal}                              
                                IscompanyId={IscompanyId}
                                onClose={() => { setIsShowModal(false); }}  />
                                {}
                                
                        </Modal.Body>
                    </Modal>)}



                    {isShowDeleteModal &&
                    ( <Modal
                        show={isShowDeleteModal}
                        keyboard={false}
                        animation={false}
                        dialogClassName="Delete-Commitment"
                        backdrop="static"
                        centered
                        
                      >
                         <Modal.Header style={{                       
                        
                        fontSize: 19,
                        paddingTop: "12px",
                        paddingRight: "24px",
                        paddingBottom: "12px",
                        paddingLeft: "24px",
                        borderRadius: "4px 4px 0px 0px",
                        borderBottom: "none",
                        fontWeight: "bold" 
                      }}>
                            
                            <div>Dismiss Commitment Without COI Request for  {IscompanyName} </div>
                           
                            
                        </Modal.Header>
                        <Modal.Body className="px-4">
                          <p className="header-details">By dismissing this commitment, it will be removed from the table. However, you can still send a COI request to this company in the future if needed.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <div>
                        <button className="add-cancel-btn"

                    onClick={() => {                       
                        SetIsShowDeleteModal(false);                    
                        return;
                    }}
                    > Cancel

                    </button>
                    
                    </div>
                    <div style={{ paddingRight: "inherit" }}>

                               
                                <button  className="add-fileupload-btn"

                                    onClick={() => {
                                       
                                        onDeleteCommitment(Isdatalist.vendorId)
                                        SetIsShowDeleteModal(false);
                                        return;
                                    }}
                                > Dismiss
                                </button>
                            </div>
                         
                        </Modal.Footer>
                      </Modal>)}
            </div>
        )
    }

}

export default CommitmentRequest;