
export const BASE_URL = `${process.env.REACT_APP_API_URL}`
export const PROCORE_URL = `${process.env.REACT_APP_PROCORE_URL}`
export const PROCORE_US_URL = `${process.env.REACT_APP_PROCORE_US_URL}`
export const JONES_RECORD_HUB_URL = `${process.env.REACT_APP_JONES_RECORD_HUB_URL}`
export const AUTH_REDIRECT_URI = `${process.env.REACT_APP_AUTH_REDIRECT_URI}`
export const CLIENT_ID = `${process.env.REACT_APP_CLIENT_ID}`
export const ENV = `${process.env.REACT_APP_ENV}`





