import React, { useCallback, useState, useEffect } from "react";

import API from "./apis";
import { PROCORE_URL,PROCORE_US_URL, AUTH_REDIRECT_URI, CLIENT_ID, ENV } from "./env";
import decode from 'jwt-decode';
import moment from 'moment';



import * as procoreIframeHelpers from "@procore/procore-iframe-helpers";
import { useHistory } from "react-router-dom";



export function useLoadApp() {
  const history = useHistory();
  const [isLoading, setLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorMessage1, setErrorMessage1] = useState<string | null>(null);
  const [errorMessage2, setErrorMessage2] = useState<string | null>(null);
  const [Permissionname, setPermissionname] = useState<string | null>(null);
  const [userExist, setuserExist] = useState<any>([]);

  const onValidateLogin = useCallback(async () => {
   // setLoading(true);
    try {
      let projectId = new URLSearchParams(window.location.search).get(
        "ProjectID"
      );
      let companyId = new URLSearchParams(window.location.search).get(
        "CompanyID"
      );
      
      
      if (!companyId) {
        console.log('company is not selected')
        setErrorMessage("Company or Project is not selected!");
        setLoading(false);
        return;
      }
     
      try {
        if (companyId) {
          sessionStorage.setItem("companyId", companyId);
        }

        const token = sessionStorage.getItem("token");
        if (!token) {
          history.push("/login", {
            projectId: projectId,
            companyId: companyId,
          });
          return;
        }
        if(token){
          const tokenData : any = decode(token);
          const isTokenValid = moment.unix(tokenData.exp).isSameOrAfter(new Date());
          if(!isTokenValid){
            sessionStorage.removeItem("token");
            history.push("/login", {
              projectId: projectId,
              companyId: companyId,
            });
            return;
          }
        }
        API.defaults.headers["accessToken"] = token;
        API.defaults.headers["CompanyId"] = companyId;
      } catch (e) {
        history.push("/login", { projectId: projectId, companyId: companyId });
        return;
      }
      const isuserExist = await API.get(
        `Procore/ProcorePermission?ProjectID=${projectId}&CompanyID=${companyId?.toString()}`
      );
           
        setuserExist(isuserExist.data)
      

       const Permissionname = await API.get(
         `Procore/PermissionName?companyId=${companyId?.toString()}`
       );
     
       setPermissionname(Permissionname.data) 
     
      const response = await API.get(`Procore/Globalisexist?CompanyID=${companyId?.toString()}`);

      
       if(!response.data && (projectId == null || projectId =="null"))
       {
        setErrorMessage1(`No “Company level - Jones app” configured in Jones yet.`);
        setErrorMessage2(`Please reach out to your CSM or contact our support at Integration@getjones.com`)        
        setLoading(false);
          return;  
       }
      
      
      if(projectId !== null && projectId !=="null")
      {
        const isProjectExist = await API.get(
          `Procore/VerifyProject?ProjectID=${projectId}&CompanyID=${companyId?.toString()}`
        );
        sessionStorage.setItem("projectname", isProjectExist.data.name);
        if (isProjectExist && isProjectExist.data && isProjectExist.data.kriyagoProjectId === "") {
          const projectData = {
            id: isProjectExist.data.id,
            projectName: isProjectExist.data ? isProjectExist.data.name : 0,
            requirementList: isProjectExist.data && isProjectExist.data.requirementList ? isProjectExist.data.requirementList : [],
            selectedRequirementList: [],
            additionalInsured: isProjectExist.data ? isProjectExist.data.additionalInsured : "",
            certificateHolder: isProjectExist.data ? isProjectExist.data.certificateHolder : "",
            Clientid1:isProjectExist.data.clientId,
            office: isProjectExist.data && isProjectExist.data.office ? isProjectExist.data.office : [],
            isProjectOnboarding: isProjectExist.data ? isProjectExist.data.isProjectOnboarding : false,
            isuserExist:isuserExist.data?.userexist,
            Permissionname:Permissionname.data,
           
          }
          history.push({
            pathname: '/map-project',
            state: { detail: projectData }
          });        
         
         return;
       }

       if(isProjectExist && isProjectExist.data && isProjectExist.data.isProjectOnboarding){
         const projectData = {
           id: isProjectExist.data.id,
           projectName: isProjectExist.data ? isProjectExist.data.name : 0,
           requirementList: isProjectExist.data && isProjectExist.data.requirementList ? isProjectExist.data.requirementList : [],
           selectedRequirementList: [],
           additionalInsured: isProjectExist.data ? isProjectExist.data.additionalInsured : "",
           certificateHolder: isProjectExist.data ? isProjectExist.data.certificateHolder : "",
           Clientid1:isProjectExist.data.clientId,
           office: isProjectExist.data && isProjectExist.data.office ? isProjectExist.data.office : [],
           isProjectOnboarding: isProjectExist.data ? isProjectExist.data.isProjectOnboarding : false,
           isuserExist:isuserExist.data?.userexist,
           Permissionname:Permissionname.data,
          
         }
           history.push({
             pathname: '/map-project',
             state: { detail: projectData }
           });        
          
          return;
        }

      }
      else{
        const isProjectExist = await API.get(
          `Procore/VerifyProject?ProjectID=${""}&CompanyID=${companyId?.toString()}`
        );
        if (isProjectExist && isProjectExist.data && isProjectExist.data.kriyagoProjectId === "") {
          const projectData = {
            id: isProjectExist.data.id,
            projectName: isProjectExist.data ? isProjectExist.data.name : 0,
            requirementList: isProjectExist.data && isProjectExist.data.requirementList ? isProjectExist.data.requirementList : [],
            selectedRequirementList: [],
            additionalInsured: isProjectExist.data ? isProjectExist.data.additionalInsured : "",
            certificateHolder: isProjectExist.data ? isProjectExist.data.certificateHolder : "",
            Clientid1:isProjectExist.data.clientId,
            office: isProjectExist.data && isProjectExist.data.office ? isProjectExist.data.office : [],
         
            
           
          }
          sessionStorage.setItem("projectname", isProjectExist.data.name);
          history.push(
            `/?ProjectID=${isProjectExist.data.id}&CompanyID=${companyId}&mode=global`
          );
          window.location.reload();
          
          
          return;
        }
      }

    

      setLoading(false);
      return;
    } catch (e) {
      console.log(e);
      setErrorMessage(e.message);
    }

    setLoading(false);
  }, [history]);

 

   useEffect(()  => {
      onValidateLogin();
   }, [onValidateLogin]);

  return {
    isLoading,
    errorMessage,
    errorMessage1,
    errorMessage2,
    Permissionname,
    userExist,
    
  };
}




export function useAuthenticate() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isAuthorizing, setIsAuthorizing] = useState(true);
  const [isPreviouslyAuthorised, setIsPreviouslyAuthorised] = useState(false);
  const history = useHistory();

  const onAuth = useCallback(
    async (data = null) => {

      try {
        setIsAuthorizing(true);
        const token = sessionStorage.getItem("token");
        if (!token && isPreviouslyAuthorised) {
          let projectId = new URLSearchParams(window.location.search).get(
            "ProjectID"
          );
          let companyId = new URLSearchParams(window.location.search).get(
            "CompanyID"
          );
          history.push("/login", {
            projectId: projectId,
            companyId: companyId,
          });
          return;
        } else {
          let parentWin = window;
          console.log(ENV);
          const insideProcore =
            // window.location.ancestorOrigins.contains(PROCORE_URL) ||
            // window.location.ancestorOrigins.contains(PROCORE_US_URL)||
            parentWin.document.referrer.includes(PROCORE_URL) ||
            parentWin.document.referrer.includes(PROCORE_US_URL)||
            ENV === "local";
          if (!token && insideProcore) {
            const context = procoreIframeHelpers.initialize();
            var Base_url;
              if (parentWin.document.referrer.includes(PROCORE_URL)) {
                Base_url = PROCORE_URL;
              } else if (parentWin.document.referrer.includes(PROCORE_US_URL)) {
                Base_url = PROCORE_US_URL;
              } else {
                Base_url = PROCORE_URL; // or assign a different value if none of the conditions are met
              }
            const url = `${Base_url}/oauth/authorize?client_id=${CLIENT_ID}&response_type=token&redirect_uri=${AUTH_REDIRECT_URI}`;

            context.authentication.authenticate({
              url: url,
              onSuccess: async function (accessToken) {
                sessionStorage.setItem("token", accessToken);
                API.defaults.headers["accessToken"] = accessToken;
                setIsAuthorizing(false);
                setIsAuthorized(true);
                setIsPreviouslyAuthorised(true);
                if (data) {
                  API.defaults.headers["CompanyId"] = data.companyId;
                  if(data.navigateURL){
                    history.push(
                      `/${data?.navigateURL}?ProjectID=${data.projectId}&CompanyID=${data.companyId}`
                    );
                    return;
                  }
                  history.push(
                    `/?ProjectID=${data.projectId}&CompanyID=${data.companyId}`
                  );
                  return;
                }
                // const me = await API.get(`GetLoggedInUser`);
                // if (me.data) {
                //   const userData = JSON.stringify(me.data);
                //   sessionStorage.setItem("userData", userData);
                //   setIsAuthorizing(false);
                //   setIsAuthorized(true);
                // } else {
                //   setIsAuthorizing(false);
                //   setIsAuthorized(false);
                // }
              },
              onFailure: function (error) {
                console.log(error);
                sessionStorage.clear();
                setIsAuthorizing(false);
                setIsAuthorized(false);
              },
            });
          } else if (token && insideProcore) {
            setIsAuthorizing(false);
            setIsAuthorized(token ? true : false);
          } else {
            sessionStorage.clear();
            setIsAuthorizing(false);
            setIsAuthorized(false);
          }
        }
        return;
      } catch (e) {
        console.log(e);
        let projectId = new URLSearchParams(window.location.search).get(
          "ProjectID"
        );
        let companyId = new URLSearchParams(window.location.search).get(
          "CompanyID"
        );
        history.push("/login", { projectId: projectId, companyId: companyId });
        return;
      }
    },
    [history, isPreviouslyAuthorised]
  );

  return {
    isAuthorized,
    isAuthorizing,
    onAuth,
  };
}
