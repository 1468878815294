import { useState, useCallback } from "react";
import API from "../../apis";
import {  CompanyUser } from "../../models";
import { useLocation } from "react-router-dom";
import { showErrorMessage } from "../../utils";

export function LoadMapProject() {

  const location :any = useLocation();
  const [ companyUsers, setCompanyUsers] = useState<CompanyUser[]>([]);
  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const [isAddProject, setAddProject] = useState(false);
  const clientid =  location?.state?.detail?.Clientid1;
  const office = location?.state?.detail?.office;

  const onValidateProject = useCallback(
    async () => {
      setUsersLoading(true);
      try {
        let projectId = location?.state?.detail?.id
        let companyId = sessionStorage.getItem('companyId');

        if (projectId !== null && projectId !== "null") {
          const isProjectExist = await API.get(
            `Procore/VerifyProject?ProjectID=${projectId}&CompanyID=${companyId?.toString()}`
          );

          if(isProjectExist.data && isProjectExist.data.isInValidToCreateNewProject){
            showErrorMessage(`The project for ${isProjectExist.data.name} is in the onboarding phase in Jones.`);
            setUsersLoading(false);
            return false;
          }
        }

        return true;
      } catch (e) {
        console.log(e);
        setUsersLoading(false);
        return false;
      }
    },
    [location]
  );
    
  const onLoadCompanysUsers = useCallback(
    async () => {
      setUsersLoading(true);
      try {
        let projectId = location?.state?.detail?.id
        let companyId = sessionStorage.getItem('companyId');
        const response = await API.get(
          `Procore/GetCompanyUsers?companyId=${companyId?.toString()}&projectId=${projectId}`
        );
        setUsersLoading(false);
        setCompanyUsers(response.data)
        return response.data;
      } catch (e) {
        console.log(e);
        setUsersLoading(false);
      }
    },
    [location]
  );


  return {
    isAddProject,
    setAddProject,
    onLoadCompanysUsers,
    companyUsers,
    usersLoading,
    clientid,
    office,
    onValidateProject,

  };
}
