import  axios  from 'axios';
import { BASE_URL, PROCORE_URL } from './env'
import { createBrowserHistory  } from "history";
import type { AxiosRequestConfig, AxiosError } from 'axios';
const history = createBrowserHistory();

 const API =  axios.create({
    baseURL: BASE_URL
  });

  
  API.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = sessionStorage.getItem("token");
      const companyId = sessionStorage.getItem("companyId");
  
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers["accessToken"] = token;
      }
      if(companyId){
        API.defaults.headers["CompanyId"] = companyId;
        config.headers["CompanyId"] = companyId;
      }
  
      return config;
    },
    (error: AxiosError) => error
  );

  API.interceptors.response.use(
    response => response,
    error => {
      const {status} = error?.response;

      if (status === 401 || status === 403) {
        let projectId = new URLSearchParams(window.location.search).get(
          "ProjectID"
        );
        let companyId = new URLSearchParams(window.location.search).get(
          "CompanyID"
        );
        sessionStorage.clear();
        history.push("/login", { projectId: projectId, companyId: companyId })
        history.go(0);
        return;
      }
     return Promise.reject(error);
   }
  );

  export default API;

  export const SANDBOXAPI =  axios.create({
    baseURL: PROCORE_URL
  });