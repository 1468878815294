import React, { useState } from "react";
import { Tabs } from "@procore/core-react";
import { TabItem } from "./models";

import UserManagement from "./pages/user-management";
import AllRequest from "./pages/all-request";
import GlobalAllRequest from "./pages/global-request";
import { useLoadApp } from "./hooks"
import SeetingIcon from "./shared/status-icons/Setting";
import ProcorePermission from "../src/components/ProcorePermission";
import CommitmentRequest from "./pages/Request-for-commitments";
import RecordHub from "./pages/Record-hub";
import GlobalRecordHub from "./pages/global-record-Hub";


function App() {
    const { isLoading, errorMessage, errorMessage1, errorMessage2, Permissionname, userExist } = useLoadApp();
    var tabs: any = [];
    let mode = new URLSearchParams(window.location.search).get(
        "mode"
    );
    const projectname = sessionStorage.getItem("projectname");


    if (mode === "global") {

        tabs = [
            {
                id: "all-requests",
                name: "Request COIs",
                selected: true,
                data: null,
                component: GlobalAllRequest,
            },
            {
                id: "manage-users",
                name: "User Management",
                selected: false,
                data: null,
                component: UserManagement,
            }
        ];

    }
    else {
        tabs = [
            {
                id: "all-requests",
                name: "Request COIs",
                selected: true,
                data: null,
                component: AllRequest,
            },
            {
                id: "commitment-request",
                name: " Request for Commitments ",
                selected: false,
                data: null,
                component: CommitmentRequest,
            },
            {
                id: "manage-users",
                name: "User Management",
                selected: false,
                data: null,
                component: UserManagement,
            }

        ];
    }


    const [selectedTab, setSelectedTab] = useState<TabItem | null>(tabs[0]);

    if (isLoading) {
        return (
            <div className="container container-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="alert alert-light text-center">
                            <img src={"/images/spinner.gif"} alt="Loading" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if (errorMessage1 && !userExist?.userexist) {
        var text1 = errorMessage1
        return (
            <div className="container container-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="alert text-center globalsetup">
                            <h5>{errorMessage1}</h5>
                            <h6>{errorMessage2}</h6>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

    if (userExist?.userexist) {
        if (userExist?.recordHubExist) {
            return (
                <div className="p-4">      
                    {mode === "global"? (
                        <>
                            <div className="h4">
                                <span className="pr-3">{<SeetingIcon />}</span>
                                Company Level COI Requests
                            </div>
                            <GlobalRecordHub objectid={userExist.objectid}
                            loadingGlobalJonesVendors={true}
                            Coifileload={true}
                            onLoadGlobalJonesVendors={0}
                            jonesGlobalVendor={0}
                            onUploadCoiFiles={0}
                            onLoadCoiFiles={0} />   

                                                 
                        </>
                    ) : (
                        <>
                            <div className="h4">
                                <span className="pr-3">{<SeetingIcon />}</span>
                                {projectname}
                                
                            </div>
                            <RecordHub objectid={userExist.objectid} />
                        </>

                    )}
                </div>
            );

        } else {
            return (

                <div>
                    <ProcorePermission Permissionname={Permissionname} />
                </div>
            );
        }

    }   


    if (errorMessage) {
        return (
            <div className="container container-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="alert alert-danger text-center">
                            <p>{errorMessage}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        if (mode === "global") {
            return (

                <div className="p-4">
                    <div className="h4">
                        <span className="pr-3">{<SeetingIcon />}</span> Company Level COIs Requests
                    </div>


                    <Tabs>
                        {tabs.map((tab: any) => {
                            return (

                                <Tabs.Tab
                                    key={`tab-thing-${tab.id}`}
                                    active={tab.id === selectedTab?.id}
                                    onClick={() => {
                                        tab.selected = true;
                                        setSelectedTab(tab);
                                    }}
                                >
                                    <Tabs.Link>{tab.name}</Tabs.Link>
                                </Tabs.Tab>
                            );
                        })}
                    </Tabs>
                    <>
                        {selectedTab && selectedTab.component ? (
                            <selectedTab.component {...selectedTab.data} />
                        ) : null}
                    </>
                </div>
            );
        }
        else {
            return (

                <div className="p-4">

                    <div className="h4">
                        <span className="pr-3">{<SeetingIcon />}</span>

                        {projectname}
                    </div>

                    <Tabs>
                        {tabs.map((tab: any) => {
                            return (

                                <Tabs.Tab
                                    key={`tab-thing-${tab.id}`}
                                    active={tab.id === selectedTab?.id}
                                    onClick={() => {
                                        tab.selected = true;
                                        setSelectedTab(tab);
                                    }}
                                >
                                    <Tabs.Link>{tab.name}</Tabs.Link>
                                </Tabs.Tab>
                            );
                        })}
                    </Tabs>
                    <>
                        {selectedTab && selectedTab.component ? (
                            <selectedTab.component {...selectedTab.data} />
                        ) : null}
                    </>
                </div>
            );
        }
    }

}



export default App;