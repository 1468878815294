import React, { useCallback, useMemo,useEffect } from "react";
import { Table, FormControl } from "react-bootstrap";

import { Modal } from "react-bootstrap";
import Select, { components } from "react-select";
import { useValidate } from "./hooks";

import {
  InsuranceRequirement,
  ProjectUser,
  PermissionTemplate,
  Vendor,
  CoiFile,
} from "../models";

import DeleteIcon from "../shared/DeleteIcon";
import UndoIcon from "../shared/UndoIcon";
import { Checkbox } from "@procore/core-react";
import Attachment from "./Attachment";

function MissingContact(props: {
  onClose: any;
  insurance: InsuranceRequirement;
  isModelOpen: boolean;
  setInsurance: any;
  onSubmit: any;
  showCOIModal: boolean;
  allVendors: Vendor[];
  permissionTemplates: any[];
  submitting: boolean;
}) {
  const {
    onClose,
    insurance,
    isModelOpen,
    setInsurance,
    onSubmit,
    allVendors,
    permissionTemplates,
    showCOIModal,
    submitting,
  } = props;

  const { onValidateEmail, validating } = useValidate();

  const [isEmptyCleared, setIsEmptyCleared] = React.useState<boolean>(false);
  const [showCoiUploadStep, setShowCoiUploadStep] =
    React.useState<boolean>(false);
    const [kishor,setkishor]=React.useState<any>([])
  const [hasErrorsInFile, setHasErrorsInFile] = React.useState<boolean>();
 

  const filesError = useMemo(() => {
    const remainingVendors = insurance.vendors.filter(
      (x) =>
        x.emailAddress ||
        (x.contactCoiUser != null &&
          x.contactCoiUser.emailAddress &&
          x.contactCoiUser.lastName &&
          !x.contactCoiUser.isSkipped &&
          !x.contactCoiUser.errorMessage &&
          x.contactCoiUser.permissionTemplateId !== null)
    );
    if (remainingVendors && remainingVendors.length) {
      const vendorsWithMorethanOneFileError = remainingVendors.filter(
        (x) =>
          x.coiFiles &&
          x.coiFiles.length &&
          x.coiFiles.filter(
            (y) => y.errors && y.errors.length && y.errors.length > 1
          )?.length
      );
      const vendorsWithMultipleFileErrors = remainingVendors.filter(
        (x) => x.fileErrorsCount > 1
      );
      if (
        vendorsWithMultipleFileErrors &&
        vendorsWithMultipleFileErrors.length &&
        vendorsWithMultipleFileErrors.length
      ) {
        return "Errors Found. Please fix before submitting.";
      }

      if (
        vendorsWithMorethanOneFileError &&
        vendorsWithMorethanOneFileError.length &&
        vendorsWithMorethanOneFileError.length > 1
      ) {
        return "Errors Found. Please fix before submitting.";
      }
      const invalidVendors = remainingVendors.filter(
        (x) => (!x.coiFiles || !x.coiFiles.length) && !x.isRequestCoi
      );
      if (
        invalidVendors &&
        invalidVendors.length &&
        invalidVendors.length > 1
      ) {
        return "Errors Found. Please fix before submitting.";
      }

      const vendorsWithOneFileError = remainingVendors.filter(
        (x) =>
          x.coiFiles &&
          x.coiFiles.length &&
          x.coiFiles.filter(
            (y) => y.errors && y.errors.length && y.errors.length === 1
          )?.length
      );
      if (
        vendorsWithOneFileError &&
        vendorsWithOneFileError.length &&
        vendorsWithOneFileError.length > 1
      ) {
        return "Errors Found. Please fix before submitting.";
      }
      if (
        vendorsWithOneFileError &&
        vendorsWithOneFileError.length &&
        vendorsWithOneFileError.length === 1 &&
        (!invalidVendors || !invalidVendors.length)
      ) {
        let error = null;
        vendorsWithOneFileError[0].coiFiles?.forEach((e) => {
          if (e.errors && e.errors.length && e.errors.length === 1) {
            error = e.errors[0];
          }
        });
        return error;
      }
      if (
        invalidVendors &&
        invalidVendors.length &&
        invalidVendors.length === 1 &&
        (!vendorsWithOneFileError || !vendorsWithOneFileError.length) &&
        (!vendorsWithMorethanOneFileError ||
          !vendorsWithMorethanOneFileError.length)
      ) {
        return "Either attach a COI or select the Request COI box.";
      }

      if (
        invalidVendors &&
        invalidVendors.length &&
        (vendorsWithOneFileError?.length ||
          vendorsWithMorethanOneFileError?.length)
      ) {
        return "Errors Found. Please fix before submitting.";
      }

      const vendorsWithExceedingFileSizeLimit = remainingVendors.filter(
        (x) => Math.round(x.totalFileSize / 1024) > 25600
      );
      if (
        vendorsWithExceedingFileSizeLimit &&
        vendorsWithExceedingFileSizeLimit.length &&
        vendorsWithExceedingFileSizeLimit.length === 1
      ) {
        return 'Maximum size of all files should be less than 25MB.';
      }
      if (
        vendorsWithExceedingFileSizeLimit &&
        vendorsWithExceedingFileSizeLimit.length &&
        vendorsWithExceedingFileSizeLimit.length > 1
      ) {
        return "Errors Found. Please fix before submitting.";
      }
    } else {
      return null;
    }
  }, [insurance]);

  const permissionsList = useMemo(() => {
    if (permissionTemplates && permissionTemplates.length) {
      let data: any[] = [];
      permissionTemplates.forEach((e) => {
        if (e.options && e.options.length) {
          e.options.forEach((p: any) => {
            data.push(p);
          });
        }
      });
      return data;
    } else {
      return [];
    }
  }, [permissionTemplates]);

  const emptyUserVendorList = useMemo(() => {
    if (isEmptyCleared) {
      return [];
    }

    let data = insurance.vendors.filter(
      (x) => !x.emailAddress && x.projectUsers?.length === 0 && x.id > 0
    );

    if (data && data.length) {
      data.forEach((e) => {
        if (!e.contactCoiUser) {
          e.contactCoiUser = {
            id: 0,
            name: "",
            emailAddress: "",
            firstName: "",
            lastName: "",
            vendor: null,
            notes: "",
            isSkipped: false,
            permissionTemplateId: 0,
            errorMessage: "",
            lastNameError: "",
          };
        }
      });
      return data;
    }
    return [];
  }, [insurance, isEmptyCleared]);

  const assignCOIVendorList = useMemo(() => {
    if (insurance?.vendors.length > 0) {
      const data = insurance.vendors.filter(
        (x) => x.projectUsers?.length > 0

       
      );
    
      if (data && data.length) {
        data.forEach((e) => {
          if (!e.projectUsers.find((x) => x.name === "Skip Request")) {
            e.projectUsers = [
              {
                id: -1,
                name: "Skip Request",
                emailAddress: "",
                lastName: "Skip ",
                firstName: "Request",
                notes: "",
                vendor: null,
                isSkipped: false,
                permissionTemplateId: 0,
                errorMessage: "",
                lastNameError: "",
              },
              ...e.projectUsers,
            ];
          }
        });
        return data;
      } else {
        return [];
      }
    }
  }, [insurance]);

  const newlyAssignedCOIVendorList = useMemo(() => {
    if (insurance?.vendors.length > 0) {
      const data = insurance.vendors.filter(
        (x) => x.newlyAddedContactUser
      );
      return data
    }
  }, [insurance]);

  const coiUploadVendors = React.useMemo(() => {
    if (insurance.requirementId !== null && insurance.requirementId === 0) {
      return [];
    }
    let remainingVendors = insurance.vendors.filter(
      (x) =>
        x.emailAddress ||
        (x.contactCoiUser != null &&
          x.contactCoiUser.emailAddress &&
          x.contactCoiUser.lastName &&
          !x.contactCoiUser.isSkipped &&
          !x.contactCoiUser.errorMessage &&
          x.contactCoiUser.permissionTemplateId !== null)
    );
    let emptyUsers = insurance.vendors.filter(
      (x) => !x.emailAddress && x.projectUsers?.length === 0 && x.id > 0
    );
    const assignCoiUsers = insurance.vendors.filter(
      (x) =>  x.projectUsers?.length > 0
    );
    if (remainingVendors && remainingVendors.length) {
      remainingVendors.forEach((e) => {
        e.isRequestCoi =
          e.isRequestCoi === null || e.isRequestCoi === undefined
            ? true
            : e.isRequestCoi;
      });
      if (!emptyUsers?.length && !assignCoiUsers?.length) {
       
        
        setShowCoiUploadStep(true);
      }
    }

    return remainingVendors;
  }, [insurance]);

  const [coiSelectedVendor, setCoiSelectedVendor] = React.useState<any[]>([]);

 

   const validateEmail = useCallback(
     async (email: string, vendor: any) => {
       const validEmailString = String(email)
         .toLocaleLowerCase()
         .match(
           /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
         );
         // .match(
         //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
         // );
       const existingEmails = insurance.vendors.filter(
         (x) =>
           x.contactCoiUser?.emailAddress?.toLowerCase() === email.toLowerCase()
       );
       if (!validEmailString || (existingEmails && existingEmails.length > 1)) {
        
         const d = insurance.vendors.map((e) => {         
           if (e.id === vendor.id) {
             return {
               ...e,
               contactCoiUser: {
                 ...e.contactCoiUser,
                 errorMessage: !email
                   ? "Email Address Required"
                   : !validEmailString
                   ? "Invalid Email Address"
                   : existingEmails && existingEmails.length > 1
                   ? "Email address already exists for another company"
                   : "",
               },
             };
           }
           return e;
         });
         setInsurance({
           ...insurance,
           vendors: d,
         });
       } else {
         try {
   
           const vendorIds = allVendors.map((e) => {
             return e.id;
           });
           let errorMessage="";
          
           const isExists = await onValidateEmail(
             insurance.companyId,
             vendorIds,
             email
           );
           
            errorMessage = isExists
             ? "This user is already associated with another company"
             : "";
          
         

           setInsurance((insurance: any) => {
             return {
               ...insurance,
               vendors: insurance.vendors.map((e: any) => {
                 if (e.id === vendor.id) {
                   return {
                     ...e,
                     contactCoiUser: {
                       ...e.contactCoiUser,
                       errorMessage: errorMessage,
                     },
                   };
                 }
                 return e;
               }),
             };
           });
         } catch (e) {
           console.log(e);
         }
       }
     },
     [insurance, setInsurance, onValidateEmail, allVendors]
   );
  const validateDropEmail = useCallback(
    async (email: string, vendor: any, userCheck: boolean) => 
    {
      const validEmailString = String(email)
        .toLocaleLowerCase()
        .match(
          /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
        );
        let errorMessage ="";

      const existingEmails = insurance.vendors.filter(
        (x) =>
          x.contactCoiUser?.emailAddress?.toLowerCase() === email.toLowerCase()
      );
      
  if(vendor.contactName =='Skip Request')
  {
    errorMessage="";

  }else{
    
     errorMessage = !email
        ? "Email Address Required"
        : !validEmailString
        ? "Invalid Email Address"
        : existingEmails && existingEmails.length > 1
        ? "Email address already exists for another company"
        : "";
  }
      
  
      try {
        const vendorIds = allVendors.map((e) => e.id);
  
        let isExists = false;
        if (userCheck) {
          isExists = await onValidateEmail(insurance.companyId, vendorIds, email);
        }
   
        setInsurance((prevInsurance: any) => {
          return {
            ...prevInsurance,
            vendors: prevInsurance.vendors.map((e: any) => {
              const emailErrorMessage =
                e.id === vendor.id ? errorMessage : e.contactCoiUser?.errorMessage;
                
              return {
              //   ...e,
              //    contactCoiUser : 
              // emailErrorMessage ? errorMessage : null,
              //   // contactCoiUser: 
              //   //  {
              //   //   ...e.contactCoiUser,
              //   //   errorMessage : emailErrorMessage,
              //   // },
              ...e,
              contactCoiUser: emailErrorMessage ? 
                {
                  ...e.contactCoiUser,
                  errorMessage: emailErrorMessage,
                } 
                : null,
              };
            }),
          };
        });
      
      } catch (e) {
        console.log(e);
      }
    
    },
 
    [insurance, setInsurance, onValidateEmail, allVendors]
  );
  
  

  const validateLastName = useCallback(
    async (lastName: string, vendor: any) => {
      const d = insurance.vendors.map((e) => {
        if (e.id === vendor.id) {
          return {
            ...e,
            contactCoiUser: {
              ...e.contactCoiUser,
              lastNameError: !lastName ? "Last Name Required" : "",
            },
          };
        }
        return e;
      });
      setInsurance({
        ...insurance,
        vendors: d,
      });
    },
    [insurance, setInsurance]
  );

  const validateFileErrors = useCallback(() => {
    let isValid = true;
    const fileVendors = insurance.vendors.filter(
      (x) => x.coiFiles && x.coiFiles.length > 0
    );
    if (fileVendors && fileVendors.length) {
      for (let index = 0; index < fileVendors.length; index++) {
        const errorFiles = fileVendors[index].coiFiles?.filter(
          (x) => x.errors && x.errors.length
        );
        if (errorFiles && errorFiles.length) {
          isValid = false;
          break;
        }

        if (fileVendors && fileVendors[index].coiFiles?.length) {
          let coiFilesTotalSize: number = 0;

          fileVendors[index]?.coiFiles?.forEach((e) => {
            if (e?.file) {
              coiFilesTotalSize += e.file.size;
            }
          });
          const fileSizeinMB = Math.round(coiFilesTotalSize / 1024);
          if (fileSizeinMB > 25600) {
            isValid = false;
            break;
          }
        }
      }
    }
    const nonFileVendors = insurance.vendors.filter(
      (x) => !x.isRequestCoi && (!x.coiFiles || !x.coiFiles.length)
    );
    if (
      nonFileVendors?.length &&
      insurance.requirementId !== null &&
      insurance.requirementId > 0
    ) {
      isValid = false;
    }
    return isValid;
  }, [insurance]);

  const submitRequest = useCallback(() => {
    const remainingVendors = insurance.vendors.filter(
      (x) =>
        x.emailAddress ||
        (x.contactCoiUser != null &&
          x.contactCoiUser.emailAddress &&
          x.contactCoiUser.lastName &&
          !x.contactCoiUser.isSkipped &&
          !x.contactCoiUser.errorMessage &&
          x.contactCoiUser.permissionTemplateId !== null)
    );
    if (remainingVendors.length === 0) {
      onSubmit(null);
    } else {
      let isValid = validateFileErrors();
      if (isValid) {
        onSubmit(insurance.vendors);
      } else {
        setHasErrorsInFile(true);
      }
    }
  }, [insurance, onSubmit, validateFileErrors]);

  const setMissingUsers = useCallback(async () => {
    const remainingVendors = insurance.vendors.filter(
      (x) =>
        (x.projectUsers && x.projectUsers.length > 0) ||
        (x.contactCoiUser != null &&
          x.contactCoiUser.emailAddress &&
          x.contactCoiUser.lastName &&
          !x.contactCoiUser.isSkipped &&
          !x.contactCoiUser.errorMessage &&
          x.contactCoiUser?.permissionTemplateId !== null)
    );
    if (remainingVendors.length === 0) {
      onSubmit(null);
    }
    await setInsurance((insurance: any) => {
      return {
        ...insurance,
        vendors: [...remainingVendors],
      };
    });
    if (assignCOIVendorList?.length === 0) {
      if (insurance.requirementId !== null && insurance.requirementId > 0) {
        setShowCoiUploadStep(true);
      } else if (
        insurance.requirementId !== null &&
        insurance.requirementId === 0
      ) {
        submitRequest();
      }
    } else {
      setIsEmptyCleared(true);
    }
  }, [insurance, setInsurance, onSubmit, assignCOIVendorList, submitRequest]);

  const setProjectUserPermissionTemplate = useCallback(
    (permission: PermissionTemplate | null, vendor: any) => {
      const d = insurance.vendors.map((e) => {
        if (e.id === vendor.id) {
          return {
            ...e,
            contactCoiUser: {
              ...e.contactCoiUser,
              permissionTemplateId: permission?.id ?? 0,
            },
          };
        }
        return e;
      });
      setInsurance({
        ...insurance,
        vendors: d,
      });
    },
    [insurance, setInsurance]
  );

  const setEmailAddress = useCallback(
    (val: string, vendor: any) => {
      const d = insurance.vendors.map((e) => {
        if (e.id === vendor.id) {
          return {
            ...e,
            contactCoiUser: {
              ...e.contactCoiUser,
              emailAddress: val,
            },
          };
        }
        return e;
      });
      setInsurance({
        ...insurance,
        vendors: d,
      });
    },
    [insurance, setInsurance]
  );

  const setLastName = useCallback(
    (val: string, vendor: any) => {
      const d = insurance.vendors.map((e) => {
        if (e.id === vendor.id) {
          return {
            ...e,
            contactCoiUser: {
              ...e.contactCoiUser,
              lastName: val,
            },
          };
        }
        return e;
      });
      setInsurance({
        ...insurance,
        vendors: d,
      });
    },
    [insurance, setInsurance]
  );

  const setSkipped = useCallback(
    (val: boolean, vendor: any) => {
      const d = insurance.vendors.map((e) => {
        if (e.id === vendor.id) {
          return {
            ...e,
            contactCoiUser: {
              ...e.contactCoiUser,
              errorMessage: "",
              emailAddress: "",
              lastName: "",
              permissionTemplateId: 0,
              isSkipped: val,
              lastNameError: "",
            },
          };
        }
        return e;
      });
      setInsurance({
        ...insurance,
        vendors: d,
      });
    },
    [insurance, setInsurance]
  );

  useEffect(() => {
    let coiTagUser: ProjectUser;  

      assignCOIVendorList?.map((listValue, index) => {
        const coiUser = listValue.projectUsers.find((y) => y.notes === "#COI");
      if (coiUser) {
        setCOIContactRoleToUser(coiUser,listValue)
        validateDropEmail(coiUser.emailAddress,listValue,false)
       
      }
        })

    }, []);
  
  

  const setCOIContactRoleToUser = useCallback(
    async (selectedUser: ProjectUser | null, vendor: any) => {
      if (selectedUser) {
        let nonCoiVendor = insurance.vendors.find((x) => x.id === vendor?.id);

        if (nonCoiVendor) {
          if (
            coiSelectedVendor.length > 0 &&
            coiSelectedVendor.find((x) => x.id === vendor?.id)
          ) {
            const index = coiSelectedVendor.findIndex(
              (x) => x.id === vendor?.id
            );
            nonCoiVendor.emailAddress = selectedUser?.emailAddress ?? "";
            nonCoiVendor.coiContactUserId = selectedUser?.id ?? 0;
            nonCoiVendor.contactName = selectedUser?.name;
            nonCoiVendor.newlyAddedContactUser = selectedUser;            
            coiSelectedVendor[index] = nonCoiVendor;
            setCoiSelectedVendor(coiSelectedVendor);
          } else {
            nonCoiVendor.emailAddress = selectedUser?.emailAddress ?? "";
            nonCoiVendor.coiContactUserId = selectedUser?.id ?? 0;
            nonCoiVendor.contactName = selectedUser?.name;
            nonCoiVendor.newlyAddedContactUser = selectedUser;
            setCoiSelectedVendor((oldVendor) => [...oldVendor, nonCoiVendor]);
          }
        }
      }
    },
    [coiSelectedVendor, setCoiSelectedVendor, insurance.vendors]
  );

  const setCoiRoleUser = useCallback(() => {
    const remainingVendors = insurance.vendors.filter(
      (x) =>
        x.emailAddress ||
        (x.contactCoiUser != null &&
          x.contactCoiUser.emailAddress &&
          x.contactCoiUser.lastName &&
          !x.contactCoiUser.isSkipped &&
          !x.contactCoiUser.errorMessage &&
          x.contactCoiUser.permissionTemplateId !== null)
    );
    if (remainingVendors.length === 0) {
      onSubmit(null);
    } else {
      remainingVendors.forEach((e) => {
        e.isRequestCoi = true;
      });
      setInsurance({
        ...insurance,
        vendors: remainingVendors,
      });
      if (insurance.requirementId !== null && insurance.requirementId > 0) {
        setShowCoiUploadStep(true);
      } else if (
        insurance.requirementId !== null &&
        insurance.requirementId === 0
      ) {
        submitRequest();
      }
      //onSubmit(insurance.vendors);
    }
  }, [insurance, setInsurance, onSubmit, submitRequest]);

  const setFiles = useCallback(
    (files: FileList, vendor: Vendor) => {
      let errors: string[] = [];
      if (files && files.length) {
        const validFileExtensions = ["jpg", "jpeg", "pdf"];
        const extension = files[0].name.split(".")?.pop()?.toLowerCase();
        const isExtensionAllowed = validFileExtensions.find(
          (x) => x === extension
        );
        if (!isExtensionAllowed) {
          let mgs = "Invalid file format. Accepted formats are PDF and JPEG.";

          errors.push(mgs);
        }
        const fileSize = Math.round(files[0].size / 1024);
        // filesize greater than 25MB
        if (fileSize > 25600) {
          let mgs = 'Maximum file size is 25MB.';
          errors.push(mgs);
        }
      }
      const d = insurance.vendors.map((e) => {
        if (e.id === vendor.id) {
          if (e.coiFiles && e.coiFiles.length) {
            return {
              ...e,
              isRequestCoi: false,
              coiFiles: [
                ...e?.coiFiles,
                {
                  file: files[0],
                  errors: errors,
                },
              ],
              totalFileSize: e.totalFileSize + files[0].size,
              fileErrorsCount: (e.fileErrorsCount ?? 0) + (errors?.length ?? 0),
            };
          } else {
            return {
              ...e,
              isRequestCoi: false,
              coiFiles: [
                {
                  file: files[0],
                  errors: errors,
                },
              ],
              totalFileSize: files[0].size,
              fileErrorsCount: (e.fileErrorsCount ?? 0) + (errors?.length ?? 0),
            };
          }
        }
        return e;
      });

      setInsurance({
        ...insurance,
        vendors: d,
      });
    },
    [insurance, setInsurance]
  );

  const removeFile = useCallback(
    (file: CoiFile, vendor: any) => {
      const d = insurance.vendors.map((e) => {
        if (e.id === vendor.id) {
          if (e.coiFiles && e.coiFiles.length) {
            const files = e.coiFiles.filter(
              (x) => x.file.name !== file.file.name
            );
            return {
              ...e,
              isRequestCoi: files && files.length > 0 ? false : true,
              coiFiles: files,
              totalFileSize: e.totalFileSize - file.file.size,
              fileErrorsCount: file.errors?.length
                ? e.fileErrorsCount - file.errors.length
                : e.fileErrorsCount,
            };
          }
        }
        return e;
      });

      let isValid = true;
      const fileVendors = d?.filter((x) => x.coiFiles && x.coiFiles.length);
      if (fileVendors && fileVendors.length) {
        for (let index = 0; index < fileVendors.length; index++) {
          const errorFiles = fileVendors[index].coiFiles?.filter(
            (x) => x.errors && x.errors.length
          );
          if (errorFiles && errorFiles.length) {
            isValid = false;
            break;
          }
        }
      }

      setHasErrorsInFile(!isValid);

      setInsurance({
        ...insurance,
        vendors: d,
      });
    },
    [insurance, setInsurance]
  );

  const setIsCoiRequest = useCallback(
    (val: boolean, vendor: any) => {
      const d = insurance.vendors.map((e) => {
        if (e.id === vendor.id) {
          return {
            ...e,
            isRequestCoi: val,
          };
        }
        return e;
      });

      let isValid = true;
      const fileVendors = d?.filter((x) => x.coiFiles && x.coiFiles.length);
      if (fileVendors && fileVendors.length) {
        for (let index = 0; index < fileVendors.length; index++) {
          const errorFiles = fileVendors[index].coiFiles?.filter(
            (x) => x.errors && x.errors.length
          );
          if (errorFiles && errorFiles.length) {
            isValid = false;
            break;
          }
        }
      }

      setHasErrorsInFile(!isValid);

      setInsurance({
        ...insurance,
        vendors: d,
      });
    },
    [insurance, setInsurance]
  );

  const onProjectUserFilter = useCallback((option: any, searchText: any) => {
    if (option.data.name?.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }, []);

  const onPermissionFilter = useCallback((option: any, searchText: any) => {
    if (option.data.name?.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }, []);

  const setBackgroundColor = {
    backgroundColor: "#DADADA",
  };

  const MenuList = (props: any) => {
    return (
      <components.MenuList {...props}>{props.children}</components.MenuList>
    );
  };
 

  
 

  return (
    <Modal
      show={isModelOpen}
      keyboard={false}
      animation={false}
      backdrop="static"
      centered
      onHide={onClose}
      dialogClassName={
        showCOIModal && emptyUserVendorList?.length
          ? "create-request empty-user-modal"
          : emptyUserVendorList?.length
          ? "empty-user-modal"
          : showCOIModal && !emptyUserVendorList?.length && !showCoiUploadStep
          ? "create-request coi-modal"
          : showCoiUploadStep
          ? "create-request empty-user-modal"
          : "coi-modal"
      }
    >
      <Modal.Body
        key={insurance?.vendors?.length}
        // style={{
        //   height: "auto",
        //   padding: "1.6rem",
        // }}
      >
        <h1 className="custom-header">
          {emptyUserVendorList?.length && !showCoiUploadStep ? (
            <p>
              Missing user(s) for {emptyUserVendorList?.length} out of{" "}
              {insurance?.vendors.filter((x) => x.id > 0)?.length} Companies
            </p>
          ) : 
            (
              !emptyUserVendorList?.length &&
              !showCoiUploadStep 
              ) ? (
            <p>
              Missing COI Point of Contact for {assignCOIVendorList?.length} out
              of {insurance?.vendors.filter((x) => x.id > 0)?.length} Companies
            </p>
          ) : showCoiUploadStep ? (
            <p>Upload COI on Behalf of the Invitee or Submit COI Request</p>
          ) : null}
        </h1>
        {emptyUserVendorList?.length && !showCoiUploadStep ? (
          <div className="header-details">
            <p>
              No user(s) assigned to this company/these companies.<br></br>
              Please add a contact to enable sending a request; this contact
              will be added to the directory tool.
            </p>
            <Table striped bordered hover size="sm" className="mt-2">
              <thead>
                <tr style={setBackgroundColor}>
                  <th className="icon-width"></th>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Last Name</th>
                  <th>Permission Template</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {emptyUserVendorList.map((listValue, index) => {
                  return (
                    <tr key={index}>
                      <td
                        style={
                          listValue.contactCoiUser?.errorMessage ||
                          listValue.contactCoiUser?.lastNameError
                            ? { paddingTop: "13px" }
                            : { verticalAlign: "middle" }
                        }
                      >
                        <img
                          src={"/images/Procore_Company_img.PNG"}
                          alt="Procore company"
                          width="30"
                          height="25"
                        />
                      </td>
                      <td
                        style={
                          listValue.contactCoiUser?.errorMessage ||
                          listValue.contactCoiUser?.lastNameError
                            ? { paddingTop: "13px", width: "25%" }
                            : { verticalAlign: "middle", width: "25%" }
                        }
                      >
                        {listValue.name}
                      </td>
                      <td>
                        <FormControl
                         
                          value={listValue.contactCoiUser?.emailAddress}
                          className={
                            !listValue.contactCoiUser?.isSkipped &&
                            listValue.contactCoiUser?.errorMessage
                              ? "invalid-control"
                              : ""
                          }
                          disabled={listValue?.contactCoiUser?.isSkipped}
                          onChange={(e) => {
                            setEmailAddress(e.target.value, listValue);
                          }}
                          onBlur={(e: any) => {
                            validateEmail(e.target.value, listValue);
                          }}
                        />
                        {!listValue.contactCoiUser?.isSkipped &&
                        listValue.contactCoiUser?.errorMessage ? (
                          <p className="text-danger validation-label">
                            {listValue.contactCoiUser?.errorMessage}
                          </p>
                        ) : null}
                      </td>
                      <td>
                        <FormControl
                          value={listValue.contactCoiUser?.lastName}
                          className={
                            !listValue.contactCoiUser?.isSkipped &&
                            listValue.contactCoiUser?.lastNameError
                              ? "invalid-control"
                              : ""
                          }
                          disabled={listValue?.contactCoiUser?.isSkipped}
                          onChange={(e) => {
                            setLastName(e.target.value, listValue);
                          }}
                          onBlur={(e: any) => {
                            validateLastName(e.target.value, listValue);
                          }}
                        />
                        {!listValue.contactCoiUser?.isSkipped &&
                        listValue.contactCoiUser?.lastNameError ? (
                          <p className="text-danger validation-label">
                            {listValue.contactCoiUser?.lastNameError}
                          </p>
                        ) : null}
                      </td>
                      <td style={{ width: "25%" }}>
                        <Select
                          className="custom-select-input"
                          classNamePrefix="permission-select"
                          isMulti={false}
                          value={permissionsList?.find(
                            (x) =>
                              x.id ===
                              listValue?.contactCoiUser?.permissionTemplateId
                          )}
                          isSearchable
                          isDisabled={listValue?.contactCoiUser?.isSkipped}
                          hideSelectedOptions={false}
                          onChange={(e, metaData) => {
                            setProjectUserPermissionTemplate(
                              e as PermissionTemplate | null,
                              listValue
                            );
                          }}
                          getOptionLabel={(option) => `${option.name}`}
                          getOptionValue={(option) => `${option.name}`}
                          options={permissionTemplates}
                          defaultValue={{
                            id: 0,
                            name: "Apply Permission Template Later",
                            type: "",
                            projectSpecific: false,
                          }}
                          filterOption={onPermissionFilter}
                          placeholder={"Start typing to search permissions..."}
                          noOptionsMessage={() => null}
                        />
                      </td>
                      <td
                        className="text-center"
                        style={
                          listValue.contactCoiUser?.errorMessage ||
                          listValue.contactCoiUser?.lastNameError
                            ? { paddingTop: "13px" }
                            : { verticalAlign: "middle" }
                        }
                      >
                        {listValue?.contactCoiUser?.isSkipped ? (
                          <UndoIcon
                            onClick={() => {
                              setSkipped(
                                !listValue?.contactCoiUser?.isSkipped,
                                listValue
                              );
                            }}
                          />
                        ) : (
                          <DeleteIcon
                            onClick={() => {
                              setSkipped(
                                !listValue?.contactCoiUser?.isSkipped,
                                listValue
                              );
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : 
          (
            !emptyUserVendorList?.length &&
            !showCoiUploadStep
            ) ? (
          <div className="header-details">
            <p className="mt-3">
              No user(s) assigned the ‘COI Point of Contact’ role for
              companie(s) below. Assign an existing user, or click ‘Skip’ to
              assign later using the directory tool.
            </p>

            <Table striped bordered hover size="sm" className="mt-2">
              <thead>
                <tr style={setBackgroundColor}>
                  <th className="icon-width"></th>
                  <th className="w-50 align-middle">Name</th>
                  <th>Select User</th>
                
                </tr>
              </thead>
              <tbody>
                {console.log("assignCOIVendorList",assignCOIVendorList)}
                {assignCOIVendorList?.map((listValue, index) => {                
             
                  return (
                    <tr key={index}>
                      <td className="align-middle">
                        <img
                          src={"/images/Procore_Company_img.PNG"}
                          alt="Procore company"
                          width="30"
                          height="20"
                        />
                      </td>
                      <td className="align-middle"> {listValue.name} </td>
                      <td>
                        <Select
                          className="custom-select-input"
                          classNamePrefix="name-select"
                          isMulti={false}
                          isSearchable
                          closeMenuOnSelect
                          openMenuOnClick
                          hideSelectedOptions={false}
                          
                          onChange={(e:any, metaData) => {
                            setCOIContactRoleToUser(e, listValue); 
                            if (e) {
                              // Check if an option is selected
                              console.log("e",e)
                              validateDropEmail(e.emailAddress, listValue,false); 
                              
                            }
                                      
                          }}  
                                                
                                             
                         
                          getOptionLabel={(option) =>  `${`${option?.lastName === null ? '': option.lastName }` + " " + `${option?.firstName === null ? '': option.firstName }`  + " "+  `${option?.notes === null ? '': option.notes}`}`}
                          getOptionValue={(option) => `${`${option?.lastName === null ? '': option.lastName }` + " " + `${option?.firstName === null ? '': option.firstName }` + " "+  `${option?.notes === null ? '': option.notes}`}`}
                          options={listValue?.projectUsers}  
                          defaultValue={ listValue?.projectUsers.find((x) => x.notes === "#COI")}                       
                          filterOption={onProjectUserFilter}
                          placeholder={"Start typing to search people..."}
                          noOptionsMessage={() => null}
                          components={{ MenuList }}
                         
                        />  

                           {console.log("listValue",listValue.contactCoiUser) }

                                          
                       {!listValue.contactCoiUser?.isSkipped &&
                        listValue.contactCoiUser?.errorMessage ? (
                          <p className="text-danger validation-label">
                            {  listValue.contactCoiUser?.errorMessage + " (" + listValue.emailAddress + ")"}
                          </p>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
                {
                newlyAssignedCOIVendorList &&
                newlyAssignedCOIVendorList.length
                  ? newlyAssignedCOIVendorList.map((listValue, index) => {
                     /*  return (
                        <tr key={index}>
                          <td className="align-middle">
                            <img
                              src={"/images/Procore_Company_img.PNG"}
                              alt="Procore company"
                              width="30"
                              height="20"
                            />
                          </td>
                          <td className="align-middle"> {listValue.name} </td>
                          <td>
                            {listValue.contactName}
                          </td>
                        </tr>
                      ); */
                    })
                  : null}
              </tbody>
            </Table>
          </div>
        ) : showCoiUploadStep ? (
          <div className="header-details">
            <p className="mt-3">
              Any companies for which you do not upload a COI at this step will
              receive an email from Jones requesting them to submit a COI.
            </p>
            <div style={{ maxHeight: 460, overflowY: "auto" }}>
              <Table
                striped
                bordered
                hover
                size="sm"
                className="mt-2"
                style={{ overflowY: "auto" }}
              >
                <thead>
                  <tr style={setBackgroundColor}>
                    <th className="icon-width"></th>
                    <th style={{ width: "30%" }} className=" align-middle">
                      Name
                    </th>
                    <th style={{ width: "20%" }}>Request COI</th>
                    <th>Upload COI</th>
                  </tr>
                </thead>
                <tbody>
                  {coiUploadVendors?.map((listValue, index) => {
                    return (
                      <tr key={index} style={{ maxHeight: "40px" }}>
                        <td className="align-middle">
                          <img
                            src={"/images/Procore_Company_img.PNG"}
                            alt="Procore company"
                            width="30"
                            height="20"
                          />
                        </td>
                        <td className="align-middle"> {listValue.name} </td>
                        <td className="align-middle">
                          <div className="row no-gutters">
                            <Checkbox
                              className="mr-2 chbox"
                              disabled={
                                (listValue?.coiFiles !== null &&
                                listValue?.coiFiles?.length > 0) ||
                                submitting
                              }
                              checked={
                                listValue.isRequestCoi === true ? true : false
                              }
                              onChange={() => {}}
                              onClick={(e) => {
                                setIsCoiRequest(
                                  !listValue.isRequestCoi,
                                  listValue
                                );
                              }}
                            />
                            <label
                              className={
                                listValue?.coiFiles !== null &&
                                listValue?.coiFiles?.length > 0
                                  ? "disabled-label"
                                  : ""
                              }
                            >
                              Request COI
                            </label>
                          </div>
                        </td>
                        <td>
                          <Attachment
                            key={`attachment-${index}`}
                            actionDisabled={submitting}
                            index={index}
                            isFilesMandatory={!listValue.isRequestCoi}
                            files={listValue.coiFiles}
                            onFileAdded={(files: FileList) => {
                              if (files && files.length) {
                                setFiles(files, listValue);
                              }
                            }}
                            onFileRemoved={(file: CoiFile) => {
                              removeFile(file, listValue);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        ) : null}
      </Modal.Body>

      <Modal.Footer className="cusrom-card-footer">
        <div
          className={`${
            !emptyUserVendorList?.length || showCoiUploadStep ? "flex-fill" : ""
          } justify-content-between align-middle pt-1 pl-2`}
        >
          {emptyUserVendorList?.length && !showCoiUploadStep ? null : (
            <div
              className={`${
                showCoiUploadStep ? "justify-content-end" : ""
              } row`}
            >
              {!showCoiUploadStep ? (
                <div className={`header-details w-auto col-md-7`}>
                  <p className="warning-msg">
                    * You must complete ‘select user’ option(s) before sending
                    the COI request
                  </p>
                </div>
              ) : showCoiUploadStep && hasErrorsInFile ? (
                <div className={`header-details w-auto col-md-7`}>
                  <p className="text-danger">
                    {filesError ??
                      "Errors Found. Please fix before submitting."}
                  </p>
                </div>
              ) : null}
              <div className="col-md-5">
                <div className="row justify-content-end">
                  <button
                    className=" btn-cutom btn btn-dark blue-btn col-md-4"
                    disabled={submitting}
                    onClick={() => {
                      onSubmit(null);
                    }}
                  >
                    Cancel
                  </button>
                
                
                  {!showCoiUploadStep ? (
                   
                    <button
                      className="btn btn-secondary ml-2 btn-cutom  col-md-6"
                      // disabled={
                       
                      //   !(
                          
                      //     assignCOIVendorList?.length &&
                      //     coiSelectedVendor.length ===
                      //       assignCOIVendorList.length
                      //   ) || submitting 
                      // }
                      
                      disabled={
                        (assignCOIVendorList?.find(
                          (x) => x.contactCoiUser?.errorMessage &&
                          !x.contactCoiUser?.isSkipped
                        ) !== undefined) ||
                        !(
                          (assignCOIVendorList?.length &&
                            coiSelectedVendor.length === assignCOIVendorList.length) ||
                          submitting
                        )
                      }
                      
                      onClick={setCoiRoleUser}
                    >
                      {coiUploadVendors?.length || (insurance?.requirementId !== null && insurance?.requirementId > 0) ? "Continue" : "Submit/Request"}
                    </button>
                  ) : showCoiUploadStep &&
                    coiUploadVendors?.length &&
                    !submitting ? (
                    <button
                      className="btn btn-secondary ml-2 btn-cutom  col-md-6"
                      onClick={submitRequest}
                    >
                      Submit/Request
                    </button>
                  ) : (showCoiUploadStep &&
                      coiUploadVendors?.length &&
                      submitting) ||
                    (
                      !emptyUserVendorList?.length &&
                      !showCoiUploadStep &&
                      submitting) ? (
                    <img
                      className="ml-3 h5 mt-1"
                      src={"/images/loader.gif"}
                      alt="Loading"
                    />
                  ) : null}
                </div>
              </div>
            </div>
          )}
          {emptyUserVendorList?.length && !showCoiUploadStep ? (
            <div className={`text-right w-auto`}>
              {emptyUserVendorList?.length ? (
                <button
                  className="btn-cutom btn btn-dark blue-btn"
                  onClick={() => {
                    onSubmit(null);
                  }}
                  disabled={submitting}
                >
                  Cancel
                </button>
              ) : null}

              {emptyUserVendorList?.length &&
              !validating &&
              !showCoiUploadStep &&
              !submitting ? (
                <button
                  className="btn-cutom btn btn-dark blue-btn ml-3 float-right"
                  onClick={setMissingUsers}
                  style={{ fontSize: "13px" }}
                  disabled={
                    emptyUserVendorList.find(
                      (x) =>
                        x.contactCoiUser?.errorMessage &&
                        !x.contactCoiUser?.isSkipped
                    ) !== undefined ||
                    emptyUserVendorList.find(
                      (x) =>
                        !x.contactCoiUser?.isSkipped &&
                        (!x.contactCoiUser?.emailAddress ||
                          !x.contactCoiUser?.lastName)
                    ) !== undefined ||
                    submitting
                  }
                >
                  {assignCOIVendorList?.length
                    ? "Next"
                    : coiUploadVendors?.length || (insurance.requirementId !== null && insurance.requirementId > 0)
                    ? "Continue"
                    : "Submit/Request"}
                </button>
              ) : validating || submitting ? (
                <img
                  className="ml-3"
                  src={"/images/loader.gif"}
                  alt="Loading"
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default MissingContact;
