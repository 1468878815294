import React from "react";

export const columns: any = [
  {
    dataField: "emailAddress",
    caption: "COI Contact",
    width: "70%",
    allowEditing: false,
  },
  {
    caption: "Actions",
    width: "30%",
    type: "action",
    cssClass: "dx-datagrid-action",
  },
];
