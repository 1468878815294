import React, { useCallback, useState } from "react";
import { Card } from "react-bootstrap";
import Messagelogo from "../shared/images/Message-Record-Hub.svg";
import { JONES_RECORD_HUB_URL} from "../env";
const RecordHubCard: React.FC<any> = ({ }) => {  
  const handleClick = () => {
    //window.open('https://app.getjones.com/signin?utm_source=procore', '_blank');
    window.open(JONES_RECORD_HUB_URL, '_blank');
  };
  return (
    <>

      <div className="container container-wrapper p-4 permission-panel">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-9">
            <Card>
              <Card.Body>

                <div className="row">
                  <div className="col-12 col-md-12 d-flex align-items-center justify-content-between" style={{ height: "150px" }}>

                    <img src={Messagelogo} alt="Message Logo" width="50" />

                    <div className="">
                      <p className="card-hub mb-1">You may have more COI requests from other companies</p>
                      <p className="gray-card-note">Log into Jones to see all of your requests and manage your compliance.</p>
                    </div>

                    <div className="d-flex align-items-center">
                      {/* <button className="view-request-button">View Request</button> */}
                      <button className="view-request-button" onClick={handleClick}>View Request</button>
                    </div>
                  </div>
                </div>

              </Card.Body>

            </Card>
          </div>
        </div>
      </div>
    </>
  );
}


export default RecordHubCard;
