// import { Button } from "@procore/core-react";
import React, { useCallback, useEffect, useState } from "react";
import RightSidePanel from "../../components/right-side-panel";
import CopyIcon from "../../shared/copy-icon";
import OpenIcon from "../../shared/open-icon";
import { columns } from "./columns";
import { useLoadItems } from "./hooks";
// import { Plus } from "@procore/core-icons/dist";
import Loader from "../../shared/common/loader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import SidePanelPermission from "./side-panel-permission";
import fileuploadLogo from "../../shared/images/upload-file.png";
import fileViewLogo from "../../shared/images/upload-viewdocs.png";
import plusLogo from "../../shared/images/plus-image.png";
import { Modal } from "react-bootstrap";
import { Button } from "@procore/core-react";
import FileUploader from "devextreme-react/file-uploader";
import { showErrorMessage, showSuccessMessage } from "../../../src/utils";
import { receiptMimes, FileUpload } from "../../models";
import classnames from "classnames";
import ProjectGlobalSection from "./project-global-section";
import Tooltip from 'react-bootstrap/Tooltip';
import { ClipLoader } from "react-spinners";

const SidePanel: React.FC = () => {
  const [selectedRowData, setSelectedRowData] = useState<any>();
  const [isShowFileModal, setisShowFileModal] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = React.useState<any>([]);
  const [fileSizeError, setFileSizeError] = React.useState<string>("");
  const [isDropZoneActive, setIsDropZoneActive] =
    React.useState<boolean>(false);
  const [receipts, setReceipts] = React.useState<FileUpload[]>([]);


  var conrint = "";

  const {
    jonesVendorList,
    loading,
    isGlobalExits,
    jonesGlobalVendor,
    loadingGlobalJonesVendors,
    onLoadCOIFile,
    vendorName,
    onLoadJonesVendorsByProjetId,
    onLoadGlobalJonesVendors,
    loadingProjectDetails,
    isValidating,
    permissionname,
    onUploadCoiFiles,
    isProjectOnboarding,
    projectName,
    isUploading,
    isInitializing,
    initialdata,
    selectedJonesVendorId,
    loadingiFrame,
    setLoadingiFrame,
    userExist
  } = useLoadItems();


  const openCOIFile = useCallback(
    async (data: any) => {
      document.body.click();
      const fileurl = await onLoadCOIFile(data?.foreignid2, data?.id);
      if (fileurl && data?.id) {
        window.open(fileurl);
      }
    },
    [onLoadCOIFile]
  );

  const onDropZoneEnter = React.useCallback((e: any) => {
    if (e.dropZoneElement.id === "dropzone-external") {
      setIsDropZoneActive(true);
    }
  }, []);
  const onDropZoneLeave = React.useCallback((e: any) => {
    if (e.dropZoneElement.id === "dropzone-external") {
      setIsDropZoneActive(false);
    }
  }, []);

  const onDeletefile = useCallback(
    (name: string) => {
      const files = receipts.filter((x) => x.fileName !== name);
      const updatedFiles = selectedFiles.filter(
        (x: any) =>
          x?.file?.name?.toLowerCase().trim() !== name?.toLowerCase().trim()
      );

      setReceipts(files);
      setSelectedFiles(updatedFiles);
    },
    [receipts, selectedFiles]
  );

  const handleAddFile = React.useCallback(
    (invoiceData: any) => {
      let errors: string[] = [];
      let mgs = "";
      let coiFilesTotalSize: number = 0;
      const newFile = invoiceData.slice(-1);

      const isFileHasSameName = selectedFiles.find(
        (x: any) => x.file.name === newFile[0]?.name
      );

      if (isFileHasSameName) {
        showErrorMessage(
          "Uploaded files cannot have the same name.",
          null,
          true,
          true
        );
        return;
      }
      const validFileExtensions = ["jpg", "jpeg", "pdf"];
      const extension = newFile[0].name.split(".")?.pop()?.toLowerCase();
      const isExtensionAllowed = validFileExtensions.find(
        (x) => x === extension
      );
      if (!isExtensionAllowed) {
        mgs = "Invalid file format. Accepted formats are PDF and JPEG.";
        errors.push(mgs);
      }

      const fileSize = Math.round(newFile[0].size / 1024);
      // filesize greater than 25MB
      if (fileSize > 25600) {
        mgs = 'Maximum file size is 25MB.';
        errors.push(mgs);
      }

      for (let index = 0; index < selectedFiles.length; index++) {
        const coiFile = selectedFiles[index];
        coiFilesTotalSize += coiFile.file.size;
      }
      coiFilesTotalSize += newFile[0].size;
      const fileSizeinMB = Math.round(coiFilesTotalSize / 1024);
      // filesize greater than 25MB
      if (fileSizeinMB > 25600 && selectedFiles[0]) {
        setFileSizeError('Maximum size of all files should be less than 25MB.');
      }

      const formatNewFile = newFile.map((file: File) => {
        return {
          file: file,
          error: mgs,
        };
      });

      setSelectedFiles([...selectedFiles, ...formatNewFile]);
    },
    [selectedFiles]
  );

  const popover = (
    <Popover id="popover-basic">
      <div className="btn-group">
        <div className="sidepanel-totip">
          <div className="py-2">
            <div>
              <button
                className="btn btn-light btn-block view-upload-btn"
                style={{ fontSize: "inherit" }}
                disabled={selectedRowData?.complianceStatus === "Pending"}
                onClick={async (event) => {
                  openCOIFile(selectedRowData);
                }}
              >
                {" "}
                <img
                  style={{ marginLeft: "-10px" }}
                  src={fileViewLogo}
                  alt={fileViewLogo}
                  width="18"
                />{" "}
                <span style={{ paddingLeft: "2px" }}>View Docs </span>
              </button>
            </div>
          </div>

          <div className="py-2">
            <button
              className="view-upload-btn btn-light"
              style={{ fontSize: "inherit" }}
              onClick={(event) => {
                document.body.click();
                setisShowFileModal(true);
                setSelectedFiles([]);
              }}
            >
              <img src={plusLogo} alt={plusLogo} width="18" /> {"  "}{" "}
              <span style={{ paddingLeft: "2px" }}>Upload New</span>
            </button>{" "}
          </div>
        </div>
      </div>
    </Popover>
  );

  useEffect(() => {
    columns.forEach((e: any) => {
      if (e.caption === "Actions") {
        e.cellRender = (cellData: any, index: number) => {
          const openJonesReport = () => {
            const foreignid = cellData?.data?.foreignid2;
            const DeepUrl = cellData?.data?.deepUrl;
            const updatedUrl = `${DeepUrl}=${foreignid}&reportTab=report`;
            window.open(updatedUrl, "_blank");
          };

          return (
            <div className="d-flex align-items-center justify-content-center">
              <div className="action-icon">
                {cellData.data.complianceStatus !== "No COI Required" &&
                cellData.data.complianceStatus !== "Deleted on Jones" &&
                cellData.data.complianceStatus !== "Archived" ? (
                  <OverlayTrigger
                    rootClose={true}
                    trigger="click"
                    placement="bottom"
                    overlay={popover}
                  >
                    <button
                      className="border-0 bg-transparent"
                      onClick={(event) => {
                        setSelectedRowData(cellData.data);
                      }}
                    >
                      <CopyIcon />
                    </button>
                  </OverlayTrigger>
                ) : null}
              </div>
              {((!!jonesVendorList.length && jonesVendorList[0].jonesuserexit) || 
              (!!jonesGlobalVendor.length && jonesGlobalVendor[0].jonesuserexit)) && (
                <div className="ml-2 action-icon" onClick={openJonesReport}>
                  <OpenIcon />
                </div>
              )}
            </div>
          );
        };
      }
    });
  }, [openCOIFile, popover, jonesVendorList,jonesGlobalVendor]);

  const upoloadNewCOI = useCallback(async () => {
    await onUploadCoiFiles(
      selectedFiles,
      selectedRowData?.foreignid2,
      selectedRowData?.id
    );
    setLoadingiFrame(true);
    onLoadJonesVendorsByProjetId(selectedJonesVendorId);
    onLoadGlobalJonesVendors(selectedJonesVendorId);
    setisShowFileModal(false);
    return;
  }, [onLoadGlobalJonesVendors, onLoadJonesVendorsByProjetId, onUploadCoiFiles, selectedFiles, selectedJonesVendorId, selectedRowData, setLoadingiFrame]);



  if (isInitializing) {
    return (
      <div className="d-flex align-items-center justify-content-center main-loader">
        <ClipLoader
          color="gray"
          speedMultiplier={1}
          cssOverride={{
            borderWidth: 5,
          }}
        />
        {/* <div className="mt-4">
          <Loader />
          <PulseLoader />
        </div>
        <div style={{ paddingTop: "70px" }}>
          <Loader />
          <PulseLoader />
        </div> */}
      </div>
    );
  }

  if (
    userExist?.userexist &&
    (!userExist?.recordHubExist || userExist.vendorid !== selectedJonesVendorId)
  ) {
    return (
      <SidePanelPermission
        Permissionname={permissionname}
        loading={isValidating}
      />
    );
  }

  if (initialdata) {
    return (
      <div>
        <RightSidePanel
          isOpen={true}
          onClose={() => {}}
          loading={loadingProjectDetails || isInitializing}
        >
          <ProjectGlobalSection 
          intialData={initialdata}
          isProjectOnboarding ={isProjectOnboarding}
          projectName={projectName}
          vendorName={vendorName}
          jonesVendorList={jonesVendorList}
          isValidating={isValidating}
          userExist={userExist}
          loading={loading}
          loadingiFrame={loadingiFrame}
          jonesGlobalVendor={jonesGlobalVendor}
          isGlobalExits={isGlobalExits}
          loadingGlobalJonesVendors={loadingGlobalJonesVendors}
          onLoadIframe={() => { setLoadingiFrame(false);}}
          />
          
        </RightSidePanel>

        {isShowFileModal && (
          <Modal
            show={isShowFileModal}
            keyboard={false}
            animation={false}
            dialogClassName="create-fileupload-side-panel"
            backdrop="static"
            centered
            size="sm"
            onHide={() => {}}
          >
            <Modal.Header style={{ borderBottom: "0 none" }}>
              <div className="d-flex align-items-center">
                <div className="fileuploadhead">
                  Upload Insurance Document(s)
                </div>
                <button
                  className="fileupload-close-btn"
                  onClick={(event) => {
                    setisShowFileModal(false);
                  }}
                >
                  X
                </button>
              </div>
            </Modal.Header>
            <div
              className={classnames(
                "mx-1 ",
                selectedFiles.length < 5 ? "" : " list-view"
              )}
            >
              <div
                className={`col-md-12 ${
                  fileSizeError && fileSizeError.length
                    ? "border rounded border-danger pl-1"
                    : ""
                }`}
              >
                <div className="row">
                  {selectedFiles.map((filename: any, indexKey: number) => {
                    let fileSplit = filename.file.name.split(".");
                    let filename1 = filename.file.name.replace(
                      "." + filename.file.name.split(".")[fileSplit.length - 1],
                      ""
                    );
                    let fileext =
                      filename.file.name.split(".")[fileSplit.length - 1];

                    return (
                      <div className="col-auto mb-1" key={filename.file.name}>
                        <div
                          className={`row no-gutters fileupload-list d-inline-block ${
                            filename.error
                              ? "border rounded border-danger pl-1"
                              : ""
                          }`}
                        >
                          {filename?.file.name.length < 55 ? (
                            <div>
                              <span className="fileupload-list-name">
                                {filename?.file.name}{" "}
                              </span>

                              <Button
                                className="fileupload-close-btn"
                                style={{ height: 16 }}
                                variant="secondary"
                                onClick={(e) => {
                                  onDeletefile(filename?.file.name);
                                  setFileSizeError("");
                                }}
                              >
                                X
                              </Button>
                            </div>
                          ) : (
                            <>
                              <div className="onepdf">
                                <div className="col-md-12 px-0 truncate-text fileupload-list-name">
                                  <OverlayTrigger
                                    key="top"
                                    placement="bottom-start"
                                    overlay={
                                      <Tooltip id={`tooltip-bottom`}>
                                     {filename?.file.name}
                                      </Tooltip>
                                    }
                                    
                                  >
                                    <span>
                                      {filename1.substring(0, 45) + "..."}{" "}
                                    </span>
                                  </OverlayTrigger>
                                </div>
                              </div>
                              <div className="d-flex alighn-items-center fileupload-list-name">
                                {" "}
                                <span>.{fileext}</span>
                                <Button
                                  className="fileupload-close-btn"
                                  style={{ height: 16 }}
                                  size="sm"
                                  variant="secondary"
                                  onClick={() => {
                                    onDeletefile(filename?.file.name);
                                    setFileSizeError("");
                                  }}
                                >
                                  ✕
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                        {filename.error && (
                          <p className="text-danger1 file-list-item">
                            {filename.error}
                            <p className="d-none">
                              {
                                (conrint =
                                  "Error uploading files. Please remove before continuing.")
                              }
                            </p>
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              {fileSizeError ? (
                <div className="row no-gutters mb-1">
                  <span className="text-danger file-list-item">
                    {fileSizeError}
                  </span>
                </div>
              ) : null}
            </div>
            <Modal.Body className="px-0" style={{ height: "165px" }}>
              <div className="grid flex">
                <div className="flex bg-gray-200">
                  <div className="flex flex-1 bg-white m-1 items-center justify-center ">
                    <div
                      className={`flex items-center flex-box ${
                        isDropZoneActive
                          ? "dx-theme-accent-as-border-color dropzone-active"
                          : "dx-theme-border-color"
                      }`}
                    >
                      <div id="dropzone-external">
                        <div id="dropzone-text" className="flex-box h-max">
                          <span>
                            <img
                              style={{ marginLeft: "27px" }}
                              src={fileuploadLogo}
                              alt={fileuploadLogo}
                              width="40"
                            />
                            <br></br>
                            <div style={{ marginTop: "5px" }}>
                              <span
                                style={{ color: "#337ab7", fontSize: "16px" }}
                              >
                                Click{" "}
                              </span>{" "}
                              <span style={{ fontSize: "16px" }}>
                                to Upload
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <FileUploader
                  id="file-uploader"
                  dialogTrigger="#dropzone-external"
                  dropZone="#dropzone-external"
                  accept={receiptMimes}
                  multiple={false}
                  visible={false}
                  showFileList={true}
                  onDropZoneEnter={onDropZoneEnter}
                  onDropZoneLeave={onDropZoneLeave}
                  onValueChange={(e) => {
                    if (e && e.length) {
                      handleAddFile(e);
                    }
                  }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: "0 none" }}>
              <div>
                {/*  {fileSizeError.lenght != 0 ? (<span className="error-show"> {conrint} </span>) : ""} */}
                {conrint != "" ? (
                  <span className="error-show"> {conrint} </span>
                ) : (
                  ""
                )}
              </div>

              <div style={{ marginRight: "4px" }}>
                <button
                  className="add-cancel-btn sidepanel-cancel-btn"
                  onClick={() => {
                    setFileSizeError("");
                    setisShowFileModal(false);
                    setSelectedFiles([]);
                    showSuccessMessage("No docs uploaded", null, true);
                    return;
                  }}
                  style={{ width: "100px" }}
                >
                  {" "}
                  Cancel
                </button>
              </div>
              <div style={{ paddingRight: "inherit" }}>
                {selectedFiles.error}
                <Button
                  disabled={
                    selectedFiles.length === 0 ||
                    conrint !== "" ||
                    fileSizeError !== ""
                  }
                  className="fileupload-btn"
                  onClick={() => {
                    upoloadNewCOI();
                  }}
                >
                  {isUploading ? "Uploading..." : "Done"}
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default SidePanel;

export function PulseLoader() {
  return (
    <div
      className="card skeleton my-4"
      style={{ minHeight: "90px", width: "400px" }}
    >
      <div className="card-body">
        <p className="card-text skeleton"></p>
      </div>
    </div>
  );
}
