import React, { useState, useCallback, useEffect } from "react";
import { InsuranceRequirement, JonesRecord, Project, PermissionOptionModel } from "../../models"
import API from "../../apis";
import fileDownload from 'js-file-download';
import { showErrorMessage, showSuccessMessage } from "../../utils";
import SuccessIcon from "../../shared/SuccessIcon";

export function useLoad() {
  const [jonesVendor, setJonesVendor] = useState<JonesRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloading, setDownLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingJonesVendors, setLoadingJonesVendors] = useState(false);
  const [permissions, setPermissions] = useState<PermissionOptionModel[]>([])
  const [Coifileload, setCoifileload] = useState<boolean>(false);
  const [jonesGlobalVendor, setJonesGlobalVendor] = useState<JonesRecord[]>([]);
  const [loadingGlobalJonesVendors, setLoadingGlobalJonesVendors] = useState(false);
  const [isGlobalexits, setisGlobalexits] = useState(false);
 
  


  const [project, setProject] = useState<Project>({
    id: 0,
    name: "",
    code: "",
    vendorList: [],
    requirementList: [],
    projectRoles: [],
    clientId: 0,
    kriyagoProjectId: 0,
    requirements: [],
    logeduser: "",
    comanyAccName:"",
  });

  const [insurance, setInsurance] = useState<InsuranceRequirement>({
    vendors: [],
    projectId: 0,
    requirementId: null,
    clientId: 0,
    kriyagoProjectId: 0,
    companyId: '0',
    tabId: 0,
  });









  const onLoadCoiFiles = useCallback(
    async (ForeignId2: string, Id: any) => {

      try {

        setLoadingUsers(true);
        const response = await API.get(
          `Procore/GetRecentCoiFile?ForeignId2=${ForeignId2}&Id=${Id}`,
        );
        if (response && response.data) {
          return response.data;
        }
        else { return null; }

      } catch (e) {
        console.log(e);

        setErrorMessage(e.message);
      }
    },
    []
  );


  const onUploadCoiFiles = useCallback(
    async (values, ForeignId2: string, Id: any,objectid:number) => {      
      setCoifileload(true)
      try {
        

        let formData = new FormData();
        for (var i = 0; i < values.length; i++) {
          const element = values[i].file;
          formData.append(`files`, element);
        }
        const response = await API.post(
          `Procore/UploadCoiFile?ForeignId2=${ForeignId2}&Id=${Id}`, formData
        );
        onLoadJonesVendors(objectid);
        onLoadGlobalJonesVendors(objectid);
        setCoifileload(false)
 
        
        if (response.data == true) {     

          showSuccessMessage(
            "Insurance docs have been uploaded",
            <SuccessIcon />
          );
         } else {
          showErrorMessage("File is incorrect");
        }
        return response.data;
      } catch (e) {
        console.log(e);
        setLoadingUsers(false);
        setErrorMessage(e.message);
      }
    },
    []
  );


  const onLoadJonesVendors = useCallback(
    async (objectid: number = 0) => {
      setLoadingJonesVendors(true);
      try {
        const projectId = new URLSearchParams(window.location.search).get("ProjectID");
        let companyId = new URLSearchParams(window.location.search).get("CompanyID");
        if(companyId != null)
        {
        const response = await API.get(`Procore/GetJonesVendors?projectId=${projectId}&CompanyId=${companyId?.toString()}&search=${""}&objectid=${objectid}`);
        setJonesVendor(response.data);
        setLoadingJonesVendors(false);
        return response.data;
        }else{setLoadingJonesVendors(false);}

      } catch (e) {
        setLoadingJonesVendors(false);
      }
    }, []
  )

  const onLoadGlobalisexist = useCallback(
    async (search: string = '') => {
      setLoadingJonesVendors(true);
      try {
        let companyId = new URLSearchParams(window.location.search).get(
          "CompanyID"
        );
        const response = await API.get(`Procore/Globalisexist?CompanyID=${companyId?.toString()}`);
       
        setLoadingJonesVendors(false);
        setisGlobalexits(response.data);

      } catch (e) {
        setLoadingJonesVendors(false);
      }
    }, []
  )

  const onLoadGlobalJonesVendors = useCallback(
    async (objectid: number = 0) => {
      
      setLoadingGlobalJonesVendors(true);
      try {
        const projectId = new URLSearchParams(window.location.search).get("ProjectID");
        let companyId = new URLSearchParams(window.location.search).get("CompanyID");
        if(companyId != null)
        { 
        const response = await API.get(`Procore/GetGlobalJonesVendors?projectId=${projectId}&compnayId=${companyId?.toString()}&search=${""}&objectid=${objectid}`);
        setJonesGlobalVendor(response.data);
        setLoadingGlobalJonesVendors(false);
        return response.data;
        }else{setLoadingGlobalJonesVendors(false);}

      } catch (e) {
        setLoadingGlobalJonesVendors(false);
      }
    }, []
  )



  const onLoadPermissions = React.useCallback(async () => {
    let companyId = new URLSearchParams(window.location.search).get(
      "CompanyID"
    );
    let projectId = new URLSearchParams(window.location.search).get(
      "ProjectID"
    );
    try {
      const response = await API.get(`Procore/GetPermissionTemplates?companyId=${companyId?.toString()}&projectId=${projectId}`);
      if (response.data) {
        setPermissions([
          {
            label: "",
            options: [
              {
                id: 0,
                type: "",
                name: "Apply Permission Template Later",
                projectSpecific: false
              }
            ]
          },
          ...response.data
        ])
      }
    } catch (error) {
      console.log(error);
    }
  }, [])

//   useEffect(() => {
//     // onLoadProject();
//     onLoadJonesVendors();
//     onLoadGlobalJonesVendors();
//     onLoadPermissions();
//   }, [onLoadJonesVendors, onLoadGlobalJonesVendors,onLoadPermissions])

  return {
    project,
    insurance,
    loading,
    setInsurance,
    errorMessage,
    jonesVendor,
    jonesGlobalVendor,
    onLoadJonesVendors,
    onLoadGlobalJonesVendors,
    downloading,   
    loadingUsers,
    setLoadingUsers,   
    loadingJonesVendors,
    loadingGlobalJonesVendors,
    permissions,
    onLoadCoiFiles,
    onUploadCoiFiles,
    Coifileload,
    onLoadGlobalisexist,
    isGlobalexits,

  }
}


