import React, { memo } from "react";
import CompanyLogo from "../../shared/CompanyLogo";

interface INotLinkProjectProps {
  projectName: string;
}

const NotLinkProject = ({ projectName }: INotLinkProjectProps) => {
  return (
    <div className="container container-wrapper p-4 ">
      <MainTemplate height={150}>
        <div className="main-create-project text-center">
          No Project for {projectName} configured in Jones
        </div>

        <div
          className="main-create-project-p text-center"
          style={{ marginTop: "10px", lineHeight: "19.5px" }}
        >
          After creating the project on the Jones app, you can easily invite
          vendors to review your insurance requirements and upload their COIs.
        </div>
      </MainTemplate>
    </div>
  );
};

export default memo(NotLinkProject);


 export const MainTemplate = ({ children, height  }: any) => {
  return (
    <div className="row ">
        <div style={{ padding: "0.6rem" }}>
          <div className="d-flex justify-content-center">
            <CompanyLogo height={height}/>
          </div>
          {children}
        </div>
    
    </div>
  );
}