import * as React from "react"

function CompliantIcon(props: any) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path fill="#00BB4B" fillRule="evenodd" d="M11.25 17.75l-5-3.75 1.5-2 3 2.25 5.25-7 2 1.5-6.75 9zM12 2C6.477 2 2 6.478 2 12s4.477 10 10 10 10-4.478 10-10S17.523 2 12 2z" />
        </svg>

    )
}

export default CompliantIcon;
