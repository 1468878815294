import * as React from "react"

function ExpiredIcon(props: any) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path fill="#FF6565" fillRule="evenodd" d="M12.707 15.293l-1.414 1.414L12 13.414l-3.293 3.293-1.414-1.414L10.586 12 7.293 8.707l1.414-1.414L12 10.586l3.293-3.293 1.414 1.414L13.414 12l3.293 3.293zM12 2C6.478 2 2 6.478 2 12s4.478 10 10 10c5.523 0 10-4.478 10-10S17.523 2 12 2z" />
        </svg>


    )
}

export default ExpiredIcon;
