import React, { useCallback, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Table, FormControl } from "react-bootstrap";
import Alertlogo from "../shared/images/Alert.svg";
import {
  InsuranceRequirement,
  ProjectUser,
  PermissionTemplate,
  Vendor,
  CoiFile,
  JonesRecord,
} from "../models";
interface ICreateCOI {
  isModelOpen: boolean;
  showCOIModal: boolean;
  allVendors: JonesRecord[];
  onClose: () => void;
  setIsCoiShowModal?: (value: boolean) => void;

}

function FailedUploadCoiPopup(props: ICreateCOI) {


  const { onClose,
    isModelOpen,
    allVendors,
    showCOIModal,
    setIsCoiShowModal,
  } = props;


  return (
    <>

      <Modal
        show={isModelOpen}
        keyboard={false}
        animation={false}
        backdrop="static"
        centered
        onHide={() => { }}
        dialogClassName={
          "coi-failed-popup"
        }
      >
        <Modal.Body>
        <div className="d-flex justify-content-end">
            <button className="Coi-close-btn"
              onClick={(event) => {
                onClose();

                sessionStorage.removeItem("FailedCoiResponse");
              }}
            >
              X
            </button>
            </div>

          

          <div className="">
            <p className="mt-3">

            </p>
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 text-center">
              <img src={Alertlogo} alt="Message Logo" width="50" />
              <h4 className="my-3">COI upload failed for:</h4>

               <Table className="table table-bordered table-hover table-sm mt-2">
                  <tbody>
                    {allVendors.map((vendor, index) => (
                      <tr key={index}>
                        <td className="align-middle" width={30}>
                          <img
                            src="/images/Procore_Company_img.PNG"
                            alt="Procore company"
                            width="30"
                            height="25"
                          />
                        </td>
                        <td className="align-middle text-left"style={{ fontSize: '14px' }}>{vendor.companyName}</td>
                      </tr>
                    ))}
  
                  </tbody>
                </Table>
                <p className="text-coi"> Jones scans every file for potential threats. This file you received may contain a virus or malware.</p>
                <p className="text-coi"> Please upload a different file or <a href="mailto:integration@getjones.com">contact Jones support team</a>.
</p>
              </div>
            </div>
          </div>

        </Modal.Body>


      </Modal>
    </>
  );

}


export default FailedUploadCoiPopup;
