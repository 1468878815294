import React, { useState } from "react";
import { BarLoader } from "react-spinners";
import classNames from "classnames";
import { PulseLoader } from "..";
import DataTable from "../../../components/DataTable";
import { JonesRecord } from "../../../models";
import SettingIcon from "../../../shared/setting-icon";
import Banner from "../banner";
import { columns } from "../columns";
import NotLinkProject from "../notlink-project";
import ProjectOnboarding from "../project-onboarding";

interface IProjectGlobalSectionProps {
  intialData: any;
  isProjectOnboarding: boolean;
  projectName: string;
  vendorName: string;
  jonesVendorList: JonesRecord[];
  isValidating: boolean;
  userExist: any;
  loading: boolean;
  loadingiFrame: boolean;
  jonesGlobalVendor: JonesRecord[];
  isGlobalExits: boolean;
  loadingGlobalJonesVendors: boolean;
  onLoadIframe: () => void;
}

const ProjectGlobalSection = ({
  intialData,
  isProjectOnboarding,
  projectName,
  vendorName,
  jonesVendorList,
  isValidating,
  userExist,
  loading,
  loadingiFrame,
  jonesGlobalVendor,
  isGlobalExits,
  loadingGlobalJonesVendors,
  onLoadIframe,
}: IProjectGlobalSectionProps) => {
  const [showGlobalIframeLoader, setShowGlobalIframeLoader] =
    useState<boolean>(true);

  React.useEffect(() => {
    if (loadingiFrame) {
      setShowGlobalIframeLoader(true);
    }
  }, [loadingiFrame]);

  if (!intialData.kriyagoProjectId) {
    return <NotLinkProject projectName={projectName} />;
  } else if (isProjectOnboarding) {
    return <ProjectOnboarding projectName={projectName} />;
  } else {
    return (
      <>
        <div
          className="panel-header d-flex justify-content-between align-items-center mb-2"
          style={{ borderTop: "1px solid #ddd" }}
        >
          <div className="d-flex justify-content-start align-items-center sidepanel-x-padding">
            <SettingIcon />
            <span className="font-weight-bold pr-1">Project level - </span>{" "}
            {vendorName}
          </div>
        </div>

        <div
          className={classNames(
            "d-flex flex-column ",
            jonesVendorList?.length
              ? "align-items-start"
              : "align-items-center"
          )}
          style={{ minHeight: "70vh" }}
        >
          <>
            {jonesVendorList?.length ? (
              <div className="d-flex flex-column">
                {isValidating ? (
                  <PulseLoader />
                ) : (
                  <>
                    {userExist?.userexist && userExist?.recordHubExist && (
                      <Banner />
                    )}

                    <div className="sidepanel-x-padding">
                      <DataTable
                        id="gridContainer"
                        dataSource={jonesVendorList}
                        keyExpr="id"
                        columns={columns}
                        loading={loading}
                        pageSize={5}
                      />
                    </div>

                    {jonesVendorList[0].complainceReportLink && (
                      <div
                        className="my-2 d-flex flex-column justify-content-start align-items-center"
                        style={{
                          height: !!jonesGlobalVendor.length ? "55vh" : "58vh",
                        }}
                      >
                        {loadingiFrame ? (
                          <BarLoader color="#ddd" width={375} />
                        ) : (
                          <></>
                        )}

                        <iframe
                          src={jonesVendorList[0].complainceReportLink}
                          title="description"
                          style={{
                            width: "375px",
                            borderTop: "1px solid #ddd",
                            borderLeft: "1px solid #ddd",
                            flex: 1,
                          }}
                          loading="lazy"
                          className={loadingiFrame ? "skeleton" : ""}
                          onLoad={() => {
                            onLoadIframe();
                          }}
                          height={"auto"}
                        ></iframe>
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className="d-flex justify-content-center flex-column align-items-center sidepanel-x-padding">
                {userExist?.userexist && userExist?.recordHubExist && (
                  <div className="mt-2">
                    <Banner />
                  </div>
                )}
                <div
                  className="d-flex mb-2 align-items-center font-weight-normal"
                  style={{ minHeight: "80px", color: "#363A43" }}
                >
                  No COI requests initiated
                </div>
              </div>
            )}
          </>
        </div>

        <div className="panel-footer text-center mt-1">
          <>
            {isGlobalExits && !!jonesGlobalVendor.length && (
              <>
                <div
                  className="d-flex justify-content-start align-items-center panel-header my-1 sidepanel-x-padding"
                  style={{ borderTop: "1px solid #ddd" }}
                >
                  <SettingIcon />
                  <span className="font-weight-bold  pr-1">
                    Company level -{" "}
                  </span>{" "}
                  {vendorName}
                </div>

                {jonesGlobalVendor.length ? (
                  <div className="d-flex flex-column mt-2">
                    <div className="sidepanel-x-padding">
                      <DataTable
                        id="gridContainer"
                        dataSource={jonesGlobalVendor}
                        keyExpr="id"
                        columns={columns}
                        loading={loadingGlobalJonesVendors}
                        pageSize={5}
                      />
                    </div>

                    {jonesGlobalVendor[0].complainceReportLink && (
                      <div className="my-2 d-flex flex-column justify-content-start align-items-center">
                        {showGlobalIframeLoader ? (
                          <BarLoader color="#ddd" width={375} />
                        ) : (
                          <></>
                        )}
                        <iframe
                          src={jonesGlobalVendor[0].complainceReportLink}
                          title="description"
                          className={showGlobalIframeLoader ? "skeleton" : ""}
                          style={{
                            width: "375px",
                            borderTop: "1px solid #ddd",
                            borderLeft: "1px solid #ddd",
                            height: "450px",
                          }}
                          onLoad={() => {
                            setShowGlobalIframeLoader(false);
                          }}
                        ></iframe>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-center flex-column align-items-center h-auto sidepanel-x-padding"
                    style={{ minHeight: "100px" }}
                  >
                    <div
                      className="mt-2 font-weight-normal"
                      style={{ color: "#363A43" }}
                    >
                      No COI requests initiated
                    </div>
                  </div>
                )}
              </>
            )}
          </>

          <div style={{ padding: "5px 10px" }}>
            <div className="font-weight-bold" style={{ color: "#8995AD" }}>
              Powered by JONES{" "}
            </div>
            <div className="text-sm">
              <a
                rel="noopener noreferrer"
                href="http://getjones.com"
                target="_blank"
                style={{ color: "#0073FF" }}
              >
                www.getjones.com
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default ProjectGlobalSection;
