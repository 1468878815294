import * as React from "react";
function OpenIcon(props: any) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0982 15.8333H3.97352V4.16667H9.53588V2.5H3.97352C3.09149 2.5 2.38428 3.25 2.38428 4.16667V15.8333C2.38428 16.75 3.09149 17.5 3.97352 17.5H15.0982C15.9723 17.5 16.6875 16.75 16.6875 15.8333V10H15.0982V15.8333ZM11.1251 2.5V4.16667H13.9778L6.16668 12.3583L7.2871 13.5333L15.0982 5.34167V8.33333H16.6875V2.5H11.1251Z"
        fill="#0073FF"
      />
    </svg>
  );
}

export default OpenIcon;
