import * as React from "react";
import { Overlay } from "react-bootstrap";

function DeleteIcon(props: { onClick: any }) {
  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);
  return (
    <div
      onClick={() => {
        props.onClick();
      }}
      ref={target}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        version="1.1"
        viewBox="0 0 32 32"
      >
        <g transform="scale(2)">
          <circle
            style={{
              fill: "#f44336",
            }}
            cx="8"
            cy="8"
            r="7"
          />
          <rect
            style={{
              fill: "#ffffff",
            }}
            width="2"
            height="10"
            x="-.98"
            y="-16.29"
            transform="rotate(135)"
          />
          <rect
            style={{
              fill: "#ffffff",
            }}
            width="2"
            height="10"
            x="-12.29"
            y="-5.01"
            transform="rotate(-135)"
          />
        </g>
      </svg>

      <Overlay target={target.current} show={show} placement="top">
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              backgroundColor: "rgb(19 18 18)",
              padding: "2px 5px",
              color: "white",
              borderRadius: 3,
              zIndex: 10034,
              ...props.style,
            }}
          >
            Remove to ‘skip’ the request
          </div>
        )}
      </Overlay>
    </div>
  );
}

export default DeleteIcon;
