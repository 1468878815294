import * as React from 'react';
import {
  DataGrid,
  IDataGridOptions,
  Column,
  Selection,
  ISelectionProps,
  Pager,
  Paging,
  Editing,
  Lookup,
  CustomRule,
  Export,
} from 'devextreme-react/data-grid';
// @ts-ignore
import saveAs from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import LookupBox from './LookupBox';

export interface DataTableProps extends IDataGridOptions, ISelectionProps {
  selectedRowKeys?: number[];
  isEditMode?: boolean;
  loading?: boolean;
  enableExport?: boolean;
  exportFileName?: string;
  pageSize?: number;
}

const DataTable: React.FC<DataTableProps> = ({
  columns,
  isEditMode,
  loading,
  pageSize,
  ...props
}) => {
  //const [rowHeight, setRowHeight] = React.useState<number>(0);
  const dataGrid = React.useRef<any>(null);
  const [infoText, setInfoText] =  React.useState<string>(``)

 React.useEffect(() => {
   const data = props.dataSource as any[];

   if (data && data.length) {
     setInfoText(`Displaying  1 - ${(pageSize ?? 15) * 1} of ${data.length}`);
   }
 }, [props.dataSource, setInfoText, pageSize]);

  const performLongOperation = React.useCallback((loading: boolean) => {
    if (dataGrid?.current?.instance) {
      if (loading) {
        dataGrid.current.instance.beginCustomLoading();
      } else {
        dataGrid.current.instance.endCustomLoading();
      }
    }
  }, []);

  React.useEffect(() => {
    if (loading !== undefined) {
      performLongOperation(loading);
    }
  }, [loading, performLongOperation]);

  const onExportExcel = (e: any) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${props.exportFileName}.xlsx`
        );
      });
    });
    // e.cancel = true;
  };
  return (
    <DataGrid
      ref={dataGrid}
      dataSource={props.dataSource}
      {...props}
      showRowLines={true}
      showColumnLines={true}
      showBorders={true}
      height="auto"
      onExporting={onExportExcel}
    >
      {columns?.map((column: any, idx) => {
        if (column?.type === 'action' && column?.cellRender) {
          return (
            <Column
              dataField={column.dataField}
              key={idx}
              caption={column.caption}
              cssClass={column.cssClass}
              cellRender={(cellData: any) => column.cellRender(cellData)}
            />
          );
        } else if (column.hasLookup) {
          return (
            <Column
              {...column}
              allowEditing={column.allowEditing}
              key={idx}
              editCellRender={(cell) => {
                if (column.hasLookup) {
                  return <LookupBox {...cell} />;
                }
              }}
            >
              {isEditMode && column.validation && (
                <CustomRule {...column.validation} />
              )}
              {column.hasLookup && (
                <Lookup
                  dataSource={column.lookupDataSource}
                  valueExpr="value"
                  displayExpr="text"
                  allowClearing={true}
                />
              )}
            </Column>
          );
        }
        return (
          <Column
            {...column}
            key={idx}
          >
            {isEditMode && column.validation && (
              <CustomRule {...column.validation} />
            )}
            {column.hasLookup && (
              <Lookup
                dataSource={column.lookupDataSource}
                valueExpr="value"
                displayExpr="text"
                allowClearing={true}
              />
            )}
          </Column>
        );
      })}
      {isEditMode && <Editing mode="cell" allowUpdating={true}  />}
      <Export
        enabled={props.enableExport}
        fileName={props.exportFileName ?? 'Expense Management'}
      />
      <Selection {...props} />
      <Paging defaultPageSize={pageSize ?? 15} onPageIndexChange={(index) => {
          let data = props.dataSource as any[];
          const startItemNumber  =  ((index * (pageSize ?? 15))  +1 );
          let endItemNumber =  (index +1 ) * (pageSize ?? 15);
          endItemNumber = endItemNumber > data.length ? data.length : endItemNumber;
          const message = `Displaying  ${startItemNumber} - ${endItemNumber} of ${data.length}`;
          setInfoText(message);
      }} />
      <Pager showInfo={true} showNavigationButtons infoText={infoText}/>
    </DataGrid>
  );
};

export default DataTable;
