import React, { useCallback, useEffect } from "react";
import { Card } from "react-bootstrap";
import procoreLogo from "../../shared/images/procoreLogo.svg";
import cOIIcon from "../../shared/images/COIIcon.png";
import jonesLogo from "../../shared/images/JonesLogo.png";
import { useAuthenticate } from "../../hooks";
import { useLocation, useHistory } from "react-router-dom";
import decode from 'jwt-decode';
import moment from 'moment';

function Login() {
  const { onAuth } = useAuthenticate();
  const location = useLocation();
  const data: any = location.state;
  const history = useHistory();

  const ReAuthenticate = useCallback(async () => {
    await onAuth(data);
  }, [onAuth, data]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      const tokenData: any = decode(token);
      const isTokenValid = moment.unix(tokenData.exp).isSameOrAfter(new Date());
      if (isTokenValid && data) {
        if (data?.navigateURL) {
          history.push(
            `/${data?.navigateURL}?ProjectID=${data?.projectId}&CompanyID=${data?.companyId}`
          );
          return;
        }
        history.push(
          `/?ProjectID=${data.projectId}&CompanyID=${data.companyId}`
        );
        return;
      }
    }
  }, [data, history]);

  return (
     data?.isSidePanel === true ?
      (<div className="container container-wrapper p-4">
        <div className="row">
          <div
            className="col-sm-12"
          >
            <Card
              style={{
                width: 360,
                height: 300,
                margin: "0 auto",
                boxShadow: "0px 1.77812px 3.55625px rgb(0 0 0 / 11%)",
              }}
            >
              <Card.Body className="p-0">
                <Card.Title
                  style={{
                    backgroundColor: "#45484A",
                    color: "#fff",
                    fontSize: 14,
                    height: 40,
                    paddingTop: "12px",
                    paddingRight: "24px",
                    paddingBottom: "12px",
                    paddingLeft: "24px",
                    borderRadius: "4px 4px 0px 0px",
                  }}
                ></Card.Title>
                <div
                  className="panel-body"
                  style={{ padding: "0.5rem" }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      paddingLeft: "0px"
                    }}
                  >
                    <img src={procoreLogo} alt="Procore-Logo"   width="150px"
                     height="36px"/>
                    <img
                      src={cOIIcon}
                      alt="COI Icon"
                      className="pl-1"
                      width= "70px"
                      height="50px"
                    />
                    <img
                      src={jonesLogo}
                      alt="Jones-Logo"
                      className="pl-1"
                      height="18px" 
                      width="80px" 
                    />
                  </div>
                  <div className="login-body-content text-center" style={{marginTop:'8px'}}>
                    Please log in with your Procore account to access the Jones
                    App.
                  </div>
                  <div className="text-center">
                    <button className="btn btn-login btn-login-sidepanel" onClick={ReAuthenticate}>
                      Login
                    </button>
                  </div>
                  <div
                    className="login-p-content text-center"
                    style={{ marginTop: "20px", lineHeight: "19.5px" }}
                  >
                    If you don’t see a popup after clicking “Login”, please
                  </div>
                  <p className="login-p-content text-center">
                    check your popup blocker and try again.
                  </p>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>):
      <div className="container container-wrapper p-4">
      <div className="row">
          <div className="col-sm-12  ">
              <Card
                  style={{
                      width: 620,
                      height: 380,
                      margin: "0 auto",
                      boxShadow:
                          "0px 1.77812px 3.55625px rgb(0 0 0 / 11%)",
                  }}
              >
                  <Card.Body className="p-0">
                      <Card.Title
                          style={{
                              backgroundColor: "#45484A",
                              color: "#fff",
                              fontSize: 14,
                              height: 52,
                              paddingTop: "12px",
                              paddingRight: "24px",
                              paddingBottom: "12px",
                              paddingLeft: "24px",
                              borderRadius: "4px 4px 0px 0px",
                          }}
                      >
                      </Card.Title>
                      <div className="panel-body" style={{ padding: "1.6rem" }}>
                          <div className="d-flex  align-items-center" style={{ paddingLeft: "50px", marginTop: "10px" }}>
                              <img src={procoreLogo} alt="Procore-Logo" />
                              <img src={cOIIcon} alt="COI Icon" className="pl-4" />
                              <img src={jonesLogo} alt="Jones-Logo" className="pl-4" height="22px" />
                          </div>
                          <div className="login-body-content text-center" style={{marginTop:'20px'}}>
                              Please log in with your Procore account to access the Jones App.
                          </div>
                          <div className="text-center">
                              <button className="btn btn-login" onClick={ReAuthenticate}>
                                  Login
                              </button>
                          </div>
                          <div className="login-p-content text-center" style={{ marginTop: "30px", lineHeight: "19.5px" }}>
                              If you don’t see a popup after clicking “Login”, please
                          </div>
                          <p className="login-p-content text-center">
                              check your popup blocker and try again.
                          </p>
                      </div>
                  </Card.Body>
              </Card>
          </div>
      </div>

  </div>
  );
}

export default Login;
