import * as React from "react";
import { Overlay } from "react-bootstrap";

function UndoIcon(props: { onClick: any }) {
  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);

  return (
    <div
      onClick={() => {
        props.onClick();
      }}
      ref={target}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 4V0L3 5L8 10V6C11.31 6 14 8.69 14 12C14 15.31 11.31 18 8 18C4.69 18 2 15.31 2 12H0C0 16.42 3.58 20 8 20C12.42 20 16 16.42 16 12C16 7.58 12.42 4 8 4Z"
          fill="#0073FF"
        />
      </svg>

      <Overlay target={target.current} show={show} placement="top">
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              backgroundColor: "rgb(19 18 18)",
              padding: "2px 5px",
              color: "white",
              borderRadius: 3,
              zIndex: 10034,
              ...props.style,
            }}
          >
            Revert
          </div>
        )}
      </Overlay>
    </div>
  );
}

export default UndoIcon;
