import React, { useCallback, useEffect, useState } from "react";
import CreateProject from "./create-project";
import { LoadMapProject } from "./hooks";
import CompanyLogo from "./../../shared/CompanyLogo";
import { AssignProject, CompanyUser } from "../../models";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ProcorePermission from "./../../../src/components/ProcorePermission";

function MapProject() {
  const {
    isAddProject,
    setAddProject,
    onLoadCompanysUsers,
    usersLoading,
    clientid,
    office,
    onValidateProject
  } = LoadMapProject();

  const [procoreProject, setprocoreProject] = useState<AssignProject>({
    id: 0,
    projectName: "",
    requirementList: [],
    selectedRequirementList: [],
    additionalInsured: "",
    certificateHolder: "",
    defaultSelectedUsers: [],
    companyUsers: [],
  });

  const location: any = useLocation();

  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isProjectOnboarding, setIsProjectOnboarding] =
    useState<boolean>(false);
  const [isUserExist, setIsUserExist] = useState<boolean>(true);
  const [isPermission, setIsPermission] = useState<string>("");


  const LoadCreateProject = useCallback(async () => {
    const isProjectValid = await onValidateProject();
    if (!isProjectValid) {
      return;
    }

    const companyUsers: CompanyUser[] = await onLoadCompanysUsers();
    if (companyUsers && companyUsers.length) {
      const defaultSelected = companyUsers.filter((x) => x.isDefaultSelected);
      setprocoreProject({
        ...procoreProject,
        companyUsers: [
          {
            id: 0,
            address: "",
            avatar: "",
            employeeId: "",
            emailAddress: "",
            firstName: "Select",
            lastName: "All",
            isActive: true,
            isEmployee: true,
            isDefaultSelected: false,
            companyPermissionTemplate: null,
            fullName: "",
            isInvitedToJones: false,
            isAdminUser: false,
          },
          ...companyUsers,
        ],
        defaultSelectedUsers: defaultSelected ?? [],
      });
    }
    setAddProject(true);
  }, [setAddProject, onLoadCompanysUsers, setprocoreProject, procoreProject, onValidateProject]);

  useEffect(() => {
    const data = {
      ...location.state.detail,
      requirementList: [
        {
          id: 0,
          code: "",
          description: "Select All",
          foreignId: "",
          label: "",
          requestSent: false,
          sourceRequirementId: 0,
          tabId: 0,
        },
        ...location.state.detail.requirementList,
      ],
    };
    setIsProjectOnboarding(data?.isProjectOnboarding);
    setIsUserExist(data?.isuserExist);
    setIsPermission(data?.Permissionname);
    
    setprocoreProject(data);
  }, [location]);


  return (
    <>

      {isUserExist ? (<div>
        <ProcorePermission Permissionname={isPermission} />
      </div>) :
        (
          <>

            {isAddProject && (
              <CreateProject procoreProject={procoreProject}></CreateProject>
            )}

            {isProjectOnboarding && (
              <div className="container container-wrapper p-4">
                <MainTemplate>
                  <div className="pt-3 main-create-project text-center">
                    The project for {procoreProject ? procoreProject.projectName : ""}{" "}
                    is in the onboarding phase in Jones.
                  </div>

                  <div
                    className="main-create-project-p text-center"
                    style={{ marginTop: "10px", lineHeight: "19.5px" }}
                  >
                    Once the project is complete, you can invite vendors to review
                    your
                  </div>
                  <p className="main-create-project-p text-center">
                    insurance requirements and upload their COIs.
                  </p>
                </MainTemplate>
              </div>
            )}

            {!isAddProject && !isProjectOnboarding && (
              <div className="container container-wrapper p-4">
                <MainTemplate>
                  <div className="pt-3 main-create-project text-center">
                    No Project for {procoreProject ? procoreProject.projectName : ""}{" "}
                    configured in Jones
                  </div>

                  <div
                    className="main-create-project-p text-center"
                    style={{ marginTop: "10px", lineHeight: "19.5px" }}
                  >
                    Once the project is created, you can invite vendors to review your
                  </div>
                  <p className="main-create-project-p text-center">
                    insurance requirements and upload their COIs.
                  </p>
                  <div className="pt-2 text-center">
                    <button
                      className="main-add-project-btn btn"
                      disabled={usersLoading}
                      onClick={() => {
                        if (clientid === 0) {
                          setIsShowModal(true);
                          return;
                        }
                        LoadCreateProject();
                      }}
                    >
                      {usersLoading
                        ? "Loading Initial Data"
                        : "Create Project via Jones"}
                    </button>
                  </div>
                </MainTemplate>

                {isShowModal && (
                  <Modal
                    show={isShowModal}
                    keyboard={false}
                    animation={false}
                    dialogClassName="Office-request"
                    backdrop="static"
                    centered
                    onHide={() => { }}
                  >
                    <Modal.Body className="p-0" style={{ height: "250px" }}>
                      <div
                        style={{
                          backgroundColor: "#45484A",
                          color: "#fff",
                          fontSize: 14,
                          paddingTop: "12px",
                          paddingRight: "24px",
                          paddingBottom: "12px",
                          paddingLeft: "24px",
                          borderRadius: "4px 4px 0px 0px",
                        }}
                      >
                        Failed to Create Project via Jones
                      </div>

                      <div style={{ lineHeight: "0.9rem" }}>
                        <div style={{ padding: "1.2rem", marginBottom: "-25px" }}>
                          This project is listed under the<b> {office.name}</b>{" "}
                          office. <b> {office.name}</b> is not yet configured to an
                          account in Jones.
                        </div>
                        <div style={{ padding: "1.2rem" }}>
                          {" "}
                          Once the office is configured, you can initiate the project
                          and it will be created in the right account in Jones.
                        </div>
                      </div>
                      <div className="office-italic">
                        If you have questions or need assistance, feel free to reach
                        out -{" "}
                        <a href="mailto:integration@getjones.com">
                          {" "}
                          integration@getjones.com{" "}
                        </a>{" "}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div style={{ paddingRight: "inherit" }}>
                        <button
                          className="add-office-btn"
                          onClick={() => {
                            setIsShowModal(false);
                            return;
                          }}
                        >
                          {" "}
                          Got it
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                )}
              </div>
            )}
          </>
        )}



    </>

  );
}


export default MapProject;

export const MainTemplate = ({ children, height  }: any) => {
  return (
    <div className="row ">
      <div className="col-md-12">
        <div style={{ padding: "1.6rem" }}>
          <div className="d-flex justify-content-center">
            <CompanyLogo height={height}/>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
