import React from "react";
import { useCallback, useState } from "react";
import API from "../../../apis";
import { ResultModel } from "../../../models";
import SuccessIcon from "../../../shared/SuccessIcon";
import { showErrorMessage, showSuccessMessage } from "../../../utils";
import { useHistory } from 'react-router-dom';

export function AddProject() {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const history = useHistory();
  const [permissionName, setPermissionName] = useState<string>("");

  const submit = useCallback(
    async (values) => {
      try {
        setSubmitting(true);
        const companyId = values ? values.companyId?.toString() : '0';
        const projectId = values ? values.id?.toString() : '0';
        
        const response = await API.post("Procore/CreateProjectInJones", values);
        const result: ResultModel = response ? response.data : null;
        setSubmitting(false);

        if (result && !result.isSuccess) {
          showErrorMessage(result.error);
        }

        if (result && result.isSuccess) {
          showSuccessMessage(
            " Success! Project Created via Jones!",
            <SuccessIcon />
          );
          history.push(`/?ProjectID=${projectId}&CompanyID=${companyId}`);
          if(result.failedUsers && result.failedUsers.length){
            for (const failedUser of result.failedUsers) {
              showErrorMessage(`Failed to add ${failedUser.firstName} ${failedUser.lastName} (${failedUser.emailAddress})`, null, false);
            }
          }
          return;
        } else {
          showErrorMessage(result.error);
        }
      } catch (e) {
        setSubmitting(false);
        showErrorMessage(e?.response?.data);
      }
    },
    [setSubmitting, history]
  );

  const onLoadPermision = useCallback(async () => {
    try {
      let companyId = sessionStorage.getItem("companyId");
      const response = await API.get(
        `Procore/PermissionName?companyId=${companyId?.toString()}`
      );
      setPermissionName(response.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  React.useEffect(() => {
    onLoadPermision();
  }, [onLoadPermision]);

  return {
    submitting,
    submit,
    permissionName,
  };
}