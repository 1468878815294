import React, { useState, useCallback } from "react";
import API from "../../apis";
import {  CompanyUser, JonesUserInviteModel } from "../../models";
import { showErrorMessage, showSuccessMessage } from "../../utils";


export function useLoad() {


  const [ projectUsers, setProjectUsers] = useState<CompanyUser[]>([]);
  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const [permissionName, setPermissionName] = useState<string>('');
  const [invitingId, setInvitingId] =  useState<any>(null);
  const [notificationActiveIds, setNotificationActiveIds] = useState<any>({});




  const onLoadProjectUsers = useCallback(
    async (search: string = '') => {
      setUsersLoading(true);
      try {
        let projectId = new URLSearchParams(window.location.search).get(
            "ProjectID"
          );
          let mode = new URLSearchParams(window.location.search).get(
            "mode"
          );
        let companyId = sessionStorage.getItem('companyId');
        const response = await API.get(
          `Procore/ProjectUsers?companyId=${companyId?.toString()}&projectId=${projectId?.toString()}&mode=${mode}&search=${search}`
        );
        let items: any = {};
      response.data.map((item: any) => {
        items[item.id] = item.isNotifictionActive;
      });
      setNotificationActiveIds(items);
        setUsersLoading(false);
        setProjectUsers(response.data)
        return response.data;
      } catch (e) {
        console.log(e);
        setUsersLoading(false);
      }
    },
    []
  );

 

  const OnLoadSetEmailNotification = useCallback(
    async (optiontype:string,switchValue:boolean,userid:string) => {
          try {
            let projectId = new URLSearchParams(window.location.search).get(
              "ProjectID"
            );
              let companyId = sessionStorage.getItem('companyId');
              let mode = new URLSearchParams(window.location.search).get(
                "mode"
              );
        const response = await API.get(
            `Procore/SetEmailNotification?projectId=${projectId?.toString()}&companyId=${companyId?.toString()}&mode=${mode}&optiontype=${optiontype}&switchValue=${switchValue}&userid=${userid}`
           
            );
            if(!response.data?.failedToAdd){
              showSuccessMessage(response.data);
              onLoadProjectUsers();
              setInvitingId('');
            }
            else {
              const error = response.data?.errorMessage ?? response.data;
              showErrorMessage(error);
              setInvitingId('');
            }
        //setPermissionName(response.data);
      } catch (e) {
        console.log(e);
      }
    },
    []
  );


  const onLoadPermision = useCallback(
    async () => {
          try {
              let companyId = sessionStorage.getItem('companyId');
        const response = await API.get(
            `Procore/PermissionName?companyId=${companyId?.toString()}`
        );
        setPermissionName(response.data);
      } catch (e) {
        console.log(e);
      }
    },
    []
  );

  const onInviteUser = useCallback(
    async (data : JonesUserInviteModel) => {
      try {
        const response = await API.post(
          `Procore/InviteUserToJones`,
          data
        );
        if(!response.data?.failedToAdd){
          showSuccessMessage('Invited user to jones successfully');
          onLoadProjectUsers();
          setInvitingId('');
        }
        else {
          const error = response.data?.errorMessage ?? 'Failed to invite user to jones';
          showErrorMessage(error);
          setInvitingId('');
        }
      } catch (e) {
        console.log(e);
        showErrorMessage(e?.response?.data?.message);
        setInvitingId('');
      }
    },
    [onLoadProjectUsers]
  );

  React.useEffect(() => {
    onLoadProjectUsers();
    onLoadPermision();
  },[onLoadProjectUsers,
    onLoadPermision])

  return {
    projectUsers,
    usersLoading,
    permissionName,
    onLoadProjectUsers,
    onInviteUser,
    setInvitingId,
    invitingId,
    OnLoadSetEmailNotification,    
    notificationActiveIds,
    setNotificationActiveIds,
    
  };
}
