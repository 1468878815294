import React from 'react';
function DeleteCommitIcon(props: any) {
    return (
        <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.82418 7.97578L7.97578 8.82418L5.99998 6.84838L4.02418 8.82418L3.17578 7.97578L5.15158 5.99998L3.17578 4.02418L4.02418 3.17578L5.99998 5.15158L7.97578 3.17578L8.82418 4.02418L6.84838 5.99998L8.82418 7.97578ZM6 0C2.6868 0 0 2.6868 0 6C0 9.3132 2.6868 12 6 12C9.3138 12 12 9.3132 12 6C12 2.6868 9.3138 0 6 0V0Z" fill="#CF373D"/>
        </svg>
        


    )
}

export default DeleteCommitIcon;



