import React from "react";
import { Button } from "@procore/core-react";
import { Typeahead } from "@procore/core-react";
import debounce from "lodash/debounce";
import { JonesUserInviteModel } from "../../models";
import { useLoad } from "./hooks";
import NoResults from "../../shared/NoResults";
import Switch from "devextreme-react/switch";
import ModalComponent from "./Component/UserModelPopup";

import { DataGrid, Column, Pager, Paging } from "devextreme-react/data-grid";

function UserManagement() {
  const {
    projectUsers,
    usersLoading,
    permissionName,
    onLoadProjectUsers,
    onInviteUser,
    setInvitingId,
    invitingId,
    OnLoadSetEmailNotification,
    notificationActiveIds,
    setNotificationActiveIds,
  } = useLoad();
  const [value, setValue] = React.useState<string>("");
  const [isEnabled, setIsEnabled] = React.useState<boolean>(false);
  const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
  const [isUsername, setIsusername] = React.useState<boolean>(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const projectname = sessionStorage.getItem("projectname");
  const [selectedOption, setSelectedOption] = React.useState("");
  const [userid, setUserId] = React.useState("");
  const [infoText, setInfoText] = React.useState("");
  let data = projectUsers as any[];
  const onSearch = React.useCallback(
    async (search: string) => {
      try {
        await onLoadProjectUsers(search);
      } catch (e) {
        console.log(e);
      }
    },
    [onLoadProjectUsers]
  );

  const searchDebounce = React.useRef(debounce(onSearch, 1000));

  const handleSwitchChange = (value: any, cellData: any) => {
    setSwitchValue(value);
    setNotificationActiveIds({
      ...notificationActiveIds,
      [cellData.data.id]: value,
    });
    setIsusername(cellData.data.fullName);
    setUserId(cellData.data.id);
    setIsShowModal(true);
  };

  const handleCancelClick = () => {
    setIsEnabled(false);
    setIsShowModal(false);
    if (userid) {
      setNotificationActiveIds({
        ...notificationActiveIds,
        [userid]: !notificationActiveIds[userid],
      });
    }

   
  };
 

  React.useEffect(() => {
    
    if (data && data.length>0) {
      setInfoText(`Displaying  1 - ${20 * 1} of ${data.length}`);
    }
  }, [projectUsers, setInfoText]);

  // React.useEffect(() => {
  //   if (columns && columns.length) {
  //     columns.forEach((e) => {
  //       if (e.caption === "Actions") {
  //         e.cellRender = (cellData: any) => {
  //           return (
  //             <div className="flex">
  //               <Button
  //                 onClick={async () => {
  //                   if (cellData.data) {
  //                     let projectId = new URLSearchParams(
  //                       window.location.search
  //                     ).get("ProjectID");
  //                     let mode1 = new URLSearchParams(
  //                       window.location.search
  //                     ).get("mode");
  //                     let companyId = sessionStorage.getItem("companyId");
  //                     const data: JonesUserInviteModel = {
  //                       projectId: projectId ? +projectId : 0,
  //                       companyId: companyId ? +companyId : 0,
  //                       mode: mode1,
  //                       user: cellData.data,
  //                     };

  //                     setInvitingId(cellData.data.id);
  //                     await onInviteUser(data);
  //                   }
  //                 }}
  //                 disabled={
  //                   cellData.data.isInvitedToJones ||
  //                   invitingId === cellData?.data?.id
  //                 }
  //                 variant={cellData.data.isInvitedToJones ? `form` : `primary`}
  //                 className={
  //                   cellData.data.isInvitedToJones
  //                     ? `procore-btn prc-form-btn`
  //                     : `procore-btn`
  //                 }
  //               >
  //                 {cellData.data.isInvitedToJones
  //                   ? `Invite Sent`
  //                   : invitingId === cellData?.data?.id
  //                   ? `Inviting`
  //                   : `Invite To Jones`}
  //               </Button>
  //             </div>
  //           );
  //         };
  //       }
  //       if (e.dataField === "isNotifictionActive") {
  //         e.cellRender = (cellData: any) => {
  //           return (
  //             <div>
  //               <>
  //                 {" "}
  //                 <span>COI Requests for Commitments</span>
  //                 <Switch
  //                   defaultValue={cellData.value}
  //                   // value={isEnabled===false?false:true}
  //                   onValueChanged={(e: any) => {
  //                     handleSwitchChange(e.value, cellData);
  //                   }}
  //                   disabled={
  //                     !cellData.data.isInvitedToJones ||
  //                     cellData.data.role === "ReadOnly"
  //                   }
  //                   style={{ marginLeft: "8px" }}
  //                   switchedOffText=""
  //                   switchedOnText=""
  //                 />{" "}
  //               </>{" "}
  //             </div>
  //           );
  //         };
  //       }
  //     });
  //   }
  // }, [onInviteUser, setInvitingId, invitingId]);

  let mode = new URLSearchParams(window.location.search).get("mode");

  return (
    <div className="mt-3 pt-2 border-top">
      <div className="row mb-2">
        <div className="col-md-12">
          <h4 className="header-label page-header mt-3">
            {mode === "global" ? "" : "Project User Management"}
          </h4>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-12">
          <p className="header-label">
            List of users with {`'${permissionName}'`} permissions.
          </p>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-3">
          <Typeahead
            onChange={(e) => {
              setValue(e);
              searchDebounce.current(e);
            }}
            placeholder="Search"
            value={value}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          {usersLoading ? (
            <div className="container container-wrapper p-5">
              <div className="row">
                <div className="col-md-12">
                  <div className="alert alert-light text-center">
                    <img src={"/images/spinner.gif"} alt="Loading" />
                  </div>
                </div>
              </div>
            </div>
          ) : projectUsers && projectUsers.length ? (
            <DataGrid
              dataSource={projectUsers}
              showRowLines={true}
              showColumnLines={true}
              showBorders={true}
              height="auto"
            >
              <Column
                dataField="fullName"
                width="25%"
                caption="Name"
                allowReordering={true}
              />
              <Column
                dataField="emailAddress"
                width="25%"
                caption="Email"
                allowReordering={true}
              />
              <Column
                dataField="action"
                width="15%"
                caption="Actions"
                cssClass="dx-datagrid-action"
                cellRender={(cellData: any) => {
                  return (
                    <div className="flex">
                      <Button
                        onClick={async () => {
                          if (cellData.data) {
                            let projectId = new URLSearchParams(
                              window.location.search
                            ).get("ProjectID");
                            let mode1 = new URLSearchParams(
                              window.location.search
                            ).get("mode");
                            let companyId = sessionStorage.getItem("companyId");
                            const data: JonesUserInviteModel = {
                              projectId: projectId?.toString() ? projectId?.toString() : '0',
                              companyId: companyId?.toString() ? companyId?.toString() : '0',
                              mode: mode1,
                              user: cellData.data,
                            };

                            setInvitingId(cellData.data.id);
                            await onInviteUser(data);
                          }
                        }}
                        disabled={
                          cellData.data.isInvitedToJones ||
                          invitingId === cellData?.data?.id
                        }
                        variant={
                          cellData.data.isInvitedToJones ? `form` : `primary`
                        }
                        className={
                          cellData.data.isInvitedToJones
                            ? `procore-btn prc-form-btn`
                            : `procore-btn`
                        }
                      >
                        {cellData.data.isInvitedToJones
                          ? `Invite Sent`
                          : invitingId === cellData?.data?.id
                          ? `Inviting`
                          : `Invite To Jones`}
                      </Button>
                    </div>
                  );
                }}
              />
              <Column
                dataField="isNotifictionActive"
                width="30%"
                caption="Email Setting"
                cssClass="dx-datagrid-action email-settings"
                cellRender={(cellData: any) => {
                  return (
                    <div>
                      <>
                      <span
                      style={{
                        pointerEvents: (!cellData.data.isInvitedToJones || cellData.data.role === "ReadOnly") ? "none" : "auto",
                        opacity: (!cellData.data.isInvitedToJones || cellData.data.role === "ReadOnly") ? 0.4 : 1,
                        display: "inline-block",
                      }}
                    >
                      COI Requests for Commitments
                    </span>
                        <Switch
                          key={notificationActiveIds[cellData.data.id]}
                          value={notificationActiveIds[cellData.data.id]}
                          onValueChanged={(e: any) => {
                            handleSwitchChange(e.value, cellData);
                          }}
                          
                          disabled={
                               !cellData.data.isInvitedToJones ||
                               cellData.data.role === "ReadOnly"
                                    }
                          style={{ marginLeft: "8px" }}
                          switchedOffText=""
                          switchedOnText=""
                    
                         className={notificationActiveIds[cellData.data.id]===true?"toggleactive":"toggleinactive"}
                        />
                        
                        
                      </>
                    </div>
                  );
                }}
              />
              <Paging
                defaultPageSize={20}
                onPageIndexChange={(index) => {
                 
                  const startItemNumber = index * 20 + 1;
                  let endItemNumber = (index + 1) * 20;
                  endItemNumber =
                    endItemNumber > data.length ? data.length : endItemNumber;
                  const message = `Displaying  ${startItemNumber} - ${endItemNumber} of ${data.length}`;
                  setInfoText(message);
                }}
              />
              {data.length >20 && (
                  <Pager
                    visible={true}
                    showInfo={true}
                    showNavigationButtons={true}
                    infoText={infoText}
                  />
                )}
            </DataGrid>
          ) : (
            <NoResults />
          )}
        </div>
      </div>

      {/* <DataTable
              id="gridContainer"
              dataSource={projectUsers}
              keyExpr="id"
              columns={columns}
              loading={usersLoading}
              pageSize={20}



            /> */}

      {isShowModal && (
       
        <ModalComponent
          isShowModal={isShowModal}
          handleCancelClick={handleCancelClick}
          selectedOption={selectedOption}
          switchValue={switchValue}
          userid={userid}
          projectname={projectname}
          isUsername={isUsername}
          mode={mode}
          setIsShowModal={setIsShowModal}
          OnLoadSetEmailNotification={OnLoadSetEmailNotification}
        />
      )}
    </div>
  );
}

export default UserManagement;
