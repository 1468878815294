import React, { useState, useCallback } from "react";
import { Card, Form, Col, Row } from "react-bootstrap";

import { useLoad, useStore } from "../hooks";
import Select from "react-select";
import { components } from "react-select";
import MissingContact from "../../../shared/MissingContact";
import ErrorIcon from "../../../shared/ErrorIcon";
import { ProjectUser } from "../../../models";

import "../../../index.css";
import { showSuccessMessage } from "../../../utils";
import { Modal } from "react-bootstrap";
import jonesLogo from "../../../shared/images/JonesLogo.png";
import COIIcon from "../../../shared/images/COIIcon.png";
import procoreLogo from "../../../shared/images/procoreLogo.svg";



const CUSTOM_REQUIREMENT_LINK = 'https://app.smartsheet.com/b/form/7f8c0af2ea4b499886195d65945090db';

interface ICreateCOI {
  showCOIModal: boolean;
  onClose: () => void;
  onSaved: () => void;
  setIsShowModal?: (value: boolean) => void;
}

const MultiValue = (props: any) => {
  if (!props.index) {
    const CHIPS_LIMIT = 1;
    const validChips = props.getValue().filter((x: any) => x.id !== 0);

    if (validChips.length === CHIPS_LIMIT) {
      return <div>{validChips[0].name}</div>;
    } else if (validChips.length > CHIPS_LIMIT) {
      return (
        <div>{`${validChips.length} Companies / ${
          props.options.length - 1
        } Companies`}</div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

function GlobalCreateRequest(props: ICreateCOI) {
  const { onClose, showCOIModal, onSaved, setIsShowModal } = props;
  const {
    project,
    errorMessage,
    loading,
    insurance,
    setInsurance,
    onLoadVendorsUsers,
    getVendorsCOItag,
    loadingUsers,
    setLoadingUsers,
    permissions,
    onLoadProject
  } = useLoad();
  const [isShowModal, setIsShowModal1] = useState<boolean>(false);
  
 
  
const Menu = (props: any) => {
  var projectcode ="";
 if(project.code == null){ projectcode = "N/A"}else{projectcode =project.code};


  return (
    <components.Menu {...props}>
        {props.children}
     
        <a
          href={CUSTOM_REQUIREMENT_LINK +"?Requestor%20Email="+ project.logeduser +"&Company%20Name="+ project.comanyAccName}    
          rel="noopener noreferrer"
          target="_blank"
          style={styles.addCustomRequiremntLink}
          onClick={(event) => {           
            setIsShowModal1(true);
          }}
        >
          +Add Custom Requirements
        </a>
    </components.Menu>
  );
};

  React.useEffect(() => {
    onLoadProject();
  }, [onLoadProject])

  const { submitting, onStore } = useStore();
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);

  const handleProjectChange = useCallback(
    (selectedVendors: any, { action, removedValue }: any) => {
      if (selectedVendors && selectedVendors.length) {
        if (action === "deselect-option") {
          selectedVendors = selectedVendors.filter((x: any) => x.id !== 0);
        }
        const selectAllIndex = selectedVendors.findIndex(
          (x: any) => x.id === 0
        );
        if (selectAllIndex >= 0) {
          const selectableVendors = project.vendorList.filter(
            (x) => !x.isRequestSent
          );
          setInsurance({
            ...insurance,
            projectId: project ? project.id : 0,
            vendors: selectableVendors,
          });
        } else {
          setInsurance({
            ...insurance,
            projectId: project ? project.id : 0,
            vendors: selectedVendors,
          });
        }
      } else if (removedValue) {
        if (removedValue.id === 0) {
          setInsurance({
            ...insurance,
            projectId: project ? project.id : 0,
            vendors: [],
          });
        } else {
          let currentVendors = insurance.vendors.filter(
            (x) => x.id !== removedValue.id
          );
          setInsurance({
            ...insurance,
            vendors: currentVendors,
          });
        }
      } else if (action === "clear") {
        setInsurance({
          ...insurance,
          vendors: [],
        });
      }
    },
    [project, insurance, setInsurance]
  );

  const handleRequirementChange = useCallback(
    (e: any) => {
      const requirementId = e.id;
      if (requirementId >= 0 && e.tabId) {
        const requirement = project.requirementList?.find(
          (x: any) => x.id === +requirementId && x.tabId === e.tabId
        );
        if (requirement) {
          setInsurance({
            ...insurance,
            requirementId: requirement.id,
            tabId: requirement.tabId,
          });
        }
      }
    },
    [project, insurance, setInsurance]
  );

  const checkCOIRoleProjectUsers = useCallback(async () => {
    const vendorIds = insurance.vendors.map((e) => {
      return e.id;
    });

    const data: ProjectUser[] = await onLoadVendorsUsers(
      insurance.companyId.toString(),
      vendorIds
    );
    const vendorUserTag = await getVendorsCOItag(insurance.companyId.toString());

    if (data && data.length) {
      insurance.vendors.forEach((vendor) => {
        const vendorUsers = data.filter(
          (x: ProjectUser) => x.vendor?.id === vendor.id
        );
        if (vendorUsers && vendorUsers.length) {
          const coiVendorUser = vendorUsers.filter((x: ProjectUser) =>
            x.notes?.toUpperCase().includes(vendorUserTag.coiTag)
          );
          if (coiVendorUser && coiVendorUser.length) {
            vendor.projectUsers = coiVendorUser;
            const coiContactUser = coiVendorUser[0]; // need to sort by name
            if (coiContactUser) {
              vendor.coiContactUserId = null;
              vendor.emailAddress = "";
              vendor.contactName = "";
              vendor.projectUsers = vendorUsers;
            }
          } else {
            vendor.projectUsers = vendorUsers;
          }
        } else {
          vendor.projectUsers = [];
        }
      });
    } else {
      insurance.vendors.forEach((vendor) => {
        vendor.projectUsers = [];
      });
    }
    const nonCoiContactVendors = insurance.vendors.filter(
      (x) =>
        (!x.emailAddress || !x.projectUsers || !x.projectUsers.length) &&
        x.id > 0 
    );
    if (nonCoiContactVendors && nonCoiContactVendors.length > 0) {
      setIsModelOpen(true);
    } else if( insurance?.requirementId && insurance.requirementId > 0) {
      setIsModelOpen(true);
    }
    else if (insurance?.requirementId !== null && insurance.requirementId === 0){
      await onStore(insurance);
      // await onLoadProject();
      setInsurance({
        ...insurance,
        vendors: [],
        requirementId: null,
      });
      setLoadingUsers(false);
      onSaved();
      if (showCOIModal) {
        onClose();
      }
    }
  }, [
    insurance,
    setIsModelOpen,
    onLoadVendorsUsers,
    getVendorsCOItag,
    onClose,
    onSaved,
    setLoadingUsers,
    onStore,
    setInsurance,
    showCOIModal
  ]);
  
  const onProjectFilter = useCallback((option: any, searchText: any) => {
    if (
      option.data.code?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.name?.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }, []);

  const CheckboxCustom = ({ checked, disbled, isPartialCheckd }: any) => {
    return (
      <label className="custom-container">
        <input
          type="checkbox"
          checked={checked}
          disabled={disbled}
          onChange={() => {}}
        />
        <span
          className={isPartialCheckd ? "partial-checkmark" : "checkmark"}
        ></span>
      </label>
    );
  };

  const CustomOption = ({
    children,
    hasValue,
    getValue,
    innerProps,
    ...props
  }: any) => {
    return (
      <components.Option {...props} isDisabled={props?.data?.isRequestSent}>
        <div
          style={{ zIndex: 1 }}
          className="d-flex"
          onClick={() => {
            if (props.data.id === 0 && props.isSelected) {
              handleProjectChange([], { action: "clear", removedValue: null });
            } else if (props.data.id && props.isSelected) {
              handleProjectChange([], {
                action: "clear",
                removedValue: props.data,
              });
            } else if (innerProps && innerProps.onClick) {
              innerProps.onClick();
            }
          }}
        >
          <CheckboxCustom
            checked={props.isSelected}
            disbled={props?.data?.isRequestSent}
            isPartialCheckd={false}
          />

          <label>
            {props.value}
            {props?.data?.isRequestSent ? " (Request Sent)" : ""}
          </label>
        </div>
      </components.Option>
    );
  };

  const paddLeft = {
    paddingLeft: "0px",
  };

  const paddRight = {
    paddingRight: "1px",
  };

  const setSelectedBackColor = {
    multiValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        backgroundColor: "white",
        fontSize: "14px !important",
      };
    },
  };

  const customizeModal = {
    marginTop: "-3%",
    marginBottom: "-3%",
  };

  const customStyles = {
    group: (provided: any, state: any) => ({
      ...provided,
      paddingTop: state.data.label ? 5 : 0,
    }),
  };

  if (loading) {
    return (
      <div className="container container-wrapper p-5">
        <div className="row">
          <div className="col-md-12">
            <div className="alert alert-light text-center">
              <img src={"/images/spinner.gif"} alt="Loading" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div className="container container-wrapper p-5">
        <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger text-center">
              <p>{errorMessage}</p>
            </div>
            {showCOIModal ? (
              <button
                className="btn-cutom btn btn-dark blue-btn"
                onClick={onClose}
              >
                Cancel
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="" style={customizeModal}>
          {!loading && !errorMessage && (
            <div className="row">
              <div className=" col-md-12" >
                <Card 
                style={{
                  width: showCOIModal ? 'auto': 581,
                }}
                >
                  <Card.Body className="p-0">
                    <Card.Title
                      style={{
                        backgroundColor: "#45484A",
                        color: "#fff",
                        fontSize: 14,
                        paddingTop: "12px",
                        paddingRight: "24px",
                        paddingBottom: "12px",
                        paddingLeft: "24px",
                        borderRadius: "4px 4px 0px 0px",
                      }}
                    >
                     
                     {/* Add COI Request(s) for {project?.name} Level Requirements */}
                     Add COI Request(s) for Global Level Requirements
                    </Card.Title>
                    <div className="panel-body" style={{ padding: "1.6rem" }}>
                      <h5 className="header-details">
                        Once processed, we will send the vendor a request for a
                        COI and check the uploaded COI against the requirements.
                      </h5>
                      <Form.Group
                        as={Row}
                        controlId="companyEmail"
                        className="no-gutters"
                      >
                        <Form.Label
                          column
                          sm="5"
                          className="custom-lable"
                          style={paddLeft}
                        >
                          Select Insurance Requirements:{" "}
                          <span className="required-field">*</span>
                        </Form.Label>
                        <Col sm="7" style={paddRight}>
                          <Select
                            key={insurance.requirementId}
                            className="custom-select-input"
                            classNamePrefix="name-select"
                            isMulti={false}
                            hideSelectedOptions={false}
                            onChange={handleRequirementChange}
                            getOptionLabel={(option) => `${option.description}`}
                            getOptionValue={(option) => `${option.description}`}
                            options={project?.requirements}
                            placeholder={" --Select Requirement--"}
                            noOptionsMessage={() => null}
                            // value={project?.requirementList.find(
                            //   (i) => i.id === insurance.requirementId
                            // )}
                            value={
                              insurance?.requirementId != null &&
                              insurance?.requirementId >= 0
                                ? project?.requirementList.find(
                                    (x) =>
                                      x.id === insurance.requirementId &&
                                      insurance.tabId === x.tabId
                                  )
                                : null
                            }
                            isOptionSelected={(option) => {
                              return insurance?.requirementId === option.id &&
                                insurance.tabId === option.tabId
                                ? true
                                : false;
                            }}
                            styles={customStyles}
                            components={{
                              Menu,
                            }}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        controlId="companyName"
                        className="no-gutters"
                      >
                        <Form.Label
                          column
                          sm="5"
                          className="custom-lable"
                          style={paddLeft}
                        >
                          Company Name:{" "}
                          <span className="required-field">*</span>
                        </Form.Label>
                        <Col sm="7" style={paddRight}>
                          <Select
                            isMulti
                            isClearable
                            isSearchable
                            key={insurance.projectId}
                            hideSelectedOptions={false}
                            classNamePrefix="name-select"
                            className="custom-select-input"
                            getOptionLabel={(option) => `${option.name}`}
                            getOptionValue={(option) => `${option.name}`}
                            closeMenuOnSelect={false}
                            noOptionsMessage={() => null}
                            placeholder={"Search Companies"}
                            value={insurance?.vendors}
                            options={project?.vendorList}
                            onChange={handleProjectChange}
                            filterOption={onProjectFilter}
                            components={{
                              MultiValue: MultiValue,
                              Option: CustomOption,
                            }}
                            isOptionSelected={(option) => {
                              return insurance.vendors.includes(option);
                            }}
                            isOptionDisabled={(option) => {
                              return option?.isRequestSent;
                            }}
                            isDisabled={insurance.requirementId === null}
                            styles={setSelectedBackColor}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                  </Card.Body>
                  <Card.Footer className="cusrom-card-footer">
                    <div className="row clear button-group no-gutters ">
                      <div className="col-sm-2 text-left">
                        <p className="required-field">* required field</p>
                      </div>
                      <div className="col-sm-10 text-right p-2">
                        {submitting || loadingUsers ? (
                          <img src={"/images/loader.gif"} alt="Loading" />
                        ) : (
                          <>
                            {showCOIModal ? (
                              <button
                                className="btn-cutom btn btn-dark blue-btn"
                                onClick={onClose}
                              >
                                Cancel
                              </button>
                            ) : null}
                            <button
                              className="btn-cutom btn btn-dark blue-btn ml-2"
                              disabled={
                                !(
                                  insurance.requirementId != null &&
                                  insurance.requirementId >= 0
                                ) ||
                                !insurance.vendors ||
                                !insurance.vendors.length ||
                                submitting
                              }
                              onClick={checkCOIRoleProjectUsers}
                            >
                              {submitting ? "Submitting" : "Continue"}
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </Card.Footer>
                </Card>
              </div>
            </div>
          )}
        </div>
        
        {isShowModal &&
  (<Modal
      show={isShowModal}
      keyboard={false}
      animation={false}
      dialogClassName="Custome-rquire"
      backdrop="static"
      centered
      onHide={() => { }}
  >
<Modal.Body  className="p-0">
<div className="top-logo">
<img src={procoreLogo}  alt={procoreLogo} width="140" />
<img src={COIIcon} className="mx-4" alt={COIIcon} width="60" />
<img src={jonesLogo}  alt={jonesLogo} width="75" />

</div>

<div className="head">Add Custom Requirements</div>

<p className="divdesing">Please allow up to 24 hours for requirements to populate in Procore.<br></br>
For questions, please reach out to <a href="mailto:integration@getjones.com">  integration@getjones.com</a>.</p>
      </Modal.Body>
	
<div className="got-button">
<button  className="add-got-btn"
  
  onClick={() => {    
      setIsShowModal1(false);  
     
     {if(setIsShowModal){setIsShowModal(false)}	}
      return;																						
  }}
> Got it
  
</button>
</div>		

          

     
  </Modal>)}


        {isModelOpen && (
          <MissingContact
            insurance={insurance}
            setInsurance={setInsurance}
            isModelOpen={isModelOpen}
            showCOIModal={showCOIModal}
            permissionTemplates={permissions}
            allVendors={project.vendorList}
            submitting={submitting}
            onClose={() => {
              setIsModelOpen(false);
              setLoadingUsers(false);
            }}
            onSubmit={async (remainingVendors: any) => {
              setLoadingUsers(false);
              if (!remainingVendors) {
                showSuccessMessage("No Request Sent", <ErrorIcon />);
                setInsurance({
                  ...insurance,
                  requirementId: null,
                  vendors: [],
                });
                if (showCOIModal) {
                  onClose();
                }
              } else if (remainingVendors && remainingVendors.length) {
                const data = {
                  ...insurance,
                  vendors: remainingVendors,
                };
                await onStore(data);
                // await onLoadProject();
                setInsurance({
                  ...insurance,
                  vendors: [],
                  requirementId: null,
                });
                onSaved();
                if (showCOIModal) {
                  onClose();
                }
              }
              setIsModelOpen(false);
            }}
          />
        )}
      </>
    );
  }
}
const styles = {
  addCustomRequiremntLink: {
    display: "flex",
    color: "#F47E42",
    fontSize: "14px",
    margin: "5px 0 13px 10px",
    fontWeight: 500,
    height: "20px",
  },
};

export default GlobalCreateRequest;
