import React from "react";
import moment from 'moment'
import CustomLabel from "../../components/CustomLabel";
import ExpiredIcon from "../../shared/status-icons/ExpiredIcon";
import MissingIcon from "../../shared/status-icons/MissingIcon";
import CompliantIcon from "../../shared/status-icons/CompliantIcon";
import AwaitingIcon from "../../shared/status-icons/WaitingNewCOI";





export const columns: any[] = [
    {
        dataField: 'companyName',
        caption: 'Company Name',
        width: '25%',
        allowEditing: false,
    },
    {
        dataField: 'emailAddress',
        caption: 'COI Contact',
        width: '25%',
        allowEditing: false,
    },
    {
        dataField: 'requirementName',
        caption: 'Requirements',
        width: '17%',
        allowEditing: false,
        cellRender: (cellData: any) => {
            return cellData?.data?.requirementName
        }
    },
    {
        dataField: 'expirationDate',
        caption: 'Exp Date',
        width: '15%',
        allowEditing: false,
        cellRender: (cellData: any) => {
            if (cellData?.data?.expirationDate == null) {
                return;
            }
            return (moment(cellData?.data?.expirationDate).format('MM/DD/YYYY'))

        }
    },
    {
        dataField: 'displayStatus',
        caption: 'Status',
        width: '18%',
        allowEditing: false,
        cellRender: (cellData: any) => {
            switch (cellData?.data?.statusLoopkupElement) {
                case 1:
                case 4:
                case 7:
                    return (<CustomLabel icon={<CompliantIcon />} value={cellData?.data?.displayStatus} />);
                case 2:
                case 3:
                case 8:
                case 9:
               
                    return (<CustomLabel icon={<ExpiredIcon />} value={cellData?.data?.displayStatus} />);
                case 12:
                    return (<CustomLabel icon={<AwaitingIcon />} value={cellData?.data?.displayStatus} />);
                case 5:
                case 6:
                case 10:
                case 11:
                    return (<CustomLabel icon={<MissingIcon />} value={cellData?.data?.displayStatus} />);
                default:
                    return (<CustomLabel icon={<MissingIcon />} value={cellData?.data?.displayStatus} />);

            }
        }
    },
    {
        
        dataField: 'InsuranceDocuments',
        caption: 'Insurance Documents',
        width: '15%',
        cssClass:"text-center",
        allowEditing: false,
        
      
         
    },
    {
        
        dataField: 'Open in Jones',
        caption: 'Open in Jones',
        width: '15%',
        cssClass:"text-center",
        allowEditing: false,
        
      
         
    },
];
