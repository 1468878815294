import React, { useCallback, useState } from "react";
import { Card, FormControl } from "react-bootstrap";
import jonesLogo from "../shared/images/JonesLogo.png";
import Lock from "../shared/images/Lock.svg";
import procoreLogo from "../shared/images/procoreLogo.svg";
import coi from "../shared/images/coi.svg";
import Permissiondoucment from "../shared/images/Permissiondoucment.svg";
import Speedup from "../shared/images/Speedup.svg";




interface PermissionProps {
  Permissionname?: any;

 
}

const ProcorePermission: React.FC<PermissionProps> = ({
  Permissionname
   
  }) => {

    let convertedOutput =null;
    if (Permissionname !== null) {

     convertedOutput = Permissionname.split(',').map((item:any) => `'${item.trim()}'`).join(', ');
    }

    
  return (
    <>
    <div className="row">
<div className="col-12">
{/* <div className="alert text-center text-white font-weight-bold " style={{backgroundColor:"#E78587"}}>

{convertedOutput}, or Admin permissions on Directory / Commitments Tool are required.</div> */}

<div className="alert text-center text-white font-weight-bold" style={{ backgroundColor: "#E78587" }}>
    {convertedOutput ? `${convertedOutput} or Admin permissions on Directory / Commitments Tool are required.` : "Admin permissions on Directory / Commitments Tool are required."}
  </div>
</div>


</div>
      <div className="container container-wrapper p-4 permission-panel">
        <div className="row">
          <div className="col-sm-12">
            <Card
              style={{
                width: 650,
                margin: "0 auto",
                boxShadow: "0px 1.77812px 3.55625px rgb(0 0 0 / 11%)",
              }}
            >
              <Card.Body className="p-0">
                <div className="panel-body px-3">
                  
                  <div className="row">
                    <div className="col-12 col-md-12">
                      
                      <div className="top-logo text-center" style={{height:"90px"}} >
                        <img src={procoreLogo}  alt={procoreLogo} width="140" />
                        <img src={Lock} className="mx-4" alt={Lock} width="35" />
                        <img src={jonesLogo}  alt={jonesLogo} width="75" />
                        </div>
                       <p className="text-center font-weight-bold mb-0 header">  You don’t have permission to access this page</p>                    
                       {/* <p className="header-note text-center mb-1"> {convertedOutput} , or Admin permissions on the Directory / Commitments Tool are required.</p> */}
                       <p className="header-note text-center mb-1">
                            {convertedOutput ? `${convertedOutput} or Admin permissions on the Directory / Commitments Tool are required.` : "Admin permissions on the Directory / Commitments Tool are required."}
                            </p>
                    </div>                   
                  </div>

                  <div className="row mt-3 gray-note">
                    <div className="col-12 col-md-4 text-center">
                    <img src={coi}  alt={coi} width="50" />
                      <p className="mt-3">Uncover hidden risks, resolve gaps in coverage, and increase compliance levels across projects.</p>
                    </div>                   
                    
                    <div className="col-12 col-md-4 text-center">
                    <img src={Permissiondoucment}  alt={Permissiondoucment} width="50" />
                    <p className="mt-3">Eliminate exposure to risk and protect your projects from claims.</p>
                    </div>                   
                    
                    <div className="col-12 col-md-4 text-center">
                    <img src={Speedup}  alt={Speedup} width="50" />
                    <p className="mt-3">Empower every department that depends on insurance compliance.</p>
                    </div>                   
                  </div>

                 
                </div>

              </Card.Body>
              <Card.Footer className="custom-card-footer">
              <div className="row footer-note ">
              <div className="col-12 py-2">
                <p className="gray-note mb-0">
                If you believe you should have access, please contact your administrator.
                </p>
              </div>
            </div>
              </Card.Footer>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}


export default ProcorePermission;
