// RightSidePanel.js
import React, { memo } from "react";


interface IRightSidePanelProps {
  isOpen: boolean;
  onClose: any;
  children: any;
  loading: boolean;
}

const RightSidePanel = memo(function RightSidePanel({
  isOpen,
  onClose,
  children,
  loading,
}: IRightSidePanelProps) {
  return (
    <div className={`right-side-panel ${isOpen ? "open" : ""}`}>
      {/* <div className="panel-header d-flex justify-content-between align-items-center">
        <div className='d-flex justify-content-start align-items-center '>
        <SettingIcon />
   
          Project level - { loading ? <Loader/> : projectName}
      
        </div>
       
      </div> */}
      <div className="panel-content">{children}</div>

      {/* <div className="font-weight-bold" style={{ color: "#8995AD" }}>
            Powered by JONES{" "}
          </div>
          <div className="text-sm">
            <a
              rel="noreferrer"
              href="www.getjones.com"
              target="_blank"
              style={{ color: "#0073FF" }}
            >
              www.getjones.com
            </a>
          </div> */}
    </div>
  );
});

export default RightSidePanel;