import React from "react";
import { PulseLoader } from ".";
import jonesLogo from "../../shared/images/JonesLogo.png";
import Lock from "../../shared/images/Lock.svg";
import procoreLogo from "../../shared/images/procoreLogo.svg";

interface IPermissionProps {
  Permissionname?: any;
  loading: boolean
}

const SidePanelPermission: React.FC<IPermissionProps> = ({
  Permissionname,
  loading
}) => {
  let convertedOutput = null;
  if (Permissionname !== null) {
    convertedOutput = Permissionname.split(",")
      .map((item: any) => `'${item.trim()}'`)
      .join(", ");
  }

  return (
    <>
      <div className="row">
        <div className="col-12">

          {loading ? <PulseLoader />
          :<div
            className="alert text-center text-white font-weight-bold"
            style={{ backgroundColor: "#E78587" }}
          >
            {convertedOutput
              ? `${convertedOutput} and Admin permissions on Directory / Commitments Tool are required.`
              : "Admin permissions on Directory / Commitments Tool are required."}
          </div>}
        </div>
      </div>
      <div className="container container-wrapper  permission-panel">
        <div className="row">
          <div className="col-sm-12">
            <div className="panel-body px-3">
              <div className="row">
                <div className="col-12 col-md-12">
                  <div
                    className="top-logo text-center"
                    style={{ height: "90px" }}
                  >
                    <img src={procoreLogo} alt={procoreLogo} width="140" />
                    <img src={Lock} className="mx-4" alt={Lock} width="35" />
                    <img src={jonesLogo} alt={jonesLogo} width="75" />
                  </div>
                  <p className="text-center font-weight-bold mb-0 header">
                    {" "}
                    You don’t have permission to access this page
                  </p>
                  {/* <p className="header-note text-center mb-1"> {convertedOutput} , or Admin permissions on the Directory / Commitments Tool are required.</p> */}
                  <p className="header-note text-center mb-1">
                    {convertedOutput
                      ? `${convertedOutput} and Admin permissions on the Directory / Commitments Tool are required.`
                      : "Admin permissions on the Directory / Commitments Tool are required."}
                  </p>
                </div>
              </div>
            </div>

            <hr className="mt-4" />
            <div className="row footer-note ">
              <div className="col-12 py-2">
                <p className="gray-note mb-0">
                  If you believe you should have access, please contact your
                  administrator.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidePanelPermission;
