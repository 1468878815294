import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import API from "../../apis";
import { JonesRecord } from "../../models";
import decode from "jwt-decode";
import moment from "moment";
import { showErrorMessage, showSuccessMessage } from "../../utils";
import SuccessIcon from "../../shared/SuccessIcon";
import { PROCORE_URL, PROCORE_US_URL } from "../../env";

export function useLoadItems() {
  const [jonesVendorList, setJonesVendorList] = useState<JonesRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [loadingProjectDetails, setLoadingProjectDetails] =
    useState<boolean>(false);
  const [isGlobalExits, setIsGlobalExits] = useState(false);
  const [jonesGlobalVendor, setJonesGlobalVendor] = useState<JonesRecord[]>([]);
  const [projectName, setProjectName] = useState<string>("");
  const [vendorName, setVendorName] = useState<string>("");
  const [loadingGlobalJonesVendors, setLoadingGlobalJonesVendors] =
    useState(false);
  const [permissionname, setPermissionname] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isProjectOnboarding, setIsProjectOnboarding] =
    useState<boolean>(false);
  const [loadingiFrame, setLoadingiFrame] = useState<boolean>(false);

  const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const [selectedJonesVendorId, setSelectedJonesVendorId] =
    useState<string>("");
  const [initialdata, setInitialdata] = useState<any>(null);
  const [userExist, setuserExist] = useState<any>([]);
  const history = useHistory();

  const onLoadJonesVendorsByProjetId = useCallback(async (vendorId) => {
    try {
      setLoading(true);
      const projectId = new URLSearchParams(window.location.search).get(
        "ProjectID"
      );
      let companyId = new URLSearchParams(window.location.search).get(
        "CompanyID"
      );

      const { data } =
        await API.get(`Procore/GetJonesVendorsByProjetId?projectId=${projectId?.toString()}
            &companyID=${companyId}&mode=${""}&procoreObjectid=${vendorId}`);
      if (data) {
        setJonesVendorList(data);
      }
    } catch {}
    setLoading(false);
  }, []);

  const onLoadGlobalisexist = useCallback(async (vendorId) => {
    try {
      setLoadingProjectDetails(true);
      let companyId = new URLSearchParams(window.location.search).get(
        "CompanyID"
      );
      const projectId = new URLSearchParams(window.location.search).get(
        "ProjectID"
      );

      const { data } = await API.get(
        `Procore/GetProjectDetails?CompanyID=${companyId?.toString()}&ProjectID=${projectId}&vendorId=${vendorId}`
      );
      setLoadingProjectDetails(false);
      if (data) {
        setInitialdata(data);
        setIsGlobalExits(data.isGlobalExists);
        setProjectName(data.projectName);
        setVendorName(data?.vendorName);
        setIsProjectOnboarding(data.isProjectOnboarding);
        return data;
      }
    } catch (e) {
      setLoadingProjectDetails(false);
    }
  }, []);

  const onLoadCOIFile = useCallback(async (foreignId2: string, id: any) => {
    try {
      const { data } = await API.get(
        `Procore/GetRecentCoiFile?ForeignId2=${foreignId2}&Id=${id}`
      );
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const onLoadGlobalJonesVendors = useCallback(
    async (vendorId, search: string = "") => {
      setLoadingGlobalJonesVendors(true);
      try {
        const projectId = new URLSearchParams(window.location.search).get(
          "ProjectID"
        );
        let companyId = new URLSearchParams(window.location.search).get(
          "CompanyID"
        );

        const response = await API.get(
          `Procore/GetGlobalJonesVendorsByVendor?projectId=${projectId}&compnayId=${companyId?.toString()}&search=${search}&procoreVendorId=${vendorId}`
        );
        setJonesGlobalVendor(response.data);
        setLoadingGlobalJonesVendors(false);
        return response.data;
      } catch (e) {
        setLoadingGlobalJonesVendors(false);
        return [];
      }
    },
    []
  );

  const onValidateUserExist = useCallback(async () => {
    try {
      setIsValidating(true);
      const projectId = new URLSearchParams(window.location.search).get(
        "ProjectID"
      );
      let companyId = new URLSearchParams(window.location.search).get(
        "CompanyID"
      );

      const { data } = await API.get(
        `Procore/ProcorePermission?ProjectID=${projectId}&CompanyID=${companyId?.toString()}`
      );
      setIsValidating(false);
      if (data) {
        return data;
      }
      return null;
    } catch {
      setIsValidating(false);
      return null;
    }
  }, []);

  const onLoadPermissionname = useCallback(async () => {
    try {
      setIsValidating(true);
      let companyId = new URLSearchParams(window.location.search).get(
        "CompanyID"
      );

      const { data } = await API.get(
        `Procore/PermissionName?companyId=${companyId?.toString()}`
      );

      setPermissionname(data);
      if (data) {
        setPermissionname(data);
      }
      setIsValidating(false);
    } catch {
      setIsValidating(false);
    }
  }, []);

  const onValidateLogin = useCallback(async () => {
    let projectId = new URLSearchParams(window.location.search).get(
      "ProjectID"
    );
    let companyId = new URLSearchParams(window.location.search).get(
      "CompanyID"
    );

    const token = sessionStorage.getItem("token");
    if (!token) {
      history.push("/login", {
        projectId: projectId,
        companyId: companyId,
        navigateURL: "sidepanel",
        isSidePanel: true,
      });
      return;
    }
    if (token) {
      const tokenData: any = decode(token);
      const isTokenValid = moment.unix(tokenData.exp).isSameOrAfter(new Date());
      if (!isTokenValid) {
        sessionStorage.removeItem("token");
        history.push("/login", {
          projectId: projectId,
          companyId: companyId,
          navigateURL: "sidepanel",
          isSidePanel: true,
        });
        return;
      }
    }
    API.defaults.headers["accessToken"] = token;
    API.defaults.headers["CompanyId"] = companyId;
    return true;
  }, [history]);

  const onUploadCoiFiles = useCallback(
    async (values, ForeignId2: string, Id: any) => {
      setIsUploading(true);
      try {
        let formData = new FormData();
        for (var i = 0; i < values.length; i++) {
          const element = values[i].file;
          formData.append(`files`, element);
        }
        const response = await API.post(
          `Procore/UploadCoiFile?ForeignId2=${ForeignId2}&Id=${Id}`,
          formData
        );
        setIsUploading(false);

        if (response.data === true) {
          showSuccessMessage(
            "Insurance docs have been uploaded",
            <SuccessIcon />,
            true
          );
        } else {
          showErrorMessage("File is incorrect", null, true, true);
        }
        return response.data;
      } catch (e) {
        console.log(e);
        // setErrorMessage(e.message);
      }
    },
    []
  );

  const onLoadComplianceReportLink = useCallback(async (requestId) => {
    setLoadingGlobalJonesVendors(true);
    try {
      let companyId = new URLSearchParams(window.location.search).get(
        "CompanyID"
      );

      const { data } = await API.get(
        `Procore/GetComplianceReportLink?CompanyID=${companyId?.toString()}&jonesRequestId=${requestId}`
      );
    } catch (e) {}
  }, []);

  const onValidate = useCallback(async () => {
    const isLoggedIn = await onValidateLogin();
    if (isLoggedIn) {
      setIsInitializing(true);
      setLoadingiFrame(true);
      // const userExistData = await onValidateUserExist();
      //  const vendorId = userExistData?.userexist && userExistData?.recordHubExist ? userExistData.vendorid : "2889626";
      //   setSelectedJonesVendorId(vendorId)
      //   Promise.all([onLoadJonesVendorsByProjetId(vendorId),onLoadGlobalisexist(vendorId),  onLoadGlobalJonesVendors(vendorId)]).then(() => {
      //     setIsInitializing(false);
      //   }).catch(() => {
      //     setIsInitializing(false);
      //   });

      window.addEventListener("message", async (event) => {
        const obj: any = event.data;
        if (obj.type === "setup") {
          const userExistData = await onValidateUserExist();
          setuserExist(userExistData);
          const resource_id =
            userExistData?.userexist && userExistData?.recordHubExist
              ? userExistData.vendorid
              : obj?.context?.id;
          if (
            userExistData?.userexist &&
            (!userExistData?.recordHubExist ||
              userExistData.vendorid !== resource_id)
          ) {
            await onLoadPermissionname();
          }

          Promise.all([
            onLoadJonesVendorsByProjetId(resource_id),
            onLoadGlobalisexist(resource_id),
            onLoadGlobalJonesVendors(resource_id),
          ])
            .then(() => {
              setIsInitializing(false);
            })
            .catch(() => {
              setIsInitializing(false);
            }).finally(() => {
              setIsInitializing(false);
            });
          setSelectedJonesVendorId(resource_id);
        }
      });
    }
  }, [
    onLoadGlobalJonesVendors,
    onLoadGlobalisexist,
    onLoadJonesVendorsByProjetId,
    onLoadPermissionname,
    onValidateLogin,
    onValidateUserExist,
  ]);

  useEffect(() => {
    let parentWin = window;
    var Base_url;
    if (parentWin.document.referrer.includes(PROCORE_URL)) {
      Base_url = PROCORE_URL;
    } else if (parentWin.document.referrer.includes(PROCORE_US_URL)) {
      Base_url = PROCORE_US_URL;
    } else {
      Base_url = PROCORE_URL;
    }
    window.parent.postMessage({ type: "initialize" }, Base_url);
    onValidate();
  }, [onValidate]);

  return {
    loading,
    jonesVendorList,
    isGlobalExits,
    jonesGlobalVendor,
    loadingGlobalJonesVendors,
    projectName,
    onLoadCOIFile,
    vendorName,
    onLoadJonesVendorsByProjetId,
    onLoadGlobalisexist,
    onLoadGlobalJonesVendors,
    loadingProjectDetails,
    onValidateUserExist,
    isValidating,
    permissionname,
    onLoadPermissionname,
    onValidateLogin,
    onUploadCoiFiles,
    isProjectOnboarding,
    onLoadComplianceReportLink,
    isUploading,
    isInitializing,
    initialdata,
    selectedJonesVendorId,
    loadingiFrame,
    setLoadingiFrame,
    userExist,
  };
}
