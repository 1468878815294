import * as React from "react";
function CopyIcon(props: any) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.72164 4.725V0.7875L10.0825 4.725H6.72164ZM8.73801 12.6H2.01633V11.025H8.73801V12.6ZM2.01633 7.875H7.39367V9.45H2.01633V7.875ZM7.67213 0H1.34434C0.602935 0 0 0.707175 0 1.575V14.175C0 15.0436 0.602935 15.75 1.34434 15.75H9.41035C10.1518 15.75 10.7547 15.0436 10.7547 14.175V3.61147L7.67213 0Z"
        fill="#0073FF"
      />
    </svg>
  );
}

export default CopyIcon;
