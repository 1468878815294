import * as React from "react"

function MissingIcon(props: any) {
    return (
        <svg width={20}
            height={20}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <rect width="24" height="20" fill="black" fillOpacity="0"></rect>
            <rect width="24" height="20" fill="black" fillOpacity="0"></rect>
            <path d="M12 2C6.48571 2 2 6.48571 2 12C2 17.5143 6.48571 22 12 22C17.5143 22 22 17.5143 22 12C22 6.48571 17.5143 2 12 2ZM14.2612 12.8327H12C11.5388 12.8327 11.1673 12.4612 11.1673 12V6.82857C11.1673 6.36735 11.5388 5.99592 12 5.99592C12.4612 5.99592 12.8327 6.36735 12.8327 6.82857V11.1673H14.2612C14.7224 11.1673 15.0939 11.5388 15.0939 12C15.0939 12.4612 14.7224 12.8327 14.2612 12.8327Z"
                fill="#B1BCD3" />
        </svg>

    )
}

export default MissingIcon;
