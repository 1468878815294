import React from "react";
import moment from 'moment'
import { Tooltip } from 'devextreme-react/tooltip';
import { renderToString } from 'react-dom/server';







export const columns: any[] = [
    {
        dataField: 'companyName',
        caption: 'Company Name',
        width: '15%',
        allowEditing: false,
    },
    {
        dataField: 'commitmentnumber',
        caption: 'Commitment Number',
        width: '25%',
        allowEditing: false,
    },
    {
  dataField: 'commitmentstatus',
  caption: 'Commitment Status',
  width: '28%',
  allowEditing: false,
  cellRender: (cellData: any) => {
    const statusMap: { [key: number]: { text: string; className: string } } = {
      0: { text: 'Draft', className: 'draft-status' },
      1: { text: 'Out For Bid', className: 'out-for-bid-status' },
      2: { text: 'Out For Signature', className: 'out-for-signature-status' },
      3: { text: 'Approved', className: 'approved-status' },
      4: { text: 'Complete', className: 'draft-status' },
      5: { text: 'Terminated', className: 'terminated-status' },
      6: { text: 'Void', className: 'void-status' },
    };

    let statusElements: JSX.Element[] = [];

    for (const status of cellData?.data?.commitmentstatus) {
      if (statusMap[status]) {
        const { text, className } = statusMap[status];
        const statusElement = (
          <span className={`custom-status ${className}`} key={status}>
            {text}
          </span>
        );
        statusElements.push(statusElement);
      }
    }
    //console.log("celldata",statusElements.props.children)
    
    return (
      <>
  
  
  <Tooltip
  target={'.tooltip-satus' + cellData.data.vendorId}
  showEvent="dxhoverstart"
  hideEvent="dxhoverend"
  position="bottom"
  contentTemplate={renderToString(
    <div  className="d-flex flex-wrap justify-content-between">
      {statusElements.slice().map((element, index) => (
     
        <React.Fragment key={index}>   
               <div className={`mb-2 custom-status ${element.props.className}`}>
            {element.props.children}
          </div>
          <div className="status-spacing"></div>
        </React.Fragment>
      ))}
    </div>
  )}
/>
      
        <div className={"status-container tooltip-satus"+cellData.data.vendorId} >
        {statusElements.length > 0 && statusElements[0]}
        {statusElements.slice(1).map((element, index) => (
          <React.Fragment key={index}>
            <span className="status-spacing"></span>
            {element}
          </React.Fragment>
        ))}
      </div>
      </>
    );
  }
},


    {
        dataField: 'executed',
        caption: 'Executed',
        width: '10%',
        allowEditing: false,
        cssClass:"text-center",                       
        cellRender: (cellData: any) => {
         
          if (cellData?.data?.executed === "false") {
            return 'No';
          } else {
            return 'Yes';
          }
        }
    },
    
    {
        dataField: 'action',
        caption: 'Action',
        width: '20%',
        allowEditing: false,        
    },
   
];
